<!-- <template>
    <div v-if="isLoading">Carregando</div>
    <div v-else class="container mx-auto">
      <div v-for="(result, type) in results" :key="type" class="mb-8">
        <h2 class="text-xl font-bold mb-4">Câncer de {{ type }}</h2>
        <div class="grid grid-cols-3 gap-4">
          <div v-for="video in result.videos" :key="video.videoUrl" class="max-w-sm rounded overflow-hidden shadow-lg">
            <img :src="video.thumbnailUrl" class="w-full" alt="Thumbnail">
            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2">Resumo do Vídeo</div>
              <p class="text-gray-700 text-base">{{ video.resumo }}</p>
            </div>
          </div>
        </div>
        <div class="mt-6">
          <h3 class="text-lg font-bold">Resumo Geral do Tipo</h3>
          <p>{{ result.resumoPorTipo }}</p>
        </div>
      </div>
    </div>
  </template> -->
  <template>
    <div v-if="isLoading">Carregando...</div>
    <div v-else class="bg-gray-200 h-full p-10">
        <div class="w-4/5 mx-auto">
            <div v-for="(result, type) in results" :key="type">
                <div>
                    <h1 class="text-2xl font-bold">Câncer de {{ type }}</h1>
                </div>
                <div class="flex flex-row gap-x-10 mt-5 text-sm font-extralight">
                    <div v-for="video in result.videos" :key="video.videoUrl" class="w-1/4 p-5 bg-white rounded-xl">
                        <img :src="video.thumbnailUrl" class="w-[300px] rounded-xl" alt="Thumbnail">
                        <div class="mt-5">
                            <!-- AQUI, processamos o resumo JSON diretamente -->
                            <div v-if="video.resumo" class="text-gray-700 text-base">
                                <div v-for="(value, key) in JSON.parse(video.resumo)" :key="key">
                                    <h4 class="font-bold">{{ key }}</h4>
                                    <p>{{ value }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full mt-10 rounded-xl mb-10">
                    <h1 class="text-2xl font-bold">Principais pontos sobre Câncer de {{ type }}</h1>
                    <p class="text-sm font-extralight">{{ result.resumoPorTipo }}</p>
                </div>
            </div>
        </div>
    </div>
    </template>
    
  
  <script>
  export default {
    data() {
      return {
        results: {},
        resultVideos: {},
        isLoading: false,
      }
    },
    methods: {
        formatResumo(resumoString) {
        try {
            return JSON.parse(resumoString);
        } catch(e) {
            console.error("Erro ao parsear o resumo:", e);
            return {}; // Retorna um objeto vazio em caso de erro
        }
        }
    },
    mounted() {

    this.isLoading = true;
      fetch(`${process.env.VUE_APP_API_URL}/youtube-audio-transcriptions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // Adicione outros headers conforme necessário
      })
      .then(response => response.json())
      .then(data => {
        console.log(this.results)
        this.results = data.results;
        console.log(this.results)
        this.isLoading = false;

      })
      .catch(error => {
        console.error('Error:', error);
      });
    }
  }
  </script>
  