<template>
  <div class="flex flex-col">
    <h2 class="text-4xl font-bold text-gray-700">
      {{ $t("ContentSystem.startHereTitle") }}
    </h2>
    <p class="font-medium text-gray-500 mt-4">
      {{ $t("ContentSystem.followMaterial") }}
    </p>
  </div>

  <!-- Lista de Conteúdo -->
  <div v-if="systemContentGrouped">
    <div
      v-for="(items, theme) in systemContentGrouped"
      :key="theme"
      class="mt-20"
    >
      <h2 class="text-2xl font-bold text-gray-600 mb-4 mt-4">{{ theme }}</h2>
      <ul
        class="flex flex-wrap sm:flex-row justify-items-start space-y-9 sm:space-y-0"
      >
        <li
          v-for="(item, index) in items"
          :key="index"
          @click="navigateToContent(item.content_uuid)"
          class="flex mr-9"
          :class="
            index > 0 ? 'sm:mb-0 md:mb-0 space-y-0' : 'mb-0 md:mb-10 sm:mb-10'
          "
        >
          <div
            class="text-gray-800 pt-5 pl-5 transition ease-in-out delay-50 hover:scale-[1.06] bg-[#F1DEFF] hover:cursor-pointer hover:bg-primary hover:text-white sm:w-[280px] w-[290px] h-[150px] rounded-xl p-6"
          >
            <img
              width="50"
              :src="`https://flagicons.lipis.dev/flags/4x3/${getCountryCode(
                item.language
              )}.svg`"
              class="rounded-[8px] mb-1"
            />
            <h3 class="text-xl font-medium mb-10">{{ item.subject }}</h3>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import mixin from "./mixin";

export default {
  mixins: [mixin],

  props: ["userIdiom"],

  data() {
    return {
      systemContentGrouped: "",
      learningLanguage: "",
      locale: "",
      user_id: "",

      countries: [
        { name: "Inglês", code: "us" },
        { name: "Espanhol", code: "es" },
        { name: "Francês", code: "fr" },
        { name: "Italiano", code: "it" },
        { name: "Alemão", code: "de" },
        { name: "Russo", code: "ru" },
        { name: "Português", code: "br" },
        { name: "Holandês", code: "nl" },
      ],
    };
  },

  async mounted() {
    try {
      const username = localStorage.getItem("username");
      this.username = username;

      const responseB = await axios.get(
        `${process.env.VUE_APP_API_URL}/user/u/${username}`
      );
      var list = [responseB.data];
      this.learningLanguage = list[0][0]?.learning_language;
      this.locale = list[0][0]?.user_idiom;
      this.user_id = list[0][0]?.user_id;

      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/s/content/${this.learningLanguage}`
      );
      console.log("Idioma aprendendo: ", this.learningLanguage);
      this.systemContentGrouped = response.data;
      console.log("this.systemContentList", this.systemContentList);

      if (this.systemContentList[0][0]?.is_onboarding) {
        this.navigateToOnboarding();
      }

      // this.loadTranslations();

      // Se não, segue para Dashboard
    } catch (error) {
      console.error("Houve um erro ao buscar os dados:", error);
    }
  },

  methods: {
    getCountryCode(name) {
      const country = this.countries.find((country) => country.name === name);
      return country ? country.code : "";
    },
    getFlagUrl(name) {
      const code = this.getCountryCode(name);
      return `https://flagicons.lipis.dev/flags/4x3/${code}.svg`;
    },

    getLanguageName(isoCode) {
      const languageMap = {
        "en-US": "Inglês",
        "es-ES": "Espanhol",
        "fr-FR": "Francês",
        "it-IT": "Italiano",
        "de-DE": "Alemão",
        "ru-RU": "Russo",
        "pt-PT": "Português",
        "pt-BR": "Português",
        "nl-NL": "Holandês",
      };

      return languageMap[isoCode] || "Código de idioma desconhecido";
    },
  },
};
</script>
