import { createRouter, createWebHistory } from 'vue-router';
import multiguard from 'vue-router-multiguard';

import {
	i18nGuard,
} from '@/router/guards';

import ContentList from '../components/ContentList.vue';
import ContentView from '../components/ContentView.vue';
import ContentSystemView from '../components/ContentSystemView.vue';
import Login from '../components/Login.vue';
import Onboarding from '../views/Onboarding.vue';
import Chat from '../components/Chat.vue';
import ChatView from '../components/ChatView.vue';
import Dashboard from '../views/Dashboard.vue';
import Paywall from '../views/Paywall.vue';
import Payment from '../views/Payment.vue';
import Checkout from '../views/Checkout.vue';
import Register from '../components/Register.vue';
import CreateContent from '../components/CreateContent.vue';
import Exercise from '../components/ExercisesStruct.vue';
import ExerciseView from '../components/ExercisesView.vue';
import PronunciationView from '../components/PronunciationView.vue';
import Calendar from '../components/Calendar.vue';
import Account from '../components/Account.vue';
import Speak from '../views/Speak.vue';
import Youtube from '../views/Deletar/Youtube.vue';
import Pronunciation from '../views/Pronunciation.vue';
import Recorder from '../views/Recorder.vue';
import SpeakRTC from '../views/SpeakRTC.vue';
import WritingView from '../components/WritingView.vue';
import Writing from '../views/Writing.vue';


const routes = [
	{
		path: '/',
		redirect: 'login',
	},
	{
		path: '/login/',
		name: 'Login',
		component: Login,
	},
	{
		path: '/register',
		name: 'Register',
		component: Register,
	},
	
	{
		path: '/account',
		name: 'Account',
		component: Account,
		meta: { requiresAuth: true },
	},
	{
		path: '/create-content/',
		name: 'CreateContent',
		component: CreateContent,
		meta: { requiresAuth: true },
	},
	{
		path: '/youtube/',
		name: 'Youtube',
		component: Youtube,
	},
	{
		path: '/content-list/',
		name: 'ContentList',
		component: ContentList,
		meta: { requiresAuth: true },
	},
	{
		path: '/content/:contentUUID',
		name: 'ContentView',
		component: ContentView,
		meta: { requiresAuth: true },
	},
	{
		path: '/s/content/:contentUUID',
		name: 'ContentSystemView',
		component: ContentSystemView,
		meta: { requiresAuth: true },
	},
	{
		path: '/onboarding/',
		name: 'Onboarding',
		component: Onboarding,
		meta: { requiresAuth: true },
	},
	{
		path: '/chatview/:contentUUID',
		name: 'ChatView',
		component: ChatView,
		meta: { requiresAuth: true },
		
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: Dashboard,
		meta: { requiresAuth: true },
	},
	{
		path: '/chat/:contentUUID',
		name: 'Chat',
		component: Chat,
		meta: { requiresAuth: true },
	},
	{
		path: '/exerciseview/:contentUUID',
		name: 'ExerciseView',
		component: ExerciseView,
		meta: { requiresAuth: true },
	},
	{
		path: '/exercise/:contentUUID',
		name: 'Exercise',
		component: Exercise,
		meta: { requiresAuth: true },
	},
	{
		path: '/calendar/',
		name: 'Calendar',
		component: Calendar,
		meta: { requiresAuth: true },
	},
	{
		path: '/paywall/',
		name: 'Paywall',
		component: Paywall,
		meta: { requiresAuth: true },
	},
	{
		path: '/payment/',
		name: 'payment',
		component: Payment,
		meta: { requiresAuth: true },
	},
	{
		path: '/checkout/',
		name: 'checkout',
		component: Checkout,
		meta: { requiresAuth: true },
	},
	{
		path: '/speak/',
		name: 'Speak',
		component: Speak,
		meta: { requiresAuth: true },
	},
	{
		path: '/pronunciation/:contentUUID',
		name: 'Pronunciation',
		component: Pronunciation,
		meta: { requiresAuth: true },
	},
	{
		path: '/pronunciationview/:contentUUID',
		name: 'PronunciationView',
		component: PronunciationView,
		meta: { requiresAuth: true },
	},	
	{
		path: '/recorder/',
		name: 'Recorder',
		component: Recorder,
		meta: { requiresAuth: false },
	},
	{
		path: '/SpeakRTC/',
		name: 'SpeakRTC',
		component: SpeakRTC,
		meta: { requiresAuth: false },
	},
	{
		path: '/writing/:contentUUID',
		name: 'Writing',
		component: Writing,
		meta: { requiresAuth: true },
	},
	{
		path: '/writingview/:contentUUID',
		name: 'WritingView',
		component: WritingView,
		meta: { requiresAuth: true },
	},	

	
];

const router = createRouter({
	history: createWebHistory(),
	routes, // abreviação para 'routes: routes'
});


router.beforeEach((to, from, next) => {
	document.title = 'Lanna' || to.meta.title;

	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
	const isAuthenticated = localStorage.getItem('username');

	if (to.path === '/' && isAuthenticated) {
		next('/dashboard'); // Se autenticado, redireciona para o dashboard ao invés de login
	} else if (requiresAuth && !isAuthenticated) {
		next('/login'); // Se a rota requer autenticação e o usuário não está autenticado, redireciona para login
	} else if (to.path === '/login' && isAuthenticated) {
		next('/dashboard'); // Se o usuário está tentando acessar login mas já está autenticado, redireciona para o dashboard
	} else {
		next(); // Em outros casos, simplesmente prossegue para a rota desejada
	}
});

router.beforeResolve(multiguard([i18nGuard]));

export default router;
