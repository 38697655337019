import axios from 'axios';

export default {
	data() {
		return {
			notificationMessage: '',
			notificationType: 'success',
			isNotificationVisible: false,
			notificationKey: 0, // Nova propriedade para chave única
			notificationTimeout: null, // Para armazenar o ID do timeout
		};
	},
	methods: {
		// Notificação
		showNotification(message, type) {
			this.notificationMessage = message;
			this.notificationType = type;
			this.isNotificationVisible = true;

			setTimeout(() => {
				this.isNotificationVisible = false;
			}, 4000); // A notificação desaparecerá após 4 segundos
		},

		// Ajustar no futuro. Aqui é a chamada para criar conteudo inicialmente feita.
		submitForm() {
			// Validar o campo "assunto"
			if (!this.subject.trim()) {
				this.errorStep3 = true; // Definir uma variável de erro que pode ser usada para mostrar uma mensagem de erro na interface
				return; // Parar de executar se a validação falhar
			}
			this.errorStep3 = false; // Limpar a mensagem de erro

			if (this.page) {
				this.currentStep = 10; // Se for onboarding
			} else {
				this.currentStep = 4; // Se for create
			}

			axios
				.post(`${process.env.VUE_APP_API_URL}/api`, {
					language: this.language,
					level: this.level,
					subject: this.subject,
					translate: this.showTranslation,
					levelOfFormality: this.levelOfFormality,
					contentType: this.contentType,
				})
				.then((response) => {
					this.originalSentences = response.data.message;

					this.audioPath = response.data.audioPath;
					this.contentUUID = response.data.contentUUID;

					this.saveContent();
					this.currentStep = 5;
				})
				.catch((error) => {
					console.error('Erro ao buscar resposta da API:', error);
				});
		},

		async saveContent() {
			const contentData = {
				language: this.language || this.learningLanguage,
				level: this.level || this.languageLevel,
				subject: this.subject,
				original_sentences: this.originalSentences,
				translated_sentences: null,
				audio_path: this.audioPath,
				contentUUID: this.contentUUID,
				userId: this.userId,
				isOnboarding: 0,
				levelOfFormality: this.levelOfFormality,
				contentType: this.contentType,
			};

			try {
				const response = await axios.post(
					`${process.env.VUE_APP_API_URL}/addContent`,
					contentData,
				);
				this.contentUuid = response.data.contentUuid;
				const username = localStorage.getItem('username');
				await axios.put(
					`${process.env.VUE_APP_API_URL}/user/content/${username}`,
				);
				this.$router.push(`/content/${this.contentUuid}`);
			} catch (error) {
				console.error(error);
			}
		},

		updateContent() {
			console.log(
				'Updating content:',
				this.contentUuid,
				this.translatedSentences,
			); // Log para diagnóstico
			console.log(this.translatedSentences);

			axios
				.put(`${process.env.VUE_APP_API_URL}/updateContent`, {
					contentUuid: this.contentUuid,
					translated_sentences: this.translatedSentences,
				})
				.then((response) => {
					console.log('Update successful:', response); // Log em caso de sucesso
				})
				.catch((error) => {
					console.error('Update failed:', error); // Log em caso de erro
				});
		},

		requestTranslation(lang) {
			if (!this.translationRequested) {
				this.isTranslationLoading = true;
				// console.log('Tá vinbdo essa lingua', this.userIdiom)
				axios
					.post(`${process.env.VUE_APP_API_URL}/translate`, {
						text: this.originalSentences,
						language: this.userIdiom,
					})
					.then((response) => {
						this.translatedSentences = JSON.parse(response.data.translation);

						this.updateContent();
						this.isTranslationLoading = false;
					})
					.catch((error) => {
						console.error('Erro ao buscar tradução da API:', error);
					});
				this.translationRequested = true;
			}
		},

		reset() {
			this.currentStep = 1;
			this.language = '';
			this.level = '';
			this.subject = '';
			this.chatGptResponse = '';
			this.translation = '';
		},

		fetchWordTranslation(word) {
			console.log(this.language);
			this.isWordLoading = true;
			console.log(this.isWordLoading);

			axios
				.post(`${process.env.VUE_APP_API_URL}/api`, {
					language: this.userIdiom,
					level: this.level,
					subject: word,
					type: 'wordTranslation', // Isso informa ao servidor que queremos traduzir uma única palavra
					context: this.originalSentences.join(' '), // Aqui você já sabe que é um array, então .join deve funcionar
				})
				.then((response) => {
					this.isWordLoading = false;
					this.wordTranslation = response.data.message;
					if (this.$refs.translationBox) {
						// Verifica se a ref está disponível
						this.$refs.translationBox.style.display = 'block';
					}
				})
				.catch((error) => {
					console.error('Erro ao buscar tradução da palavra:', error);
					this.isWordLoading = false;
				});
		},
		wordClicked(event) {
			clearTimeout(this.translationBoxTimeout);

			// Detecta se o evento é de toque e manipula adequadamente
			if (event.type === 'touchend' && event.changedTouches.length > 0) {
				event.preventDefault();  // Prevenindo o comportamento padrão de toque
				event = event.changedTouches[0];
			}

			if (event.target.tagName === 'P') {
				let word = window.getSelection().toString().trim();
				if (word) {
					this.selectedWord = word;
					this.fetchWordTranslation(word);

					if (this.$refs.translationBox) {
						const range = window.getSelection().getRangeAt(0);
						const rect = range.getBoundingClientRect();

						this.$refs.translationBox.style.left = `${rect.left + window.scrollX}px`;
						this.$refs.translationBox.style.top = `${rect.bottom + window.scrollY}px`;
						this.$refs.translationBox.style.display = 'block';

						this.translationBoxTimeout = setTimeout(() => {
							this.closeTranslationBox();
						}, 10000);

						setTimeout(() => {
							document.addEventListener('click', this.hideTranslationBoxOutsideClick);
							document.addEventListener('touchend', this.hideTranslationBoxOutsideClick);
						}, 0);
					}
				}
			}
		},

		closeTranslationBox() {
			this.$refs.translationBox.style.display = 'none';
			document.removeEventListener('click', this.hideTranslationBoxOutsideClick);
			document.removeEventListener('touchend', this.hideTranslationBoxOutsideClick);
			clearTimeout(this.translationBoxTimeout);
		},

		hideTranslationBoxOutsideClick(e) {
			if (!this.$refs.translationBox.contains(e.target)) {
				this.closeTranslationBox();
			}
		},

		capitalizeFirstLetter(string) {
			let words = string.split(' ');
			let firstWords = words.slice(0, 6);
			let phrase = firstWords.join(' ');

			if (words.length > 6) {
				phrase += '...';
			}

			return phrase.charAt(0).toUpperCase() + phrase.slice(1);
		},

		nextStep(step) {
			console.log('Nível selecionado:', this.level);

			if (step === 1) {
				console.log('Nível selecionado:', this.level);

				if (!this.language) {
					this.errorStep1 = true;
					return;
				}
				this.errorStep1 = false;
			}
			if (step === 2) {
				console.log('Nível selecionado:', this.level);

				if (!this.level) {
					this.errorStep2 = true;
					return;
				}
				this.errorStep2 = false;
			}
			this.currentStep++;
		},

		// Mandar para pagamento
		handleClick() {
			// if (this.numberOfContent > 0 &&  this.isPaid==0 ) {
			//   this.navigateToPayment();
			// } else {
			this.navigateToCreateContent();
			// }
		},

		// REDIRECTS //
		navigateToContent(content_uuid) {
			this.$router.push({
				path: `/content/${content_uuid}`,
			});
		},

		navigateToSystemContent(content_uuid) {
			this.$router.push({
				path: `s/content/${content_uuid}`,
			});
		},

		navigateToContentList() {
			this.$router.push({
				path: `/content-list/`,
			});
			this.currentPage = 'content-list';
		},

		navigateToOnboarding() {
			this.$router.push({
				path: `/onboarding`,
			});
		},

		navigateToCalendar() {
			this.$router.push({
				name: `Calendar`,
			});
			this.currentPage = 'calendar';
		},
		navigateToSpeak() {
			this.$router.push({
				name: `Speak`,
			});
			this.currentPage = 'Speak';
		},

		navigateToAccount() {
			this.$router.push({
				name: `Account`,
			});
			this.currentPage = 'settings';
		},

		navigateToCreateContent() {
			this.$router.push({
				path: `/create-content`,
			});
		},

		navigateToLogin() {
			this.$router.push({
				path: `/login`,
			});
		},

		navigateToPayment() {
			this.$router.push({
				path: `/paywall`,
			});
		},

		navigateToDashboard() {
			this.$router.push({
				name: `Dashboard`,
			});
			this.currentPage = 'dashboard';
		},
	},

	watch: {
		showTranslation(newValue) {
			if (newValue && !this.translationRequested) {
				this.requestTranslation();
			}
		},
	},
};
