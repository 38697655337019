<template>
  <div class="h-screen flex">
    <!-- Coluna da Esquerda -->
    <Sidebar :username="username"></Sidebar>
    <!-- Coluna da Direita -->
    <div
      class="flex-1 p-10 mt-10 sm:mt-0 bg-[#F4F0F8] flex flex-col overflow-y-auto"
    >
      <LanguageSelection />

      <!-- PayAlert -->
      <PayAlert v-if="isPaid === 0" />

      <!-- Parte de Baixo -->
      <div class="flex-1 overflow-visible">
        <!-- Header com Título, Subtítulo  -->
        <div
          class="flex flex-col md:flex-row items-start md:items-center justify-between mt-20 w-full md:w-5/5 mb-[00px]"
        >
          <div class="flex space-x-5">
            <h2 class="text-4xl font-bold text-gray-700">
              {{ $t("dashboard.title") }}
            </h2>
            <p class="font-medium text-gray-500 mt-4"></p>
          </div>

          <!-- Botão para criar novo conteúdo-->
          <div
            @click="handleClick()"
            class="flex items-center rounded-xl p-4 text-white transition ease-in-out delay-50 hover:scale-[1.06] hover:cursor-pointer hover:transform hover:rotate-360 bg-primary items-right mb-10 mt-10"
          >
            <p class="mr-2 font-medium text-white">
              {{ $t("dashboard.newMaterial") }}
            </p>
            <i
              class="fa-solid fa-wand-magic-sparkles transform transition duration-500 hover:rotate-360 rounded-full text-white"
              style="font-size: 24px"
            ></i>
          </div>
        </div>

        <!-- Lista de Conteúdo -->
        <ul
          v-if="filteredContentList.length > 0"
          class="flex flex-wrap sm:flex-row justify-items-start space-y-9 sm:space-y-9"
        >
          <li
            :title="item.subject"
            v-for="item in filteredContentList"
            :key="item.username"
            class="flex mr-9"
            :class="{ 'mt-0 sm:mt-9': index !== 0 }"
          >
            <div
              @click="navigateToContent(item.content_uuid)"
              class="text-gray-800 pt-5 pl-5 transition ease-in-out delay-50 bg-[#F1DEFF] hover:cursor-pointer hover:bg-primary hover:text-white sm:w-[280px] w-[290px] h-[150px] rounded-xl p-6"
            >
              <img
                width="50"
                :src="`https://flagicons.lipis.dev/flags/4x3/${getCountryCode(
                  item.language
                )}.svg`"
                class="rounded-[8px]"
              />
              <h3
                class="text-[1.3rem] text-left mt-1 font-medium h-[60px] overflow-hidden"
              >
                {{ capitalizeFirstLetter(item.subject) }}
              </h3>
            </div>
          </li>
        </ul>

        <div class="mb-10"></div>
      </div>
    </div>
  </div>
  <div v-if="isLoading">
    <Loading />
  </div>
</template>

<script>
import axios from "axios";
import mixin from "./mixin";
import Dropdown from "primevue/dropdown";
import Sidebar from "./Sidebar.vue";
import PayAlert from "./PayAlert.vue";
import LanguageSelection from "./LanguageSelection.vue";
import Loading from "../components/ui/Loading.vue";

export default {
  mixins: [mixin],

  components: {
    Dropdown,
    Sidebar,
    PayAlert,
    LanguageSelection,
    Loading,
  },

  data() {
    return {
      language: null,
      countries: [
        { name: "Inglês", code: "us" },
        { name: "Espanhol", code: "es" },
        { name: "Francês", code: "fr" },
        { name: "Italiano", code: "it" },
        { name: "Alemão", code: "de" },
        { name: "Russo", code: "ru" },
        { name: "Português", code: "br" },
        { name: "Holandês", code: "nl" },
      ],

      sidebarStyle: "",
      contentList: [],
      showSidebar: window.innerWidth >= 1024, // lg breakpoint do Tailwind
      username: "",
      contentLang: "",
      createdAt: "",
      contentSubject: "",
      contentLevel: "",
      contentUUID: "",
      selectedLanguage: null, // Novo estado para armazenar a linguagem selecionada
      isPaid: 0,
      numberOfContent: 0,
      userIdiom: "",
      locale: "",
      isLoading: false,
    };
  },

  computed: {
    sidebarClasses() {
      return {
        "fixed top-0 bottom-0": window.innerWidth < 1024,
        "translate-x-0": this.showSidebar,
        "-translate-x-full": !this.showSidebar && window.innerWidth < 1024,
        "w-4/5": window.innerWidth < 1024,
        relative: window.innerWidth >= 1024,
        "w-full": window.innerWidth >= 1024,
      };
    },

    filteredContentList() {
      // Verifica se contentList[0] existe e não está vazio
      if (!this.contentList[0] || this.contentList[0].length === 0) return [];

      // Se nenhuma linguagem foi selecionada, retorna a lista inteira
      if (!this.selectedLanguage) return this.contentList[0];

      // Filtra a lista com base na linguagem selecionada
      return this.contentList[0].filter((item) => {
        // Certifique-se de que tanto item.language quanto this.selectedLanguage existem antes de compará-los
        if (item.language && this.selectedLanguage) {
          return item.language === this.selectedLanguage;
        }
        return false;
      });
    },
  },
  methods: {
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },

    getCountryCode(name) {
      const country = this.countries.find((country) => country.name === name);
      return country ? country.code : "";
    },

    getFlagUrl(name) {
      const code = this.getCountryCode(name);
      return `https://flagicons.lipis.dev/flags/4x3/${code}.svg`;
    },

    // REDIRECTS //
    navigateToContent(content_uuid) {
      this.$router.push({
        path: `/content/${content_uuid}`,
      });
    },

    navigateToOnboarding() {
      this.$router.push({
        path: `/onboarding`,
      });
    },

    navigateToCreateContent() {
      this.$router.push({
        path: `/create-content`,
      });
    },

    navigateToLogin() {
      this.$router.push({
        path: `/login`,
      });
    },
    // FIM REDIRECTS //

    checkAuthentication(username) {
      if (username) {
        console.log(username);
      } else {
        this.navigateToLogin();
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    // Guarda o usuário
    const username = localStorage.getItem("username");
    const user_id = localStorage.getItem("userId");
    this.learningLanguage = localStorage.getItem("learningLanguage");
    //Puxa os dados da rota /user/content/:username
    this.username = username;
    this.user_id = user_id;

    try {
      const responseB = await axios.get(
        `${process.env.VUE_APP_API_URL}/user/u/${username}`
      );
      var list = [responseB.data];
      // console.log(list)
      this.userIdiom = list[0][0]?.user_idiom;
      this.locale = this.userIdiom;

      // this.loadTranslations();
      this.isLoading = false;

      this.isPaid = list[0][0]?.is_paid;

      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/user/content/${username}/${this.learningLanguage}`
      );
      this.contentList = [response.data];
      this.numberOfContent = response.data.length;
      this.userIdiom = localStorage.getItem("userIdiom");

      // Se for onboarding (não criou conteúdo), envia para Onboarding
      if (this.contentList[0][0]?.is_onboarding) {
        this.navigateToOnboarding();
      }
      // this.loadTranslations();

      // Se não, segue para Dashboard
    } catch (error) {
      console.error("Houve um erro ao buscar os dados:", error);
    }

    // Ajusta e sidebar
    window.addEventListener("resize", () => {
      this.showSidebar = window.innerWidth >= 1024;
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.showSidebar = window.innerWidth >= 1024;
    });
  },
};
</script>