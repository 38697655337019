<template>
  <!-- <div v-if="isLoading">
    <Loading />
  </div> -->
  <div v-if="contentList.length > 0" class="bg-[#F4F0F8] flex flex-col h-screen overflow-y-auto">
    <div class="lg:w-3/4 md:w-3/4 sm:w-[90%] mx-auto">
      <!-- Back button -->
      <div class="relative cursor-pointer" @click="navigateToDashboard()">
        <i class="pi pi-arrow-left absolute left-5 top-5 hover:scale-[120%] transition ease-in-out delay-50 block"
          style="font-size: 1.5rem"></i>
      </div>

      <!-- Div do Título e do Switch -->
      <div class="justify-between items-center p-[10px] sm:p-[40px] rounded-lg mt-[60px]">
        <div class="flex mb-10">
          <img v-if="language" :alt="language" :src="getFlagUrl(language)" class="w-[60px] rounded-lg mr-4"
            :class="`mr-2 flag flag-${getCountryCode(language)}`" />
          <h1 class="text-3xl text-gray-800 font-bold mt-1">
            {{ capitalizeFirstLetter(subject) }}
          </h1>
        </div>
      </div>

      <!-- Área Principal e Área à Direita -->
      <div class="flex flex-row justify-between mx-auto mb-[200px]">
        <!-- DIV PRINCIPAL DE CONTEÚDO -->
        <div class="flex flex-wrap fade-in">
          <!-- OPÇÕES DO CONTEÚDO-->
          <ContentMenu />

          <!-- Área Principal -->
          <div class="p-[20px] sm:p-[50px] m-0 w-full sm:w-full bg-white rounded-lg">
            <div class="space-y-9">
              <div class="justify-right flex">
                <InputSwitch v-model="showTranslation" />
                <p class="ml-2">{{ $t("contentView.activateTranslation") }}</p>
              </div>

              <div v-if="isTranslationLoading" class="flex justify-center mx-auto">
                <i class="fa-solid fa-wand-magic-sparkles fa-spin text-primary mt-[20px] mb-[20px]"
                  style="font-size: 25px"></i>
              </div>

              <div v-else v-for="(sentence, index) in originalSentences" :key="index">
                <p class="font-medium text-primary text-[1.4rem] cursor-pointer" @click="wordClicked($event)"
                  @touchstart="wordClicked($event)" :key="'original-' + index">
                  {{ sentence }}
                </p>
                <p v-if="showTranslation && translatedSentences[index]"
                  class="text-gray-400 mt-2 font-normal text-[1.4rem]">
                  {{ translatedSentences[index] }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:hidden fixed top-30 right-0 bg-gray-100 z-50 flex flex-col items-start ml-4 rounded-l-lg"></div>
      </div>

      <div id="audioPlayer"
        class="fixed bottom-0 left-0 right-0 flex flex-col justify-center items-center p-8 bg-[#2d054b]">

        <audio ref="audio">
          <source v-if="contentList.length > 0 && contentList[0].audio_path" :src="`${contentList[0].audio_path}`"
            type="audio/mpeg" />
        </audio>

        <!-- Botões de Controle -->
        <div class="flex space-x-4 mb-4">

          <div
            class="mt-1 block w-[50px] h-[50px] rounded-full overflow-hidden border border-[3px] border-white transform hover:scale-110 transition ease-in-out delay-50">
            <button @click="goBackward" class="w-full h-full  text-white justify-center content-center">
              <i class="fa-solid fa-backward" style="font-size: 25px; margin-left: -4px"></i>
            </button>
          </div>

          <div
            class="-mt-1 block w-[60px] h-[60px] rounded-full overflow-hidden border border-[3px] border-white transform hover:scale-110 transition ease-in-out delay-50">

            <button @click="playPauseAudio" class="w-full h-full text-white justify-center content-center">
              <i :class="isPlaying ? 'fa-solid fa-pause' : 'fa-solid fa-play'"
                style="font-size: 30px; margin-left: 3px"></i>
            </button>
          </div>

          <div
            class="mt-1 block w-[50px] h-[50px] rounded-full overflow-hidden border border-[3px] border-white transform hover:scale-110 transition ease-in-out delay-50">
            <button @click="goForward" class="w-full h-full text-white justify-center content-center">
              <i class="fa-solid fa-forward" style="font-size: 25px; padding-left: 4px"></i>
            </button>
          </div>

        </div>

        <div class="flex items-center w-full">
          <span id="currentTime" class="w-1/12 text-white">0:00</span>
          <div id="progressBar" class="relative w-full h-1 bg-white mr-4 cursor-pointer" @mousedown="startDrag"
            @touchstart="startDrag">
            <div id="progress" class="absolute left-0 h-1 bg-purple-200" style="background-color: #9200ff"></div>
          </div>
          <span id="totalTime" class="w-1/12 text-white right-0">0:00</span>
        </div>
      </div>

      <!-- Caixa de tradução -->
      <div class="translationBox" ref="translationBox">
        <strong>{{ selectedWord }}</strong>
        <p v-if="isWordLoading">
          <i class="fa-solid fa-wand-magic-sparkles fa-spin text-primary mt-[20px] mb-[20px]" style="font-size: 17px"></i>
        </p>
        <p v-else>{{ wordTranslation }}</p>
      </div>
    </div>
  </div>

  <div v-if="isLoading">
    <Loading />
  </div>
</template>

<script>
import axios from "axios";
import mixin from "./mixin";
import InputSwitch from "primevue/inputswitch";
import ContentMenu from "./ContentMenu.vue";
import Loading from "../components/ui/Loading.vue";

export default {
  mixins: [mixin],

  components: {
    InputSwitch,
    ContentMenu,
    Loading,
  },

  data() {
    return {
      isDragging: false,
      contentList: [],
      currentStep: 1,
      language: "",
      translationLanguage: "",
      level: "",
      user_id: "",
      subject: "",
      userIdiom: "",
      errorStep1: false,
      errorStep2: false,
      errorStep3: false,
      selectedWord: "",
      wordTranslation: "",
      showTranslation: "",
      originalSentences: "",
      translatedSentences: "",
      sentence: "",
      isSidebarOpen: false,
      audioPath: "",
      checked: false,
      isPlaying: false,
      isWordLoading: false,
      audioDuration: 0,
      currentTime: 0,
      createdAt: "",

      // Tratar data
      dataString: "",
      dataObj: "",
      dia: "",
      mes: "",
      ano: "",

      language: null,
      countries: [
        { name: "Inglês", code: "us" },
        { name: "Espanhol", code: "es" },
        { name: "Francês", code: "fr" },
        { name: "Italiano", code: "it" },
        { name: "Alemão", code: "de" },
        { name: "Russo", code: "ru" },
        { name: "Português", code: "br" },
        { name: "Holandês", code: "nl" },
      ],
      isTranslationLoading: false,
    };
  },
  async mounted() {
    
    const contentUUID = this.$route.params.contentUUID;

    this.initAudio();

    try {
      this.isLoading = true;
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/content/${contentUUID}`
      );

      this.contentList = [response.data];

      this.originalSentences = JSON.parse(
        this.contentList[0]?.original_sentences
      );

      console.log(this.originalSentences);
      // this.originalSentences = this.contentList[0]?.original_sentences.split('\n').filter(Boolean).map(s => s.trim());
      // Uppercase first letter
      this.subject =
        this.contentList[0]?.subject.charAt(0).toUpperCase() +
        this.contentList[0]?.subject.slice(1);

      this.contentUUID = this.contentList[0].content_uuid;
      this.audioPath = this.contentList[0]?.audio_path;
      this.language = localStorage.getItem("language");
      this.translationLanguage = this.contentList[0]?.language;
      this.userIdiom =
        this.contentList[0]?.user_idiom || localStorage.getItem("userIdiom");

      console.log("cONTENTVIEW: ", this.userIdiom);

      const username = localStorage.getItem("username");
      this.username = username;

      this.isLoading = false;

      const responseB = await axios.get(
        `${process.env.VUE_APP_API_URL}/user/u/${username}`
      );
      var list = [responseB.data];
      this.locale = list[0][0]?.user_idiom;
      this.user_id = list[0][0]?.user_id;
      localStorage.setItem("userId", this.user_id);

      // this.loadTranslations();

      // Tratando a data
      this.dataString = this.createdAt = this.contentList[0]?.created_at;
      this.dataObj = new Date(this.dataString);

      this.dia = String(this.dataObj.getUTCDate()).padStart(2, "0");
      this.mes = String(this.dataObj.getUTCMonth() + 1).padStart(2, "0"); // Os meses vão de 0 a 11, então adicionamos 1
      this.ano = this.dataObj.getUTCFullYear();
    } catch (error) {
      console.error("Houve um erro ao buscar os dados:", error);
    }
  },

  beforeUnmount() {
    this.cleanupAudio();
  },

  methods: {

    setupMobileInteraction() {
      const textArea = this.$refs.textArea; // Supondo que você tenha um ref `textArea` no seu elemento de texto
      textArea.addEventListener('touchend', (event) => {
        event.preventDefault(); // Prevenindo zoom e scroll
        let selectedText = window.getSelection().toString().trim();
        if (selectedText) {
          this.selectedWord = selectedText;
          this.fetchWordTranslation(selectedText);
          this.showTranslationBox(event);
        }
      });
    },


    showTranslationBox(event) {
      if (this.$refs.translationBox) {
        const range = window.getSelection().getRangeAt(0);
        const rect = range.getBoundingClientRect();
        this.$refs.translationBox.style.left = `${rect.left + window.scrollX}px`;
        this.$refs.translationBox.style.top = `${rect.bottom + window.scrollY}px`;
        this.$refs.translationBox.style.display = 'block';

        this.translationBoxTimeout = setTimeout(() => {
          this.$refs.translationBox.style.display = 'none';
        }, 10000);
      }
    },
    startDrag(event) {
      this.isDragging = true;
      this.updateProgressOnEvent(event);

      window.addEventListener('mousemove', this.updateProgressOnEvent);
      window.addEventListener('mouseup', this.stopDrag);
      window.addEventListener('touchmove', this.updateProgressOnEvent);
      window.addEventListener('touchend', this.stopDrag);
    },
    updateProgressOnEvent(event) {
      if (!this.isDragging) return;

      // Suporta eventos de toque e de mouse
      const clientX = event.touches ? event.touches[0].clientX : event.clientX;

      const progressBar = document.getElementById('progressBar');
      const bounds = progressBar.getBoundingClientRect();
      const position = Math.max(0, Math.min(1, (clientX - bounds.left) / bounds.width));
      const audioElement = this.$refs.audio;
      const newTime = position * audioElement.duration;

      if (!isNaN(newTime)) {
        audioElement.currentTime = newTime;
      }
    },

    stopDrag() {
      window.removeEventListener('mousemove', this.updateProgressOnEvent);
      window.removeEventListener('mouseup', this.stopDrag);
      window.removeEventListener('touchmove', this.updateProgressOnEvent);
      window.removeEventListener('touchend', this.stopDrag);
      this.isDragging = false;
    },

    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },

    getCountryCode(name) {
      const country = this.countries.find((country) => country.name === name);
      return country ? country.code : "";
    },

    getFlagUrl(name) {
      const code = this.getCountryCode(name);
      return `https://flagicons.lipis.dev/flags/4x3/${code}.svg`;
    },

    /// AUDIO PLAYER

    playPauseAudio() {
      const audioElement = this.$refs.audio;
      if (this.isPlaying) {
        audioElement.pause();
      } else {
        audioElement.play();
      }
      this.isPlaying = !this.isPlaying;

      audioElement.addEventListener("timeupdate", this.updateCurrentTime);
      audioElement.addEventListener("timeupdate", this.updateProgressBar);
    },

    goBackward() {
      const audioElement = this.$refs.audio;
      audioElement.currentTime -= 10;
    },

    goForward() {
      const audioElement = this.$refs.audio;
      audioElement.currentTime += 10;
    },

    updateCurrentTime() {
      const audioElement = this.$refs.audio;

      if (!audioElement) return;

      this.currentTime = audioElement.currentTime;
      this.audioDuration = audioElement.duration;

      const minutes = Math.floor(this.currentTime / 60);
      const seconds = Math.floor(this.currentTime % 60)
        .toString()
        .padStart(2, "0");

      document.getElementById(
        "currentTime"
      ).textContent = `${minutes}:${seconds}`;
    },

    updateProgressBar() {
      const audioElement = this.$refs.audio;
      if (!audioElement) return; // Verifica se o elemento de áudio existe

      const progressBar = document.getElementById("progress");
      if (!progressBar) return; // Verifica também se o progressBar existe

      if (audioElement.duration > 0) {
        const percentage =
          (audioElement.currentTime / audioElement.duration) * 100;
        progressBar.style.width = percentage + "%";
      }
    },

    initAudio() {
      this.$nextTick(() => {
        const audioElement = this.$refs.audio;

        if (audioElement) {
          audioElement.addEventListener("loadedmetadata", () => {
            this.audioDuration = audioElement.duration;
            const minutes = Math.floor(this.audioDuration / 60);
            const seconds = Math.floor(this.audioDuration % 60)
              .toString()
              .padStart(2, "0");
            document.getElementById(
              "totalTime"
            ).textContent = `${minutes}:${seconds}`;
          });

          audioElement.addEventListener(
            "loadedmetadata",
            this.handleLoadedMetadata
          );
          audioElement.addEventListener("timeupdate", this.updateCurrentTime);
          audioElement.addEventListener("timeupdate", this.updateProgressBar);
        }
      });
    },

    cleanupAudio() {
      const audioElement = this.$refs.audio;
      if (audioElement) {
        audioElement.removeEventListener(
          "loadedmetadata",
          this.handleLoadedMetadata
        );
        audioElement.removeEventListener("timeupdate", this.updateCurrentTime);
        audioElement.removeEventListener("timeupdate", this.updateProgressBar);
      }
    },
  },
  watch: {
    showTranslation(newValue) {
      console.log("Dentro de Showtranslation", this.userIdiom);

      // Log adicional
      console.log("Valor de newValue:", newValue);
      console.log("Estado de translationRequested:", this.translationRequested);

      if (newValue && !this.translationRequested) {
        this.requestTranslation(this.userIdiom);
      }
    },

    audioPath: {
      immediate: true,
      handler() {
        this.initAudio();
      },
    },
  },
};
</script>

<style scoped></style>