<template>
  <div class="mb-4 p-[0px] w-full rounded-lg rounded-t-none justify-center flex">
    <ul class="flex justify-between w-full space-x-1 font-medium text-lg justify-center">
      <li
        class="flex w-1/3 mb-2 cursor-pointer text-gray-800 hover:text-primary bg-white p-5 rounded-lg justify-center sm:justify-left md:justify-left">
        <router-link :to="{ name: 'ContentView', params: { content_uuid: contentUUID } }"
          class="flex flex-col justify-center items-center">
          <i class="fa-solid fa-headphones text-primary" style="font-size: 30px"></i>
          <span class="hidden sm:block">{{
            $t("contentMenu.audioAndText")
          }}</span>
        </router-link>
      </li>
      <li
        class="flex w-1/3 mb-2 cursor-pointer text-gray-800 hover:text-primary bg-white p-5 rounded-lg justify-center sm:justify-left md:justify-left">
        <router-link :to="{ name: 'Pronunciation', params: { content_uuid: contentUUID } }"
          class="flex flex-col justify-center items-center">
          <i class="fa fa-microphone text-primary" style="font-size: 30px"></i>
          <span class="hidden sm:block">{{
            $t("contentMenu.pronunciation")
          }}</span>
        </router-link>
      </li>
      <li
        class="flex w-1/3 mb-2 cursor-pointer text-gray-800 hover:text-primary bg-white p-5 rounded-lg justify-center sm:justify-left md:justify-left">
        <router-link :to="{ name: 'Writing', params: { content_uuid: contentUUID } }"
          class="flex flex-col justify-center items-center">
          <i class="fa-solid fa-pencil text-primary" style="font-size: 32px"></i>
          <span class="hidden sm:block">{{ $t("contentMenu.writing") }}</span>
        </router-link>
      </li>
      <li
        class="flex w-1/3 mb-2 cursor-pointer text-gray-800 hover:text-primary bg-white p-5 rounded-lg justify-center sm:justify-left md:justify-left">
        <router-link :to="{ name: 'Chat', params: { content_uuid: contentUUID } }"
          class="flex flex-col justify-center items-center">
          <i class="fa-regular fa-comment text-primary" style="font-size: 30px"></i>
          <span class="hidden sm:block">{{ $t("contentMenu.chat") }}</span>
        </router-link>
      </li>
      <li
        class="flex w-1/3 mb-2 cursor-pointer text-gray-800 hover:text-primary bg-white p-5 rounded-lg justify-center sm:justify-left md:justify-left">
        <router-link :to="{ name: 'Exercise', params: { content_uuid: contentUUID } }"
          class="flex flex-col justify-center items-center">
          <i class="fa-solid fa-brain text-primary" style="font-size: 30px"></i>
          <span class="hidden sm:block">{{ $t("contentMenu.exercises") }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  async mounted() {
    try {
      const username = localStorage.getItem("username");
      this.username = username;

      const responseB = await axios.get(
        `${process.env.VUE_APP_API_URL}/user/u/${username}`
      );
      var list = [responseB.data];
      this.locale = list[0][0]?.user_idiom;

      // this.loadTranslations();
    } catch (error) {
      console.error("Houve um erro ao buscar os dados:", error);
    }
  },
};
</script>