<template>
    <div class="h-screen flex fade-in">
      <router-link :to="{path: '/paywall'}" class="relative cursor-pointer" >
            <i class="text-black pi pi-arrow-left absolute left-5 top-5 hover:scale-[120%] transition ease-in-out delay-50	 block" style="font-size: 1.5rem"></i>
        </router-link>    <!-- Coluna da Esquerda -->
      <!-- <Sidebar :username="username"></Sidebar> -->
      <!-- Coluna da Direita -->
      <div class="flex-1 p-10 sm:mt-0 bg-[#F4F0F8] flex flex-col overflow-y-auto ">
        <!-- Parte de Cima --> 

        <!-- Parte de Baixo -->
        <div class="flex-1 overflow-visible w-full mt-10	">
          <!-- Header com Título, Subtítulo  -->
          <div class="flex flex-col md:flex-row items-start md:items-center justify-between w-full md:w-4/5 mb-[20px]">
            <div class="flex flex-col">
              <h2 class="text-4xl font-bold text-gray-700">Assinatura do Lanna</h2>
              <p class="font-medium text-gray-500 mt-4">Preencha seus dados</p>
            </div>
          </div>
          <div class="rounded-lg p-6 w-full">
              <header class="flex justify-between items-center p-5 ">
                  <h2 class="text-2xl font-medium  text-gray-600">
                    Dados da conta
                  </h2>
              </header>
          </div>
          <!-- Pricing Plan Selection Box -->
            <div class="flex flex-wrap justify-center gap-10 ">
                <!-- Starter Plan -->
              <div class="rounded-lg bg-white w-full ">
                <div class="p-10">
                

                <form @submit.prevent="createCostumer()">

                  <div class="flex flex-col items-center justify-center w-full p-10 bg-white rounded-lg ">
                    <div class="text-left w-full">
                    
                      <span class="text-sm text-gray-400 block mb-2">Nome completo</span>
                      <input class="bg-white w-full border border-gray-300  p-4 text-gray-800 transition ease-in-out delay-50 hover:bg-white hover:text-gray-800 rounded-xl mb-4" 
                          type="text" 
                          v-model="name" 
                          placeholder=" " required>
                    
                      <span class="text-sm text-gray-400 block mb-2">CPF/CNPJ</span>
                      <input class="bg-white w-full border border-gray-300  p-4 text-gray-800 transition ease-in-out delay-50 hover:bg-white hover:text-gray-800 rounded-xl mb-4" 
                          type="text" 
                          v-model="cpfCnpj" 
                          placeholder=" " required>
                    
                      <span class="text-sm text-gray-400 block mb-2">Telefone celular</span>
                      <input class="bg-white w-full border border-gray-300 p-4 text-gray-800 transition ease-in-out delay-50 hover:bg-white hover:text-gray-800 rounded-xl mb-4" 
                          type="text" 
                          v-model="mobilePhone" 
                          placeholder=" ">

                      <div class="error-message" v-if="error">Por favor, insira informações válidas.</div>

                      <button type="submit" class="w-full mt-10 rounded-xl p-4 font-bold text-white bg-primary rounded hover:bg-purple-700 focus:outline-none focus:shadow-outline">
                        <i v-if="loading" class="fa-solid fa-circle-notch fa-spin text-white mr-5" style="font-size:20px"></i>
                        Avançar para pagamento
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import axios from "axios";
    import mixin from '../components/mixin';
    import Sidebar from '../components/Sidebar.vue';
    import PayAlert from "../components/PayAlert.vue";
    import InputText from 'primevue/inputtext';
    import Button from 'primevue/button';


    export default {
        mixins: [mixin],

        components:{
            Sidebar, PayAlert, InputText, Button
          },
          
        data() {
            return {
                username: '',    
                userEmail: '',
                user_id: '',
                isPaid: 0,
                inTrial: true,
                learningLanguage: '',
                userIdiom: '' ,
                languageName: '',
                languageMap: {
                  'en-US': 'Inglês',
                  'es-ES': 'Espanhol',
                  'fr-FR': 'Francês',
                  'it-IT': 'Italiano',
                  'de-DE': 'Alemão',
                  'ru-RU': 'Russo',
                  'pt-PT': 'Português',
                  'pt-BR': 'Português',
                  'nl-NL': 'Holandês'
                },
                learningLanguageCode: '',
                isDirectToPay: false,
                plan: '',

                // Customer Data
                cpfCnpj: '',
                mobilePhone: '',
                name: '',

                error: false,
                loading: false
        
            };
        },

        async mounted(){
          const username = localStorage.getItem("username");
          const user_id = localStorage.getItem("userId");
          
          this.isDirectToPay = this.$route.query.isDirectToPay
          // alert(this.isDirectToPay)
          this.username = username
          this.user_id = user_id
          this.plan = this.$route.query.plan
          localStorage.setItem("plan", this.plan );



          const responseB = await axios.get(`${process.env.VUE_APP_API_URL}/user/u/${username}`);
          var list = [responseB.data];
          this.isPaid = list[0][0]?.is_paid;
          this.learningLanguage = list[0][0]?.learning_language;
          this.userIdiom = list[0][0]?.user_idiom;
          this.userEmail = list[0][0]?.email;
          this.user_id = list[0][0]?.user_id;
          localStorage.setItem("userId", this.user_id ) 



          const languageName = list[0][0]?.learning_language; // Nome completo do idioma
          this.learningLanguageCode = this.getCodeFromLanguageName(languageName);

          const responseTrial = await axios.get(`${process.env.VUE_APP_API_URL}/check-trial/${username}`);
          var list = [responseTrial.data];
          this.inTrial = list[0]?.inTrial;
          console.log("Está em trial", this.inTrial)
          console.log("CNPJ", this.cpfCnpj)

        },

        computed: {
          languageName(){
            return this.getLanguageName(this.userIdiom)
          }
        },

        methods: {
          getCodeFromLanguageName(name) {
            return Object.keys(this.languageMap).find(key => this.languageMap[key] === name);
        },

          getLanguageName(isoCode) {
            const languageMap = {
              'en-US': 'Inglês',
              'es-ES': 'Espanhol',
              'fr-FR': 'Francês',
              'it-IT': 'Italiano',
              'de-DE': 'Alemão',
              'ru-RU': 'Russo',
              'pt-PT': 'Português',
              'pt-BR': 'Português',
              'nl-NL': 'Holandês'
            };

            return languageMap[isoCode] || 'Código de idioma desconhecido';
          },

          async createCostumer() {
            const costumerSettings = {
              userId: this.user_id,
              cpfCnpj: this.cpfCnpj,
              mobilePhone: this.mobilePhone,
              name: this.name,
              plan: this.plan
            }

            this.loading = true;

            try { 
              const response = await axios.post('${process.env.VUE_APP_API_URL}/payment/createCustomer', costumerSettings );
                if (response.status === 200) {

                  localStorage.setItem("plan", this.plan );
                
                  localStorage.setItem("cpfCnpj", this.cpfCnpj)
                  localStorage.setItem("mobilePhone", this.mobilePhone)
                  localStorage.setItem("name", this.name)
                  this.plan = localStorage.getItem("plan")

                  
                  this.$router.push({ path: '/checkout', query: { plan:  this.plan } });

                  localStorage.setItem("invoiceUrl", response.data.invoiceUrl)

                  console.log(response.data)                  


                }
              } catch(error) {
                console.error("Erro ao buscar resposta da API:", error);
            } finally {
              this.loading = false;
            }
          } 
        },
          
        watch: {
          userIdiom(newVal, oldVal) {
            if (newVal !== oldVal) {
              // this.updateUserLanguage();
            }
          },       
        }
    }
</script>
