<template>
  <!-- <div class="flex flex-col sm:min-h-[200px] md:min-h-[500px] lg:min-h-[600px] overflow-y-auto"> -->
  <div class="flex flex-col sm:min-h-[200px] md:min-h-[500px] lg:min-h-[600px]">
    <div class="flex-grow overflow-y-auto hide-scrollbar mb-5 h-screen" ref="chatContainer">
      <!-- Histórico do chat vindo do banco -->
      <ul v-if="parsedContent && parsedContent != ''" class="space-y-4">
        <li v-for="(message, index) in parsedContent" :key="'history-' + index"
          :class="message.role === 'user' ? 'text-right' : ''" class="relative">
          <img v-if="language" :alt="language" :src="getFlagUrl(language)"
            class="w-[30px] rounded mr-4 absolute -top-4 left-2" :class="message.role === 'user'
              ? 'hidden'
              : `mr-2 flag flag-${getCountryCode(language)}`
              " />

          <p class="inline-block py-4 px-4 rounded-lg max-w-[70%] font-medium" :class="message.role === 'user'
            ? 'bg-primary text-white'
            : 'bg-[#F1DEFF] text-gray-700'
            ">
            {{
              message.role === "assistant"
              ? JSON.parse(message.content).system[0]
              : ""
            }}
            {{ message.role === "user" ? message.content : "" }}
          </p>

          <!-- Tradução do Assistant -->
          <p class="mb-8 text-sm mt-1 ml-4 max-w-[60%]" v-if="message.role === 'assistant'">
            {{ JSON.parse(message.content).system[1] }}
          </p>
        </li>
      </ul>
      <ul v-else>
        <!-- <p>Sem chat</p> -->
      </ul>

      <ul class="space-y-4 mt-6">
        <li :v-model="chatMessages" class="relative">
          <p class="text-right flex flex-col">
            <span v-if="chatMessages.display !== false" v-for="(userMsg, index) in chatMessages.user" :key="index"
              @click="transmitMsg(chatMessages.user[index])">
              <p v-if="index === 0 || index === 2 || index === 4"
                class="inline-block py-4 px-4 bg-white border border-primary text-black font-medium rounded-lg mr-0 mt-2 mb-1 cursor-pointer transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-105 hover:bg-primary hover:text-white duration-300">
                {{ chatMessages.user[index] }}
              </p>
              <p v-if="index === 1 || index === 3 || index === 5" class="mb-6 text-sm pr-3">
                {{ chatMessages.user[index] }}
              </p>
            </span>
          </p>
        </li>
      </ul>

      <div v-if="isLoading" class="flex justify-center mx-auto">
        <i class="fa-solid fa-wand-magic-sparkles fa-spin text-primary mt-[20px] mb-[20px]" style="font-size: 25px"></i>
      </div>
    </div>

    <div class="pt-5 pb-5 border-t border-[#F1DEFF] rounded-xl">
      <div class="w-full space-y-3 flex w-[300px] sm:w-full md:w-full mx-auto font-medium text-primary text-[1.2rem]  border-2 border-primary rounded-xl  text-center position-relative">
        <input type="text" v-model="userMessage" id="message" @keyup.enter="sendMessage"
          placeholder="Digite sua mensagem..."
          class="w-[90%] h-50 sm:w-full md:w-full  mx-auto font-medium text-gray-700 text-[1.2rem] rounded-xl p-5 focus:outline-none" />

        <!-- <div @click="sendMessage" class="ml-6 py-4 px-6  text-white rounded-lg position-absolute l-0"> -->
        <div @click="sendMessage" class="mr-5 mt-2 position-absolute">
          <i class="fa-solid fa-paper-plane text-primary mt-2 cursor-pointer  transition ease-in-out delay-50 hover:scale-[1.2]"
            style="font-size:24px"></i>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import mixin from "./mixin";
import axios from "axios";

export default {
  mixins: [mixin],

  props: ["language", "countries", "subject"],

  mounted() {
    this.scrollToEnd();
  },

  data() {
    return {
      chatList: [],
      userMessage: "",
      messages: [],
      correction: false,
      user_id: "1",
      content_uuid: "",
      user_idiom: "fr",
      content: "",
      typingMessage: "",
      isTyping: false,
      original_sentences: "",
      level: "",
      chatMessages: {},
      translatedUserMsgs: [],
      audioPath: "",
      userIdiom: "",
      learningLanguage: "",
      isLoading: false,
    };
  },
  async created() {
    try {
      this.chatMessages.display = true;

      const contentUUID = this.$route.params.contentUUID;
      // Busca informações do usuário
      const username = localStorage.getItem("username");
      const responseB = await axios.get(
        `${process.env.VUE_APP_API_URL}/user/u/${username}`
      );
      var list = [responseB.data];
      this.userIdiom = list[0][0]?.user_idiom;
      this.learningLanguage = list[0][0]?.learning_language;
      console.log(this.userIdiom, this.learningLanguage);

      // Inicializa a tentativa de buscar e parsear o conteúdo do chat
      let contentFetched = false;
      let attempts = 0;
      while (!contentFetched && attempts < 3) {
        try {
          const response = await axios.get(
            `${process.env.VUE_APP_API_URL}/chat/${contentUUID}`
          );
          this.content = response.data.content;
          this.original_sentences = response.data.original_sentences;
          this.level = response.data.level;

          const responseB = await axios.get(
            `${process.env.VUE_APP_API_URL}/user/u/${username}`
          );
          var list = [responseB.data];
          this.locale = list[0][0]?.user_idiom;
          console.log(" this.locale", this.locale);

          // this.loadTranslations();

          // Tenta parsear o conteúdo JSON
          const parsedContent = JSON.parse(this.content);
          if (parsedContent && parsedContent.length > 0) {
            const lastMessage = parsedContent[parsedContent.length - 1];
            if (lastMessage.role === "assistant") {
              this.chatMessages = {
                system: JSON.parse(lastMessage.content).system[0],
                user: JSON.parse(lastMessage.content).user,
              };
              console.log(this.chatMessages);
            }
          }
          contentFetched = true; // Conteúdo foi buscado e parseado com sucesso
        } catch (error) {
          attempts++;
          console.log(
            `Tentativa ${attempts}: Falha na busca ou no parsing do conteúdo.`,
            error
          );
          if (attempts >= 3) {
            console.error(
              "Não foi possível buscar ou analisar o conteúdo após 3 tentativas."
            );
          }
        }
      }

      // Lógica adicional para quando o conteúdo está vazio
      if (!this.content) {
        this.transmitMsg("✋");
        this.userMessage = "";
      }
    } catch (error) {
      console.error("Houve um erro ao buscar os dados:", error);
    }
  },

  methods: {
    addMessageToHistory(role, content) {
      this.parsedContent.push({
        role: role,
        content: content,
      });
      // this.msgTranslation(this.chatMessages.user)
      // this.msgTranslation(this.chatMessages.system)
    },

    transmitMsg(string) {
      this.userMessage = string;
      this.chatMessages.user = [string];
      this.chatMessages.user = "";
      this.sendMessage();
    },

    getCountryCode(name) {
      const country = this.countries.find((country) => country.name === name);
      return country ? country.code : "";
    },

    getFlagUrl(name) {
      const code = this.getCountryCode(name);
      return `https://flagicons.lipis.dev/flags/4x3/${code}.svg`;
    },

    scrollToEnd() {
      this.$nextTick(() => {
        const container = this.$refs.chatContainer;
        if (container) {
          // Aplique um delay
          setTimeout(() => {
            container.scrollTop = container.scrollHeight;
          }, 200); // 200 milissegundos de delay
        } else {
          console.log("Elemento não encontrado");
        }
      });
    },

    async sendMessage() {
      this.isLoading = true;

      // Adicionar a mensagem do usuário imediatamente antes da chamada à API
      const newMessage = {
        user: this.userMessage,
        assistant: "",
      };

      this.addMessageToHistory("user", newMessage.user);
      this.messages.push(newMessage);

      this.userMessage = "";

      try {
        this.chatMessages.display = false; // Se você adicionar um estado de exibição no objeto chatMessages
        const contentUUID = this.$route.params.contentUUID;
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api`, {
          type: "chat",
          user_id: this.user_id,
          content_uuid: contentUUID,
          message: newMessage.user, // Aqui utilizamos o valor que já tínhamos guardado
          user_idiom: this.user_idiom,
          correction: this.correction,
          subject: this.subject,
          original_sentences: this.original_sentences,
          language: this.language,
          level: this.level,
          loadedChatHistory: this.loadedChatHistory,
          learningLanguage: this.learningLanguage,
          userIdiom: this.userIdiom,
        });

        this.audioPath = response.data.audioPath;

        const chatMessage = response.data.chatMessage;
        console.log(chatMessage);
        this.chatMessages = JSON.parse(chatMessage);
        // console.log(JSON.parse(this.chatMessages))
        this.addMessageToHistory("assistant", chatMessage);

        this.chatMessages.display = true;
        this.isLoading = false;


        // Atualizar a resposta do assistente
        // await this.typeMessageEffect(chatMessage, this.messages.length - 1);
        this.scrollToEnd(); // Rolar até o final novamente
      } catch (error) {
        console.error("An error occurred while sending the message:", error);
      }
    },

    async translateMessage(message) {
      return `Translated: ${message}`;
    },

    msgTranslation(string) {
      console.log("Msg translation ativada");
      axios
        .post(`${process.env.VUE_APP_API_URL}/translate`, {
          text: string,
        })
        .then((response) => {
          console.log("Tradução do Mensagem Enviada", response.data); // Adicione esta linha
          this.translatedUserMsgs = response.data;
        })
        .catch((error) => {
          console.error("Erro ao buscar tradução da API:", error);
        });
    },
  },

  computed: {
    parsedContent() {
      if (!this.content) {
        //console.warn("Conteúdo está vazio.");

        return [];
      }

      try {
        return JSON.parse(this.content);
      } catch (e) {
        console.error("Erro ao analisar o conteúdo:", e);
        return [];
      }
    },
  },
};
</script>

<style scoped>
.hide-scrollbar::-webkit-scrollbar {
  display: none;
  /* para Chrome, Safari e Opera */
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* para Internet Explorer e Edge */
  scrollbar-width: none;
  /* para Firefox */
}
</style>