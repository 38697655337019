<template>
	<div class="h-screen flex">
		<!-- Coluna da Esquerda -->
		<Sidebar :username="username"></Sidebar>

		<!-- Coluna da Direita -->
		<div
			class="flex-1 p-10 mt-10 sm:mt-0 bg-[#F4F0F8] flex flex-col overflow-y-auto"
		>
			<LanguageSelection></LanguageSelection>

			<!-- PayAlert -->
			<PayAlert v-if="isPaid === 0"/>

			<!-- Parte de Cima -->
			<div
				class="flex justify-end p-4 h-[80px] mb-[20px] mt-8 pr-10 font-bold text-lg items-center"
			>
				<!-- Se houver algum conteúdo aqui, transforme-o também -->
			</div>

			<!-- Parte de Baixo -->
			<div class="flex-1 overflow-visible w-full">
				<!-- Header com Título, Subtítulo -->
				<div
					class="flex flex-col md:flex-row items-start md:items-center justify-between w-full md:w-4/5 mb-[20px]"
				>
					<div class="flex space-x-5">
						<h2 class="text-4xl font-bold text-gray-700">
							{{ $t('account.myAccount') }}
						</h2>
						<p class="font-medium text-gray-500 mt-4">
							{{ $t('account.configureUserAndLanguage') }}
						</p>
					</div>
				</div>

				<div class="mb-20"></div>

				<div>
					<div class="bg-white rounded-lg p-6 w-[90%]">
						<header class="flex justify-between items-center p-5">
							<h2 class="text-2xl font-medium text-gray-600">
								{{ $t('account.accountData') }}
							</h2>
						</header>

						<div class="mb-10"></div>

						<!-- Dados da Conta -->
						<div class="p-4 space-y-5">
							<div class="flex flex-col">
                <span class="font-medium text-lg"
                >{{ $t('account.name') }}:
                </span>
								<p class="text-lg">{{ username }}</p>
							</div>
							<div class="flex flex-col">
                <span class="font-medium text-lg"
                >{{ $t('account.email') }}:
                </span>
								<div class="flex flex-col">
									<input
										type="text"
										:placeholder="userEmail"
										:disabled="!isEditingEmail"
										v-model="userEmail"
										class="sm:sm:w-[350px] p-2 rounded-lg border-gray-300 border h-[60px]"
										:class="{
                      'bg-gray-200': !isEditingEmail,
                      'bg-white': isEditingEmail,
                    }"
									/>
								</div>
							</div>
						</div>

						<HrSpacing/>

						<Notification
							v-if="isNotificationVisible"
							:key="notificationKey"
							:msg="notificationMessage"
							:type="notificationType"
						/>

						<!-- Senha -->
						<header class="flex justify-between items-center p-5">
							<h2 class="text-2xl font-medium text-gray-600">
								{{ $t('account.password') }}
							</h2>
						</header>

						<div class="p-4 space-y-5">
							<div class="flex flex-col">
                <span class="font-medium text-lg"
                >{{ $t('account.currentPassword') }}:
                </span>
								<input
									type="password"
									placeholder="******"
									v-model="currentPassword"
									:disabled="!isEditingPassword"
									class="sm:w-[350px] p-2 rounded-lg border-gray-300 border h-[60px]"
									:class="{
                    'bg-gray-200': !isEditingPassword,
                    'bg-white': isEditingPassword,
                  }"
								/>
							</div>
							<div class="flex flex-col" v-if="isEditingPassword">
                <span class="font-medium text-lg"
                >{{ $t('account.newPassword') }}:
                </span>
								<input
									type="password"
									placeholder="******"
									v-model="newPassword"
									class="sm:w-[350px] p-2 rounded-lg border-gray-300 border h-[60px]"
								/>
							</div>
							<div class="flex flex-col" v-if="isEditingPassword">
                <span class="font-medium text-lg"
                >{{ $t('account.confirmNewPassword') }}:
                </span>
								<input
									type="password"
									placeholder="******"
									v-model="confirmPassword"
									class="sm:w-[350px] p-2 rounded-lg border-gray-300 border h-[60px]"
								/>
							</div>
							<button
								@click="togglePasswordEdit"
								:class="{
                  'bg-primary text-white p-4 ': isEditingPassword,
                  'bg-transparent text-gray-700 font-normal text-left':
                    !isEditingPassword,
                }"
								class="font-medium text-lg rounded-lg sm:w-[350px]"
							>
                <span v-if="isEditingPassword && !isSavingPassword">{{
		                $t('account.save')
	                }}</span>
								<span v-else-if="isSavingPassword">{{
										$t('account.saving')
									}}</span>
								<span v-else>{{ $t('account.changePassword') }}</span>
								<i v-if="isLoadingPassword" class="fa-solid fa-spinner"></i>
							</button>
						</div>

						<HrSpacing/>

						<!-- Configuração de Idioma -->
						<header class="flex justify-between items-center p-5">
							<h2 class="text-2xl font-medium text-gray-600">
								{{ $t('account.languageSettings') }}
							</h2>
						</header>

						<div class="p-4 space-y-5">
							<div class="flex flex-col">
                <span class="font-medium text-lg"
                >{{ $t('account.systemLanguage') }}:</span
                >
								<select
									v-model="userIdiom"
									@change="saveSystemLanguage"
									class="sm:w-[350px] mt-4 p-2 rounded-lg border-gray-300 border h-[60px]"
								>
									<option
										v-for="(name, code) in languageMap"
										:key="code"
										:value="code"
									>
										{{ $t(name) }}
									</option>
								</select>
							</div>
						</div>

						<HrSpacing/>

						<div class="p-4 space-y-5">
							<div class="flex flex-col">
                <span
	                @click="logoutUser"
	                class="font-medium text-lg text-[#FF3B3B] cursor-pointer"
                >{{ $t('account.logout') }}</span
                >
							</div>
						</div>

						<!-- Botão de Salvar -->
						<!-- <button @click="saveUserSettings" class="w-[250px] h-[60px] bg-primary text-white font-medium text-lg mt-[50px] mb-[20px] ml-[10px] rounded-2xl">{{ $t('account.save') }}</button> -->
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-if="isLoading">
		<Loading/>
	</div>
</template>

<script>
import axios from 'axios';
import mixin from './mixin';
import Sidebar from './Sidebar.vue';
import PayAlert from './PayAlert.vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import LanguageSelection from './LanguageSelection.vue';
import HrSpacing from './ui/HrSpacing.vue';
import Notification from './ui/Notification.vue';
import Loading from '../components/ui/Loading.vue';

export default {
	mixins: [mixin],

	components: {
		Sidebar,
		PayAlert,
		InputText,
		Button,
		LanguageSelection,
		HrSpacing,
		Notification,
		Loading,
	},

	data() {
		return {
			username: '',
			userEmail: '',
			user_id: '',
			isPaid: 0,
			inTrial: true,
			learningLanguage: '',
			userIdiom: '',
			languageName: '',
			languageMap: {
				'en-US': 'register.language.english',
				'es-ES': 'register.language.spanish',
				'fr-FR': 'register.language.french',
				'it-IT': 'register.language.italian',
				'de-DE': 'register.language.german',
				'ru-RU': 'register.language.russian',
				'pt-BR': 'register.language.portuguese',
				'nl-NL': 'register.language.dutch',
			},
			learningLanguageCode: '',
			locale: '',
			isEditingEmail: false,
			isLoading: false,
			isSaving: false,
			isEditingPassword: false,
			isLoadingPassword: false,
			isSavingPassword: false,
			currentPassword: '',
			newPassword: '',
			confirmPassword: '',
		};
	},

	async mounted() {
		this.isLoading = true;

		const username = localStorage.getItem('username');
		const user_id = localStorage.getItem('userId');

		this.username = username;
		this.user_id = user_id;

		const responseB = await axios.get(
			`${ process.env.VUE_APP_API_URL }/user/u/${ username }`,
		);
		var list = [responseB.data];
		this.isPaid = list[0][0]?.is_paid;
		this.learningLanguage = list[0][0]?.learning_language;
		this.userIdiom = list[0][0]?.user_idiom;
		this.userEmail = list[0][0]?.email;
		this.locale = this.userIdiom;

		const languageName = list[0][0]?.learning_language; // Nome completo do idioma

		// this.loadTranslations();
		this.isLoading = false;

		this.learningLanguageCode = this.getCodeFromLanguageName(languageName);

		const responseTrial = await axios.get(
			`${ process.env.VUE_APP_API_URL }/check-trial/${ username }`,
		);
		var list = [responseTrial.data];
		this.inTrial = list[0]?.inTrial;
		console.log('Está em trial', this.inTrial);
	},

	computed: {
		languageName() {
			return this.getLanguageName(this.userIdiom);
		},
	},

	methods: {
		async updateAccountSettings() {
			const username = localStorage.getItem('username');

			const responseB = await axios.get(
				`${ process.env.VUE_APP_API_URL }/user/u/${ username }`,
			);
			var list = [responseB.data];
			this.isPaid = list[0][0]?.is_paid;
			this.learningLanguage = list[0][0]?.learning_language;
			this.userIdiom = list[0][0]?.user_idiom;
			this.userEmail = list[0][0]?.email;
			this.locale = this.userIdiom;
			this.isLoading = true;

			// this.loadTranslations();

			this.isLoading = false;

			const languageName = list[0][0]?.learning_language; // Nome completo do idioma
			this.learningLanguageCode = this.getCodeFromLanguageName(languageName);
		},

		getCodeFromLanguageName(name) {
			return Object.keys(this.languageMap).find(
				(key) => this.languageMap[key] === name,
			);
		},

		getLanguageName(isoCode) {
			const languageMap = {
				'en-US': 'Inglês',
				'es-ES': 'Espanhol',
				'fr-FR': 'Francês',
				'it-IT': 'Italiano',
				'de-DE': 'Alemão',
				'ru-RU': 'Russo',
				'pt-PT': 'Português',
				'pt-BR': 'Português',
				'nl-NL': 'Holandês',
			};

			return languageMap[isoCode] || 'Código de idioma desconhecido';
		},

		async saveUserSettings() {
			const userSettings = {
				// username: this.username,
				userEmail: this.userEmail,
				currentPassword: this.currentPassword,
				newPassword: this.newPassword,
				learningLanguage: this.languageMap[this.learningLanguageCode], // Nome completo do idioma
				userIdiom: this.userIdiom,
			};

			try {
				const response = await axios.post(
					`${ process.env.VUE_APP_API_URL }/user/update/${ this.username }`,
					userSettings,
				);
				if (response.status === 200) {
					// alert(`Sucesso`)
					localStorage.setItem('userIdiom', this.userIdiom);
				} else {
					throw new Error('Falha ao salvar as configurações');
				}
			} catch (error) {
				console.error(error);
				// Tratar erro
			}
		},

		async saveEmail() {
			const userSettings = {
				userEmail: this.userEmail,
			};

			this.isLoading = true;
			this.isSaving = true;

			try {
				const response = await axios.post(
					`${ process.env.VUE_APP_API_URL }/user/update-email/${ this.username }`,
					userSettings,
				);

				if (response.status === 200) {
					console.log(response.status);
					this.showNotification('notifications.emailSuccess', 'success');
				}
			} catch (error) {
				this.showNotification('notifications.emailChangeFail', 'error');
			} finally {
				setTimeout(() => {
					this.isLoading = false;
					this.isSaving = false;
					this.isEditingEmail = false; // Adicione isto para sair do modo de edição quando o salvamento estiver concluído
				}, 1000);
			}
		},

		// Altera o idioma do sistem
		async saveSystemLanguage() {
			const userSettings = {
				userIdiom: this.userIdiom,
			};

			try {
				this.isLoading = true;
				const response = await axios.post(
					`${ process.env.VUE_APP_API_URL }/user/update-system-language/${ this.username }`,
					userSettings,
				);
				if (response.status === 200) {
					localStorage.setItem('userIdiom', this.userIdiom);
					this.$i18n.locale = this.userIdiom;
					this.locale = this.userIdiom;
					this.isLoading = false;

					window.location.reload();

					await this.updateAccountSettings();

					this.showNotification(
						`notifications.systemLanguageChanged`,
						'success',
					);
				}
			} catch (error) {
				this.isLoading = false;
			}
		},

		toggleEmailEdit() {
			if (this.isEditingEmail) {
				this.saveEmail();
			}
			this.isEditingEmail = true;
		},

		// SALVA SENHA
		togglePasswordEdit() {
			if (this.isEditingPassword) {
				if (!this.currentPassword) {
					this.showNotification(
						'notifications.TypeYourCurrentPassword',
						'error',
					);
					return;
				}

				if (!this.newPassword || this.newPassword.length < 6) {
					this.showNotification(
						'notifications.passwordAtLeast6Caract',
						'error',
					);
					return;
				}

				if (this.newPassword === this.confirmPassword) {
					this.savePassword();
				} else {
					this.showNotification('notification.passwordsDoNotMatch', 'error');
				}
			} else {
				this.isEditingPassword = true;
			}
		},

		async savePassword() {
			if (this.newPassword !== this.confirmPassword) {
				// alert(this.$t('account.passwordsDoNotMatch'));
				this.showNotification('notification.passwordsDoNotMatch', 'error');
				return;
			}

			const passwordSettings = {
				currentPassword: this.currentPassword,
				newPassword: this.newPassword,
			};

			this.isLoadingPassword = true;
			this.isSavingPassword = true;

			try {
				const response = await axios.post(
					`${ process.env.VUE_APP_API_URL }/user/update-password/${ this.username }`,
					passwordSettings,
				);

				console.log(response.status);

				if (response.status === 200) {
					this.showNotification(
						'notification.passwordChangedWithSuccess',
						'success',
					);
					// this.showNotification(this.$t('account.passwordsDoNotMatch'), "sucess")

					this.isEditingPassword = false;
				}
			} catch (error) {
				this.showNotification('notification.invalidCurrentPassword', 'error');
			} finally {
				setTimeout(() => {
					this.isLoadingPassword = false;
					this.isSavingPassword = false;
					// this.isEditingPassword = false; // Encerrar modo de edição de senha
				}, 2000); // Defina um pequeno atraso para garantir que a alteração de estado seja refletida corretamente
			}
		},

		logoutUser() {
			localStorage.clear();
			this.$router.push('/login');
		},
	},

	watch: {
		userIdiom(newVal, oldVal) {
			if (newVal !== oldVal) {
				// this.updateUserLanguage();
			}
		},
	},
};
</script>
