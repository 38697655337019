<template>
	<div class="bg-[#F4F0F8] flex flex-col-reverse md:flex-row min-h-screen items-center justify-center">
		<div
			class="w-full mx-auto md:w-5/5 lg:w-3/4 xl:w-2/3 flex flex-col justify-center items-center p-4 md:p-10 lg:p-20 rounded-lg md:order-2">
			<!-- <div>
			<img src="https://guiadeidiomas.com.br/wp-content/uploads/2021/06/logo-guia-de-idiomas-2.png" alt="Logo" class=" m-10 w-[250px] "/>
		  </div> -->

			<div class="space-y-5 flex flex-col items-center justify-center text-center w-full max-w-lg mx-auto">
				<!-- <h1 class="text-3xl text-gray-800 font-bold text-white">Registrar</h1> -->
				<form @submit.prevent="registerUser">
					<!-- Step 1: Escolha do idioma -->
					<div v-if="step === 1" class="language-selection">
						<h1 class="text-3xl text-gray-800 font-bold text-gray-600">
							{{ $t('register.Start') }}
						</h1>
						<h1 class="text-3xl text-gray-800 font-bold text-gray-600">
							{{ $t('register.nativeLanguageQuestion') }}
						</h1>
						<p class="mt-5 text-gray-600">
							{{ $t('register.languageApplicationInfo') }}
						</p>

						<div class="flex justify-center flex-wrap gap-4 mt-20 mb-20">
							<div v-for="languageCode in languages_code" :key="languageCode.locale"
							     @click="selectUserIdiom(languageCode)"
							     class="cursor-pointer p-4 border border-white bg-white w-[150px] h-[150px] flex flex-col items-center justify-center space-y-3 text-gray-800 transition ease-in-out delay-50 hover:scale-[1.06] hover:bg-primary hover:text-white rounded-xl p-6">
								<div>
									<img width="60" :src="`https://flagicons.lipis.dev/flags/4x3/${getCountryCode(
          languageCode.flag
        )}.svg`" class="rounded-[6px]"/>
								</div>

								<div>
									<p>{{ $t(languageCode.name) }}</p>
								</div>
							</div>
						</div>
					</div>

					<!-- Step 2: Escolha do idioma que quer aprender -->
					<div v-if="step === 2" class="language-selection">
						<h1 class="text-3xl text-gray-800 font-bold text-gray-600">
							{{ $t('register.whatLanguageLearn') }}
						</h1>

						<div class="flex justify-center flex-wrap gap-4 mt-20 mb-20">
							<div v-for="language in languages" :key="language.locale" @click="selectLanguage(language)"
							     class="cursor-pointer p-4 border border-white bg-white w-[150px] h-[150px] flex flex-col items-center justify-center space-y-3 text-gray-800 transition ease-in-out delay-50 hover:scale-[1.06] hover:bg-primary hover:text-white rounded-xl p-6">
								<div>
									<!-- {{ language.flag }} -->
									<img width="60" :src="`https://flagicons.lipis.dev/flags/4x3/${getCountryCode(
          language.flag
        )}.svg`" class="rounded-[6px]"/>
								</div>

								<div>
									<p>{{ $t(language.name) }}</p>
								</div>
							</div>
						</div>
					</div>

					<!-- Step 3: Nível do idioma -->
					<div v-if="step === 3" class="language-selection">
						<h1 class="text-3xl text-gray-800 font-bold text-gray-600">
							{{
								$t('register.currentLevelQuestion', {
									language: $t(selectedLanguageName),
								})
							}}
						</h1>
						<div class="flex flex-col justify-center flex-wrap gap-4 mt-20 mb-20 items-center">
							<div v-for="(level, n) in languageLevel" :key="n" @click="selectLevel(level.code)"
							     class="bg-white cursor-pointer p-4 w-[300px] text-gray-800 transition ease-in-out delay-50 hover:scale-[1.06] hover:bg-primary hover:text-white rounded-xl">
								{{ $t(level.name) }}
							</div>
						</div>
					</div>

					<!-- Step 4: Informações pessoais -->

					<div v-if="step === 4" class="justify-center">
						<h1 class="text-3xl text-gray-800 font-bold text-gray-600 mb-10">
							{{ $t('register.enterYourDetails') }}
						</h1>
						<div class="text-left w-full bg-white rounded-lg p-10">
              <span class="text-sm text-gray-400 block mb-2">{{
		              $t('register.username')
	              }}</span>
							<input
								class="bg-white w-full border border-gray-300 p-4 text-gray-800 transition ease-in-out delay-50 hover:bg-white hover:text-gray-800 rounded-xl mb-4"
								type="text" v-model="username" placeholder=" " required/>

							<span class="text-sm text-gray-400 block mb-2">{{
									$t('register.email')
								}}</span>
							<input
								class="bg-white w-full border border-gray-300 p-4 text-gray-800 transition ease-in-out delay-50 hover:bg-white hover:text-gray-800 rounded-xl mb-4"
								type="email" v-model="email" placeholder=" " required/>

							<span class="text-sm text-gray-400 block mb-2">{{
									$t('register.password')
								}}</span>
							<input
								class="bg-white w-full border border-gray-300 p-4 text-gray-800 transition ease-in-out delay-50 hover:bg-white hover:text-gray-800 rounded-xl mb-4"
								type="password" v-model="password" placeholder=" " required/>

							<span class="text-sm text-gray-400 block mb-2">{{
									$t('register.confirmPassword')
								}}</span>
							<input
								class="bg-white w-full border border-gray-300 p-4 text-gray-800 transition ease-in-out delay-50 hover:bg-white hover:text-gray-800 rounded-xl mb-4"
								type="password" v-model="confirmPassword" placeholder=" " required/>

							<!-- <div class="error-message" v-if="error">{{ $t('register.validInfoError') }}</div> -->

							<button type="submit"
							        class="w-full rounded-xl p-4 font-bold text-white bg-primary rounded hover:bg-purple-700 focus:outline-none focus:shadow-outline">
								{{ $t('register.registerButton') }}
								<i v-if="loadingRegister"
								   class="fa-solid fa-wand-magic-sparkles fa-spin text-white ml-2"
								   style="font-size:25px"></i>
							</button>
						</div>

						<hr class="w-full mt-10 mb-4 text-gray-300"/>

						<div class="mt-4 text-center flex items-center justify-between">
							<p class="text-left font-medium text-gray-600 w-[200px]">
								{{ $t('register.alreadyHaveAccount') }}
							</p>
							<a href="/login"
							   class="w-[150px] items-right justify-right px-4 py-2 mt-2 font-medium text-gray-600 bg-white border border-gray-300 rounded-xl rounded hover:bg-primary hover:text-white focus:outline-none focus:shadow-outline">Entrar</a>
						</div>

						<Notification v-if="isNotificationVisible" :key="notificationKey" :msg="notificationMessage"
						              :type="notificationType"/>
					</div>
				</form>
			</div>
		</div>
		<div v-if="isLoading">
			<Loading/>
		</div>
	</div>
</template>


<script>
import axios from 'axios';
import mixin from './mixin';
import Notification from './ui/Notification.vue';
import Loading from '../components/ui/Loading.vue';

export default {
	mixins: [mixin],
	components: {
		Notification,
		Loading,
	},

	data() {
		return {
			loadingRegister: false,
			languages: [
				{ name: 'register.language.english', locale: 'en-US', flag: 'Inglês' },
				{
					name: 'register.language.spanish',
					locale: 'es-ES',
					flag: 'Espanhol',
				},
				{ name: 'register.language.french', locale: 'fr-FR', flag: 'Francês' },
				{
					name: 'register.language.italian',
					locale: 'it-IT',
					flag: 'Italiano',
				},
				{ name: 'register.language.german', locale: 'de-DE', flag: 'Alemão' },
				{
					name: 'register.language.portuguese',
					locale: 'pt-BR',
					flag: 'Português',
				},
			],
			languages_code: [
				{ name: 'register.language.english', locale: 'en-US', flag: 'Inglês' },
				{
					name: 'register.language.spanish',
					locale: 'es-ES',
					flag: 'Espanhol',
				},
				{ name: 'register.language.french', locale: 'fr-FR', flag: 'Francês' },
				{
					name: 'register.language.italian',
					locale: 'it-IT',
					flag: 'Italiano',
				},
				{ name: 'register.language.german', locale: 'de-DE', flag: 'Alemão' },
				{
					name: 'register.language.portuguese',
					locale: 'pt-BR',
					flag: 'Português',
				},
			],
			user_idiom: '',
			locale: navigator.language || navigator.userLanguage || 'en-US',
			step: 1,
			selectedLanguage: '',
			selectedLanguageName: '',
			selectedLevel: '',
			username: '',
			userIdiom: navigator.language || navigator.userLanguage || 'en-US',
			firstName: '',
			email: '',
			password: '',
			confirmPassword: '',
			error: false,
			isOnboarding: 1,
			isPaid: 0,
			countries: [
				{ name: 'Inglês', code: 'us' },
				{ name: 'Espanhol', code: 'es' },
				{ name: 'Francês', code: 'fr' },
				{ name: 'Italiano', code: 'it' },
				{ name: 'Alemão', code: 'de' },
				{ name: 'Russo', code: 'ru' },
				{ name: 'Português', code: 'br' },
				{ name: 'Holandês', code: 'nl' },
			],
			languageLevel: [
				{ name: 'register.languageLevels.knowSomeWords', code: '1' },
				{
					name: 'register.languageLevels.canHaveSimpleConversations',
					code: '2',
				},
				{
					name: 'register.languageLevels.comfortableHavingConversations',
					code: '3',
				},
				{ name: 'register.languageLevels.canExpressFluently', code: '4' },
				{ name: 'register.languageLevels.almostNative', code: '5' },
			],
			notificationMessage: '',
			notificationType: 'success',
			isNotificationVisible: false,
			notificationKey: 0,
			notificationTimeout: null,
			isLoading: false,
			translationsLoaded: false,
		};
	},
	methods: {
		translateLevel(levelKey) {
			return this.$t(levelKey);
		},

		nextStep() {
			this.step++;
		},

		selectLanguage(language) {
			this.selectedLanguage = language.flag;
			this.selectedLanguageName = language.name;
			this.selectedLanguageFlag = language.flag;
			this.nextStep();
			console.log(this.selectedLanguage);
		},

		selectLevel(level) {
			this.selectedLevel = level;
			this.nextStep();
			console.log(this.selectedLevel);
		},

		selectUserIdiom(languageCode) {
			this.user_idiom = languageCode.locale;
			this.nextStep();
			console.log(this.user_idiom);
		},

		registerUser() {

			this.loadingRegister = true;
			if (this.password !== this.confirmPassword) {
				this.showNotification('notifications.passwordsDoNotMatch', 'error');
				this.loadingRegister = false;

				return;
			}

			if (!this.password || this.confirmPassword.length < 6) {
				this.showNotification('notifications.passwordAtLeast6Caract', 'error');
				this.loadingRegister = false;

				return;
			}

			axios
				.post(`${ process.env.VUE_APP_API_URL }/register`, {
					username: this.username,
					email: this.email,
					password: this.password,
					language: this.selectedLanguage,
					user_idiom: this.user_idiom,
					level: this.selectedLevel,
					isOnboarding: this.isOnboarding,
					isPaid: this.isPaid,
					userIdiom: this.userIdiom,
				})
				.then((response) => {
					if (response.status === 200) {
						localStorage.setItem('username', this.username);
						localStorage.setItem('language', this.selectedLanguageFlag);
						localStorage.setItem('system_language', this.user_idiom);
						localStorage.setItem('level', this.languageLevel);
						localStorage.setItem('learningLanguage', this.selectedLanguageFlag);

						console.log('Registro com sucesso');
						this.loadingRegister = false;

						this.$router.push('/onboarding');
					}
				})
				.catch((error) => {
					if (error.response && error.response.status === 400) {
						// this.error = true;
						this.loadingRegister = false;

						this.showNotification(
							'notifications.tryAnotherEmailOrUsername',
							'error',
						);
					}
					console.error(error);
				});
		},

		getCountryCode(name) {
			const country = this.countries.find((country) => country.name === name);
			return country ? country.code : '';
		},

		getFlagUrl(name) {
			const code = this.getCountryCode(name);
			return `https://flagicons.lipis.dev/flags/4x3/${ code }.svg`;
		},
	},
	async created() {
		this.isLoading = true;
		// await this.loadTranslations();
		this.isLoading = false;
	},
};
</script>