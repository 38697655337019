import axios from 'axios';

export default {

// idiomas do sistema
	async loadTranslations(locale) {
		try {
			const response = await axios.get(
				`${process.env.VUE_APP_API_URL}/interfaceTranslations/${ locale }`,
			);

			if (!response.data || response.data.length === 0) {
				const responseEN = await axios.get(
					`${process.env.VUE_APP_API_URL}/interfaceTranslations/en-us`,
				);
				if (responseEN.data && responseEN.data.length > 0) {
					const translationsEN = JSON.parse(responseEN.data[0].translations);
					window.root.i18n.setLocaleMessage(locale, translationsEN);
				}
			} else {
				const translations = JSON.parse(response.data[0].translations);
				window.root.i18n.setLocaleMessage(locale, translations);
			}
			
			window.root.i18n.locale = locale;
		} catch (err) {
			console.error('Erro ao carregar traduções:', err);
			throw new Error(e);
		}
	},

};
