<template>
	<div class="bg-primary  flex flex-col min-h-screen h-auto">
		<div class="flex justify-center items-center p-[40px] rounded-lg w-5/5 mt-[5%] ">

			<!-- Bem vindo -->
			<div v-if="currentStep === 1" class="space-y-5 flex flex-col items-center justify-center text-center">
				<h1 class="text-3xl text-gray-800 font-bold text-white">
					<p>{{ username }}, </p>{{ $t('onboarding.welcomeMessage') }}
				</h1>
				<div>
					<Button :label="$t('onboarding.nextButton')" @click="nextStep()"
					        class="flex items-center rounded-xl p-4 text-white
                            transition ease-in-out delay-50 hover:scale-[1.06] 
                            hover:cursor-pointer hover:transform hover:rotate-360 bg-[#33B679] items-right mb-10 mt-10 w-[250px] mt-[10px]"/>
				</div>
				<input type="hidden" id="userId" value="{{ userId }}">
			</div>

			<!-- Vamos criar! -->
			<div v-if="currentStep === 2" class="space-y-5 flex flex-col items-center justify-center text-center">
				<h1 class="text-3xl text-gray-800 font-bold text-white">{{ $t('onboarding.letsCreate') }}</h1>
				<div>
					<Button :label="$t('onboarding.nextButton')" @click="nextStep()"
					        class="flex items-center rounded-xl p-4 text-white
                            transition ease-in-out delay-50 hover:scale-[1.06] 
                            hover:cursor-pointer hover:transform hover:rotate-360 bg-[#33B679] items-right mb-10 mt-10 w-[250px] mt-[10px]"/>
				</div>
			</div>


			<!-- Selecione o assunto -->
			<div v-if="currentStep === 3" class="space-y-5 flex flex-col items-center justify-center text-center">
				<h1 class="text-3xl text-gray-800 font-bold text-white">{{ $t('onboarding.selectSubject') }}</h1>
				<!-- <p class="text-white text-xl">{{ $t("onboarding.writeAbout") }}</p> -->
				<!-- <p class="text-white text-xl">{{ $t("onboarding.exampleSubject") }}</p> -->
				<div class="mb-20"></div>


				<div class="flex flex-col space-y-7">

					<div>
						<h3 class="text-white  mb-2">{{ $t('createContent.contentType') }}</h3>
						<select v-model="contentType"
						        class="w-[300px] p-4 rounded-lg mb-3 font-medium text-lg text-gray-600">
							<option value="Conversa">
								{{ $t('createContent.conversation') }}
							</option>
							<option value="História">
								{{ $t('createContent.history') }}
							</option>
						</select>
					</div>

					<div>
						<h3 class="text-white mb-2">{{ $t('createContent.levelOfFormality') }}</h3>
						<select v-model="levelOfFormality"
						        class="w-[300px] p-4 rounded-lg mb-3 font-medium text-lg text-gray-600">
							<option value="Muito informal">
								{{ $t('createContent.veryInformal') }}
							</option>
							<option value="Dia a dia">
								{{ $t('createContent.normal') }}
							</option>
							<option value="Formal">
								{{ $t('createContent.formal') }}
							</option>
						</select>
					</div>


					<div>
						<h3 class="text-white mb-2">
							<p>{{ $t('onboarding.writeAbout') }}</p>
							<p>{{ $t("createContent.exampleSubject") }}</p>
						</h3>
						<InputText maxlength="100" type="text" v-model="subject"
						           class="w-[300px] p-4 rounded-lg mb-3 font-medium text-lg text-gray-600"/>
					</div>
				</div>

				<div class="error-message" v-if="errorStep3">{{ $t('onboarding.enterValidSubject') }}</div>
				<div>
					<Button :label="$t('onboarding.nextButton')" @click="saveSubject(subject)"
					        class="flex items-center rounded-xl p-4 text-white
                            transition ease-in-out delay-50 hover:scale-[1.06] 
                            hover:cursor-pointer hover:transform hover:rotate-360 bg-[#33B679] items-right mb-10 mt-10 w-[250px] mt-[10px]"/>
				</div>
			</div>


			<!-- Selecione a-->

			<!--
			<div v-if="currentStep === 4"  class="space-y-5 flex flex-col items-center justify-center text-center">
				<h1 class="text-3xl text-gray-800 font-bold text-white">
					{{ onboardingMessages[1] }}
				</h1>
				<div><Button label="Próximo" @click="nextStep()" class="w-[250px] mt-[10px]"/></div>
			</div> -->


			<div v-if="currentStep === 4" class="space-y-5 flex flex-col items-center justify-center text-center">
				<h1 class="text-3xl text-gray-800 font-bold text-white">
					{{ $t('onboarding.tipsBeforeStarting') }}
				</h1>
				<img src="https://learning-language.s3.us-east-2.amazonaws.com/imgs/ui/onboarding/ideia_onboarding.png"
				     class="w-[200px]  rounded-full"/>

				<div>
					<Button :label="$t('onboarding.nextButton')" @click="nextStep()"
					        class="flex items-center rounded-xl p-4 text-white
                            transition ease-in-out delay-50 hover:scale-[1.06] 
                            hover:cursor-pointer hover:transform hover:rotate-360 bg-[#33B679] items-right mb-10 mt-10 w-[250px] mt-[10px]"/>
				</div>
			</div>

			<div v-if="currentStep === 5" class="space-y-5 flex flex-col items-center justify-center text-center">
				<h1 class="text-3xl text-gray-800 font-bold text-white">
					{{ $t('onboarding.useHeadphones') }}

				</h1>
				<img src="https://learning-language.s3.us-east-2.amazonaws.com/imgs/ui/onboarding/use_headset.png"
				     class="w-[200px] rounded-full"/>
				<div>
					<Button :label="$t('onboarding.nextButton')" @click="nextStep()"
					        class="flex items-center rounded-xl p-4 text-white
                            transition ease-in-out delay-50 hover:scale-[1.06] 
                            hover:cursor-pointer hover:transform hover:rotate-360 bg-[#33B679] items-right mb-10 mt-10 w-[250px] mt-[10px]"/>
				</div>
			</div>

			<div v-if="currentStep === 6" class="space-y-5 flex flex-col items-center justify-center text-center">
				<h1 class="text-3xl text-gray-800 font-bold text-white">
					{{ $t('onboarding.listenAndRead') }}

				</h1>
				<img src="https://learning-language.s3.us-east-2.amazonaws.com/imgs/ui/onboarding/escutar_ler.png"
				     class="w-[200px] border border-4 border-primary rounded-full"/>
				<div>
					<Button :label="$t('onboarding.nextButton')" @click="nextStep()"
					        class="flex items-center rounded-xl p-4 text-white
                            transition ease-in-out delay-50 hover:scale-[1.06] 
                            hover:cursor-pointer hover:transform hover:rotate-360 bg-[#33B679] items-right mb-10 mt-10 w-[250px] mt-[10px]"/>
				</div>
			</div>

			<div v-if="currentStep === 7" class="space-y-5 flex flex-col items-center justify-center text-center">
				<h1 class="text-3xl text-gray-800 font-bold text-white">
					{{ $t('onboarding.listenRepeatedly') }}

				</h1>
				<img src="https://learning-language.s3.us-east-2.amazonaws.com/imgs/ui/onboarding/listenRepeatedly.png"
				     class="w-[200px] border border-4 border-primary rounded-full"/>
				<div>
					<Button :label="$t('onboarding.nextButton')" @click="nextStep()"
					        class="flex items-center rounded-xl p-4 text-white
                            transition ease-in-out delay-50 hover:scale-[1.06] 
                            hover:cursor-pointer hover:transform hover:rotate-360 bg-[#33B679] items-right mb-10 mt-10 w-[250px] mt-[10px]"/>
				</div>
			</div>

			<div v-if="currentStep === 8" class="space-y-5 flex flex-col items-center justify-center text-center">
				<h1 class="text-3xl text-gray-800 font-bold text-white">
					{{ $t('onboarding.followStudySchedule') }}
				</h1>
				<img src="https://learning-language.s3.us-east-2.amazonaws.com/imgs/ui/onboarding/calendario.png"
				     class="w-[200px]"/>

				<div>
					<Button :label="$t('onboarding.createButton')" @click="submitForm()"
					        class="flex items-center rounded-xl p-4 text-white
                            transition ease-in-out delay-50 hover:scale-[1.06] 
                            hover:cursor-pointer hover:transform hover:rotate-360 bg-[#33B679] items-right mb-10 mt-10 w-[250px] mt-[10px]"/>
				</div>
			</div>

			<div v-if="currentStep === 10" class="space-y-5 flex flex-col items-center justify-center text-center">
				<h1 class="leading-tight text-3xl text-gray-800 font-bold text-white mb-5">{{
						$t('createContent.creatingMaterial')
					}}</h1>

				<p class="text-xl text-gray-800 font-bold text-white mb-[70px]">{{
						$t('createContent.dontForgetHeadphones')
					}}</p>
				<img src="https://learning-language.s3.us-east-2.amazonaws.com/imgs/ui/onboarding/use_headset.png"
				     class="w-[200px] rounded-full"/>

				<i class="fa-solid fa-wand-magic-sparkles fa-spin text-white mt-[30px] " style="font-size:40px"></i>
			</div>

		</div>
		<div v-if="isLoading">
			<Loading/>
		</div>
	</div>
</template>


<script>

import mixin from '../components/mixin';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Slider from 'primevue/slider';
import InputText from 'primevue/inputtext';
import axios from 'axios';
import Loading from '../components/ui/Loading.vue';


export default {
	mixins: [mixin],
	components: {
		Button, Dropdown, Slider, InputText, Loading,
	},


	data() {
		return {
			page: 'onboarding',
			translationsLoaded: false,
			countries: [

				{ name: 'Inglês', code: 'us' },
				{ name: 'Espanhol', code: 'es' },
				{ name: 'Francês', code: 'fr' },
				{ name: 'Italiano', code: 'it' },
				{ name: 'Alemão', code: 'de' },
				{ name: 'Russo', code: 'ru' },
				{ name: 'Português', code: 'br' },
				{ name: 'Holandês', code: 'nl' },
			],

			englishLanguageNames: {
				'Inglês': 'english',
				'Espanhol': 'spanish',
				'Francês': 'french',
				'Italiano': 'italian',
				'Alemão': 'german',
				'Russo': 'russian',
				'Português': 'portuguese',
				'Holandês': 'dutch',
			},


			language: '',
			level: '',

			levels: [
				{ name: 'Básico' },
				{ name: 'Intermediário' },
				{ name: 'Avançado' },
			],
			onboardingMessages: [],

			currentStep: 1,
			contentList: [],
			subject: '',
			errorStep1: false,
			errorStep2: false,
			errorStep3: false,
			userId: '',
			step: 1,
			username: '',
			userIdiom: '',
			language: '',
			level: '',
			isOnboarding: '',
			isPaid: '',
			locale: '',
			isLoading: false,
		};
	},


	async mounted() {
		this.isLoading = true;

		const username = localStorage.getItem('username');
		const language = localStorage.getItem('language');
		const level = localStorage.getItem('level');

		try {
			const response = await axios.get(`${ process.env.VUE_APP_API_URL }/user/u/${ username }`);

			this.contentList = [response.data];
			//   console.log(`Copntent List`,   this.contentList)

			this.userId = this.contentList[0][0]?.user_id;
			this.userIdiom = this.contentList[0][0]?.user_idiom;
			this.isOnboarding = this.contentList[0][0]?.is_onboarding;
			this.level = this.contentList[0][0]?.language_level;
			this.language = this.contentList[0][0]?.learning_language;
			this.username = this.contentList[0][0]?.username;
			this.isPaid = this.contentList[0][0]?.is_paid;
			this.locale = this.contentList[0][0]?.user_idiom;

			// await this.loadTranslations();
			this.isLoading = false;

		} catch (error) {
			console.error('Houve um erro ao buscar os dados:', error);
		}
	},

	methods: {
		getCountryCode(name) {
			const country = this.countries.find(country => country.name === name);
			return country ? country.code : '';
		},
		getFlagUrl(name) {
			const code = this.getCountryCode(name);
			return `https://flagicons.lipis.dev/flags/4x3/${ code }.svg`;
		},
		nextStep() {
			this.currentStep++;
			console.log(this.currentStep);
		},
		saveSubject(subject) {
			this.subject = subject;
			this.currentStep++;
			console.log(this.currentStep);
		},

		updateOnboardingMessages() {
			this.onboardingMessages = [
				'Então vamos lá! Vamos criar o seu primeiro material de estudos juntos!',
				`Estamos criando com nosso robozinho poliglota o material mais legal de ${ this.subject } para você aprender ${ this.language }!`,
				'Ele foi criado para você aprender de um jeito natural e eficiente!',
				'Antes de começar, eu vou te dar algumas dicas para você aproveitar ao máximo',
				'Se possível, use fones de ouvido! Assim você terá mais foco.',
				'Escute e leia ao mesmo tempo! Dessa maneira, você vai associar os sons às palavras, e vice-versa.',
				'Escute várias e várias vezes, onde você estiver! A repetição é uma das chaves para a gente memorizar mais rápido.',
				'Siga o calendário de estudos Você terá nas mãos os dias que você precisa fazer as revisões - e grudar o material na sua cabeça!',
			];
		},
	},
	watch: {
		showTranslation(newValue) {
			if (newValue && !this.translationRequested) {
				this.requestTranslation();
			}
		},
		subject(newSubject) {
			this.updateOnboardingMessages(); // Atualiza as mensagens quando 'subject' muda
		},
	},

	computed: {
		translatedLanguage() {
			if (this.language) {
				return this.$t('register.language.' + ((this.englishLanguageNames[this.language])));
			}
			return '';
		},
	},


};
</script>

<style scoped>
.large-stars .p-rating-star {
	font-size: 2em;
	/* Ajuste o tamanho conforme necessário */
}
</style>
