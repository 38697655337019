import i18nDao from '@/daos/i18n.dao';

export default async (to, from, next) => {
	let locale = localStorage.getItem('userIdiom');
	// alert(locale)
	if (!locale) {
		locale = ((navigator && navigator.language) || 'en-US');
		localStorage.setItem('locale', locale);
		localStorage.setItem('userIdiom', locale);
	}

	try {
		await i18nDao.loadTranslations(locale);
		next();
	} catch (e) {
		console.error(e);
		if (to.path === '/error') next();
		else next('/error');
	}
};
