<template>
  <div>
    <button @click="startRecording">Start Recording</button>
    <button @click="stopRecording" :disabled="!isRecording">Stop Recording</button>
    <audio v-if="audioUrl" controls :src="audioUrl"></audio>
  </div>
</template>

<script>
import RecordRTC from 'recordrtc';

export default {
  data() {
    return {
      recorder: null,
      isRecording: false,
      audioUrl: '',
    };
  },
  methods: {
    async startRecording() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.recorder = new RecordRTC(stream, {
          type: 'audio',
          mimeType: 'audio/wav', // Este MIME type ajuda na compatibilidade entre navegadores
          recorderType: RecordRTC.StereoAudioRecorder, // Específico para garantir suporte no Safari
          // Outras opções podem ser configuradas conforme necessário
        });

        this.recorder.startRecording();
        this.isRecording = true;
      } catch (error) {
        console.error('Falha ao iniciar a gravação: ', error);
        alert('Falha ao iniciar a gravação: ' + error.message);
      }
    },
    async stopRecording() {
      console.log("Tentando parar a gravação...");
      if (!this.recorder) {
        console.log("Nenhum gravador encontrado.");
        return;
      }

      this.recorder.stopRecording(() => {
        console.log("Gravação parada.");
        let blob = this.recorder.getBlob();
        this.audioUrl = URL.createObjectURL(blob);

        // Tentativa de liberar a stream capturada
        try {
          this.recorder.stream.getTracks().forEach(track => {
            console.log("Parando a faixa:", track);
            track.stop();
          });
        } catch (error) {
          console.error('Erro ao tentar parar as faixas da stream', error);
        }

        this.recorder = null;
        this.isRecording = false;
      });
    },




  },
};
</script>
