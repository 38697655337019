<template>
  <div class="flex flex-col md:flex-row items-right justify-end mt-10">
    <div class="cursor-pointer">
      <!-- <span class="w-[120px]">Idioma que estou aprendendo:</span> -->

      <div class="flex items-center relative">
        <div @click="toggleDropdown" class="flex items-center space-x-3 font-medium">
          <img :src="getFlagUrl(learningLanguage)" width="50" class="rounded-[8px]" />
          <span>{{ $t(learningLanguageName) }}</span>
        </div>

        <div v-if="showDropdown" class="dropdown-menu p-2 space-y-1 mt-5">
          <div v-for="(name, code) in languageMap" :key="code" @click="selectLanguage(code, name)"
            class="dropdown-item space-x-5 p-4 rounded-lg text-gray-500 font-medium border rounded-xl p-3 border-[#FFF] bg-[#FFF] transition ease-in-out duration-200 hover:bg-[#F1DEFF] hover:text-gray-800">
            <img :src="getFlagUrl(name)" width="30" class="rounded-[5px]" />
            <span>{{ $t(languageMapTranslated[code]) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="isLoading">
    <Loading />
  </div>
</template>



<script>
import axios from "axios";
import mixin from "./mixin";
import Loading from "../components/ui/Loading.vue";

export default {
  mixins: [mixin],

  components: {
    Loading,
  },

  data() {
    return {
      username: "",
      userEmail: "",
      user_id: "",
      isPaid: 0,
      inTrial: true,
      learningLanguage: "",
      userIdiom: "",
      languageName: "",
      languageMap: {
        "en-US": "Inglês",
        "es-ES": "Espanhol",
        "fr-FR": "Francês",
        "it-IT": "Italiano",
        "de-DE": "Alemão",
        "ru-RU": "Russo",
        "pt-BR": "Português",
        "nl-NL": "Holandês",
      },
      languageMapTranslated: {
        "en-US": "register.language.english",
        "es-ES": "register.language.spanish",
        "fr-FR": "register.language.french",
        "it-IT": "register.language.italian",
        "de-DE": "register.language.german",
        "ru-RU": "register.language.russian",
        "pt-BR": "register.language.portuguese",
        "nl-NL": "register.language.dutch",
      },
      countries: [
        { name: "Inglês", code: "us" },
        { name: "Espanhol", code: "es" },
        { name: "Francês", code: "fr" },
        { name: "Italiano", code: "it" },
        { name: "Alemão", code: "de" },
        { name: "Russo", code: "ru" },
        { name: "Português", code: "br" },
        { name: "Holandês", code: "nl" },
      ],
      learningLanguageCode: "",
      showDropdown: false,
      revisionsTomorrow: [],
      isLoading: false,
    };
  },

  async mounted() {
    const username = localStorage.getItem("username");
    const user_id = localStorage.getItem("userId");

    this.username = username;
    this.user_id = user_id;

    const responseB = await axios.get(
      `${process.env.VUE_APP_API_URL}/user/u/${username}`
    );
    var list = [responseB.data];
    this.isPaid = list[0][0]?.is_paid;
    this.learningLanguage = list[0][0]?.learning_language;
    console.log("Bob this.learningLanguage", this.learningLanguage);
    this.userIdiom = list[0][0]?.user_idiom;
    this.userEmail = list[0][0]?.email;

    console.log(this.userEmail);

    const languageName = list[0][0]?.learning_language; // Nome completo do idioma
    this.learningLanguageCode = this.getCodeFromLanguageName(languageName);
    // this.loadTranslations();
  },

  computed: {
    languageName() {
      return this.getLanguageName(this.userIdiom);
    },
    learningLanguageName() {
      const translationKey =
        this.languageMapTranslated[this.learningLanguageCode];
      console.log("translationKey:", translationKey); // Diagnóstico
      if (translationKey) {
        return this.$t(translationKey);
      }
      return "Selecionar Idioma";
    },
  },

  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },

    selectLanguage(code, name) {
      this.learningLanguageCode = code;
      this.learningLanguage = this.languageMap[code];
      this.showDropdown = false;

      this.updateLearningLanguage();
    },

    getCountryCode(name) {
      const country = this.countries.find((country) => country.name === name);
      return country ? country.code : "";
    },

    getFlagUrl(name) {
      const code = this.getCountryCode(name);
      return `https://flagicons.lipis.dev/flags/4x3/${code}.svg`;
    },

    getCodeFromLanguageName(name) {
      return Object.keys(this.languageMap).find(
        (key) => this.languageMap[key] === name
      );
    },

    getLanguageName(isoCode) {
      const languageMap = {
        "en-US": "Inglês",
        "es-ES": "Espanhol",
        "fr-FR": "Francês",
        "it-IT": "Italiano",
        "de-DE": "Alemão",
        "ru-RU": "Russo",
        "pt-PT": "Português",
        "pt-BR": "Português",
        "nl-NL": "Holandês",
      };
      return languageMap[isoCode] || "Código de idioma desconhecido";
    },

    async updateLearningLanguage() {
      const userSettings = {
        username: this.username,
        learningLanguage: this.languageMap[this.learningLanguageCode], // Nome completo do idioma
      };

      try {
        // this.isLoading = true;

        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/user/update-learning-language/${this.username}`,
          userSettings
        );

        if (response.status === 200) {
          localStorage.setItem(
            "learningLanguage",
            this.languageMap[this.learningLanguageCode]
          );
          localStorage.setItem(
            "language",
            this.languageMap[this.learningLanguageCode]
          );

          this.isLoading = true;

          // this.isLoading = false;

          this.reloadPage(); // Nova função para buscar dados atualizados
        } else {
          throw new Error("Falha ao salvar as configurações");
        }
      } catch (error) {
        console.error(error);
        // Tratar erro
      }
    },

    reloadPage() {
      window.location.reload();
    },
  },

  watch: {
    userIdiom(newVal, oldVal) {
      if (newVal !== oldVal) {
        // this.updateUserLanguage();
      }
    },
  },
};
</script>


<style>
.relative {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  /* Isso coloca o topo do dropdown logo abaixo do contêiner pai */
  left: -35px;
  /* Alinha à esquerda do contêiner pai */
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 22221;
  width: 180px;
  border-radius: 12px;
  margin-top: 5px;
}

.dropdown-item {
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}
</style>