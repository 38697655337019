<template>
  <!-- Menu Móvel no Topo -->
  <div
    class="lg:hidden fixed top-0 left-0 right-0 mb-10 p-10 bg-primary flex justify-around items-center text-white text-medium cursor-pointer h-[70px] z-40">
    <i @click="navigateToDashboard()" :class="{
      'bg-primary p-2': currentPage !== 'dashboard',
      'bg-white text-primary p-2 rounded-lg	': currentPage === 'dashboard',
    }" class="pi pi-home text-xl" style="font-size: 30px"></i>
    <i @click="navigateToContentList()" :class="{
      'bg-primary p-2': currentPage !== 'content-list',
      'bg-white text-primary p-2 rounded-lg	': currentPage === 'content-list',
    }" class="pi pi-book text-xl" style="font-size: 30px"></i>
    <i @click="navigateToCalendar()" :class="{
      'bg-primary p-2': currentPage !== 'calendar',
      'bg-white text-primary p-2 rounded-lg	': currentPage === 'calendar',
    }" class="pi pi-calendar-plus text-xl" style="font-size: 30px"></i>
    <i @click="navigateToSpeak()" :class="{
      'bg-primary p-2': currentPage !== 'account',
      'bg-white text-primary p-2 rounded-lg	': currentPage === 'speak',
    }" class="fa-solid fa-microphone-lines" style="font-size: 30px"></i>
    <i @click="navigateToAccount()" :class="{
      'bg-primary p-2': currentPage !== 'account',
      'bg-white text-primary p-2 rounded-lg	': currentPage === 'account',
    }" class="pi pi-cog mr-2" style="font-size: 30px"></i>
  </div>

  <aside :class="sidebarClasses" :style="sidebarStyle"
    class="fixed max-w-[300px] min-w-[300px] bg-white h-full flex flex-col justify-between transition-transform duration-300 ease-in-out z-40">
    <div>
      <img src="https://framerusercontent.com/images/ROWCGCoRs148xJnbvIq4SgM2oM.png" alt="Logo"
        class="mt-[50px] ml-10 w-[150px]" />
      <div class="mt-20 ml-10 flex items-center text-gray-500 text-medium">
        <p v-if="username">{{ $t("sidebar.welcome") }} {{ username }}</p>
      </div>
      <ul class="text-xl space-y-5 ml-10 font-medium mt-20 items-center justify-center">
        <li @click="navigateToDashboard()" :class="{
      'text-gray-700 border rounded-xl p-3 w-[83%] border-[#F1DEFF] bg-[#F1DEFF]':
        currentPage === 'dashboard',
      'text-gray-300  border rounded-xl p-3 w-[83%] border-[#FFF] bg-[#FFF] transition ease-in-out duration-200 hover:bg-[#F1DEFF40] hover:text-gray-500 ':
        currentPage !== 'dashboard',
    }" class="mb-2 cursor-pointer">
          <i class="pi pi-home mr-2" style="font-size: 24px"></i>
          {{ $t("sidebar.home") }}
        </li>
        <li @click="navigateToContentList()" :class="{
      'text-gray-700 border rounded-xl p-3 w-[83%] border-[#F1DEFF] bg-[#F1DEFF]':
        currentPage === 'content-list',
      'text-gray-300  border rounded-xl p-3 w-[83%] border-[#FFF] bg-[#FFF] transition ease-in-out duration-200 hover:bg-[#F1DEFF40] hover:text-gray-500 ':
        currentPage !== 'content-list',
    }" class="mb-2 cursor-pointer">
          <i class="pi pi-book mr-2" style="font-size: 24px"></i>
          {{ $t("sidebar.library") }}
        </li>
        <li @click="navigateToCalendar()" :class="{
      'text-gray-700 border rounded-xl p-3 w-[83%] border-[#F1DEFF] bg-[#F1DEFF]':
        currentPage === 'calendar',
      'text-gray-300  border rounded-xl p-3 w-[83%] border-[#FFF] bg-[#FFF] transition ease-in-out duration-200 hover:bg-[#F1DEFF40] hover:text-gray-500 ':
        currentPage !== 'calendar',
    }" class="mb-2 cursor-pointer">
          <i class="pi pi-calendar-plus mr-2" style="font-size: 24px"></i>
          {{ $t("sidebar.memorization") }}
        </li>
        <li @click="navigateToSpeak()" :class="{
      'text-gray-700 border rounded-xl p-3 w-[83%] border-[#F1DEFF] bg-[#F1DEFF]':
        currentPage === 'speak',
      'text-gray-300  border rounded-xl p-3 w-[83%] border-[#FFF] bg-[#FFF] transition ease-in-out duration-200 hover:bg-[#F1DEFF40] hover:text-gray-500 ':
        currentPage !== 'speak',
    }" class="mb-2 cursor-pointer">
          <!-- <i class="fa-solid fa-microphone-lines mr-2"></i> {{ $t("sidebar.speaking") }} -->
          <i class="fa-solid fa-microphone-lines mr-2" style="font-size: 24px"></i>
          Speaking <span style="font-size: 11px">beta</span>
        </li>
      </ul>
    </div>
    <div class="">
      <ul class="text-xl space-y-10 ml-10 font-medium pt-10 pb-10">
        <!-- <li class="mb-2 cursor-pointer text-gray-300 hover:text-primary"><i class="pi pi-bell mr-2"></i> {{ $t("sidebar.notifications") }}</li> -->
        <li @click="navigateToAccount()" :class="{
      'text-gray-700 border rounded-xl p-3 w-[83%] border-[#F1DEFF] bg-[#F1DEFF]':
        currentPage === 'account',
      'text-gray-300  border rounded-xl p-3 w-[83%] border-[#FFF] bg-[#FFF] transition ease-in-out duration-500 hover:bg-[#F1DEFF40] hover:text-gray-500 ':
        currentPage !== 'account',
    }" class="mb-2 cursor-pointer">
          <i class="pi pi-cog mr-2" style="font-size: 24px"></i>
          {{ $t("sidebar.settings") }}
        </li>
        <!-- <li class="mb-2 cursor-pointer text-gray-300 hover:text-primary"><i class="pi pi-pencil mr-2"></i> {{ $t("sidebar.myProfile") }}</li> -->
      </ul>
    </div>
  </aside>

  <!-- <button @click="toggleSidebar" class="lg:hidden p-4 bg-blue-500 text-white z-60 fixed right-0 top-60">☰</button> -->
</template>

<script>
import mixin from "./mixin";

export default {
  mixins: [mixin],

  props: ["contentList", "username"],

  data() {
    return {
      sidebarStyle: "",
      contentList: [],
      // showSidebar: window.innerWidth >= 1024, // lg breakpoint do Tailwind
      showSidebar: window.innerWidth >= 1024, // lg breakpoint do Tailwind
      // username: '',
      currentPage: "",
    };
  },

  async mounted() {
    // console.log( "OI ")
    // console.log("Log: ", this.$route.path.includes('account'))

    this.setCurrentPageBasedOnRoute();

    window.addEventListener("resize", () => {
      this.showSidebar = window.innerWidth >= 1024;
    });

    try {
      const username = localStorage.getItem("username");
      this.username = username;

      const responseB = await axios.get(
        `${process.env.VUE_APP_API_URL}/user/u/${username}`
      );
      var list = [responseB.data];
      this.locale = list[0][0]?.user_idiom;

      // this.loadTranslations();
      // this.setCurrentPageBasedOnRoute();
    } catch (error) {
      console.error("Houve um erro ao buscar os dados:", error);
    }
  },

  computed: {
    sidebarClasses() {
      return {
        "fixed top-0 bottom-0": window.innerWidth < 1024,
        "translate-x-0": this.showSidebar,
        "-translate-x-full": !this.showSidebar && window.innerWidth < 1024,
        "w-4/5": window.innerWidth < 1024,
        relative: window.innerWidth >= 1024,
        "w-full": window.innerWidth >= 1024,
      };
    },
    // Outros computados
  },
  methods: {
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    setCurrentPageBasedOnRoute() {
      // Supondo que você tenha acesso ao objeto $route
      // Você precisará ajustar este código conforme a sua lógica de rotas
      if (this.$route.path.includes("dashboard")) {
        this.currentPage = "dashboard";
      } else if (this.$route.path.includes("content-list")) {
        this.currentPage = "content-list";
      } else if (this.$route.path.includes("calendar")) {
        this.currentPage = "calendar";
      } else if (this.$route.path.includes("account")) {
        this.currentPage = "account";
      }
    },
    // Outros métodos
  },

  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.showSidebar = window.innerWidth >= 1024;
    });
  },
};
</script>