<template>
  <div
    v-if="contentList.length > 0"
    class="bg-[#F4F0F8] flex flex-col h-screen overflow-y-auto"
  >
    <div class="w-4/5 mx-auto">
      <!-- Back button -->
      <div class="relative cursor-pointer" @click="navigateToDashboard()">
        <i
          class="pi pi-arrow-left absolute left-5 top-5 hover:scale-[120%] transition ease-in-out delay-50 block"
          style="font-size: 1.5rem"
        ></i>
      </div>

      <!-- Div do Título e do Switch -->
      <div
        class="justify-between items-center p-[40px] rounded-lg w-3/5 ml-8 mt-5"
      >
        <div class="flex mb-10">
          <img
            v-if="language"
            :alt="language"
            :src="getFlagUrl(language)"
            class="w-[60px] rounded-lg mr-4"
            :class="`mr-2 flag flag-${getCountryCode(language)}`"
          />
          <h1 class="text-3xl text-gray-800 font-bold mt-1">{{ subject }}</h1>
        </div>
      </div>

      <!-- Área Principal e Área à Direita -->
      <div class="flex flex-row justify-between mx-auto mb-[200px]">
        <!-- DIV PRINCIPAL DE CONTEÚDO -->
        <div class="flex flex-wrap">
          <!-- OPÇÕES DO CONTEÚDO-->
          <ContentMenu />

          <!-- Área Principal -->
          <div class="ml-8 mr-8 p-[50px] w-full bg-white rounded-lg">
            <div class="space-y-9 justify-right">
              <div class="justify-right flex">
                <InputSwitch v-model="showTranslation" />
                <p class="ml-2">Ativar tradução</p>
              </div>

              <div v-for="(sentence, index) in originalSentences" :key="index">
                <p
                  class="font-medium text-primary text-[1.4rem] cursor-pointer"
                  @click="wordClicked($event)"
                  :key="'original-' + index"
                >
                  {{ sentence }}.
                </p>
                <p
                  v-if="showTranslation && translatedSentences[index]"
                  class="text-gray-400 mt-2 font-normal text-[1.4rem]"
                >
                  {{ translatedSentences[index] }}.
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Área à Direita -->
        <!-- <div class="hidden lg:flex flex-wrap w-2/5 mt-5"> 
    

          <ul class="space-y-6"> 
            <li class=" text-gray-400 mb-10 "><i class="pi pi-calendar"></i> Criado em {{ dia }}/{{ mes }}/{{ ano }}</li>
            <li class="cursor-pointer text-gray-400 hover:background-primary"><i class="pi pi-share-alt"></i> Compartilhar</li>
            <li class="cursor-pointer text-gray-400 hover:text-primary"><router-link :to="{ name: 'ChatView', params: { content_uuid: contentUUID }}"><i class="pi pi-download"></i> Baixar áudio</router-link></li>
            <li class="cursor-pointer text-gray-400 hover:text-primary"><i class="pi pi-print"></i> Imprimir</li>
            <li class="cursor-pointer text-gray-400 hover:text-primary"><i class="pi pi-trash"></i> Apagar</li>
          </ul>

        </div> -->

        <!-- Ícone de Hambúrguer -->
        <div
          class="lg:hidden fixed top-30 right-0 bg-gray-100 z-50 flex flex-col items-start ml-4 rounded-l-lg"
        >
          <!-- <button @click="toggleSidebar" class="mt-0 p-8 focus:outline-none pi pi-bars"></button> -->

          <!-- Menu que aparece ao clicar no ícone de hambúrguer -->
          <!-- <div v-if="isSidebarOpen" class="mt-0 p-8 ">
            <ul class="space-y-9">
              <li class="mb-2 cursor-pointer text-gray-400 hover:background-primary"><i class="pi pi-share-alt"></i> Compartilhar</li>
              <li class="mb-2 cursor-pointer text-gray-400 hover:text-primary">
              <router-link :to="{ name: 'ChatView', params: { content_uuid: contentUUID }}"><i class="pi pi-download"></i> Baixar áudio</router-link>
              </li>
              <li class="mb-2 cursor-pointer text-gray-400 hover:text-primary"><i class="pi pi-print"></i> Imprimir</li>
              <li class="mb-2 cursor-pointer text-gray-400 hover:text-primary"><i class="pi pi-trash"></i> Apagar</li>
            </ul>
          </div> -->
        </div>
      </div>

      <!-- Seu player de áudio -->
      <div
        id="audioPlayer"
        class="fixed bottom-0 left-0 right-0 flex flex-col justify-center items-center p-8"
        style="background-color: #2d054b"
      >
        <audio ref="audio">
          <source
            v-if="contentList.length > 0 && contentList[0].audio_path"
            :src="`https://learning-language.s3.us-east-2.amazonaws.com/${contentList[0].audio_path}`"
            type="audio/mpeg"
          />
        </audio>

        <!-- Botões de Controle -->
        <div class="flex space-x-4 mb-4">
          <button
            @click="goBackward"
            class="mt-1 block w-[50px] h-[50px] bg-transparent outline border-3 border-solid border-white rounded-full text-white justify-center content-center transform motion-safe:hover:scale-110"
          >
            <i class="pi pi-step-backward-alt" style="font-size: 25px"></i>
          </button>
          <button
            @click="playPauseAudio"
            class="block w-[60px] h-[60px] bg-transparent outline border-3 border-solid border-white rounded-full text-white justify-center content-center transform motion-safe:hover:scale-110"
          >
            <i
              :class="isPlaying ? 'pi pi-pause' : 'pi pi-play'"
              style="font-size: 30px; padding: 5px 0 0 2px"
            ></i>
          </button>
          <button
            @click="goForward"
            class="mt-1 block w-[50px] h-[50px] bg-transparent outline border-3 border-solid border-white rounded-full text-white justify-center content-center transform motion-safe:hover:scale-110"
          >
            <i class="pi pi-step-forward-alt" style="font-size: 25px"></i>
          </button>
        </div>

        <div class="flex items-center w-full">
          <span id="currentTime" class="w-1/12 text-white">0:00</span>
          <div
            id="progressBar"
            class="relative w-full h-1 bg-white mr-4 cursor-pointer"
          >
            <div
              id="progress"
              class="absolute left-0 h-1 bg-purple-200"
              style="background-color: #9200ff"
            ></div>
          </div>
          <span id="totalTime" class="w-1/12 text-white right-0">0:00</span>
        </div>
      </div>

      <!-- Sua caixa de tradução -->
      <div class="translationBox" ref="translationBox">
        <strong>{{ selectedWord }}</strong>
        <p>{{ wordTranslation }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import mixin from "./mixin";
import InputSwitch from "primevue/inputswitch";
import ContentMenu from "./ContentMenu.vue";

export default {
  mixins: [mixin],

  props: ["language", "countries", "subject", "original_sentences", "level"],

  components: {
    InputSwitch,
    ContentMenu,
  },

  data() {
    return {
      contentList: [],
      currentStep: 1,
      language: "Francês",
      level: "",
      subject: "",
      errorStep1: false,
      errorStep2: false,
      errorStep3: false,
      selectedWord: "",
      wordTranslation: "",
      showTranslation: "",
      originalSentences: "",
      translatedSentences: "",
      sentence: "",
      isSidebarOpen: false,
      audioPath: "",
      checked: false,
      isPlaying: false,
      audioDuration: 0,
      currentTime: 0,
      createdAt: "",

      // Tratar data
      dataString: "",
      dataObj: "",
      dia: "",
      mes: "",
      ano: "",

      countries: [
        { name: "Inglês", code: "us" },
        { name: "Espanhol", code: "es" },
        { name: "Francês", code: "fr" },
        { name: "Italiano", code: "it" },
        { name: "Alemão", code: "de" },
        { name: "Russo", code: "ru" },
        { name: "Português", code: "br" },
        { name: "Holandês", code: "nl" },
      ],
    };
  },
  async mounted() {
    const contentUUID = this.$route.params.contentUUID;

    this.initAudio();

    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/s/content/1/${contentUUID}`
      );
      this.contentList = response.data;

      console.log(this.contentList);

      this.originalSentences = this.contentList[0]?.original_sentences
        .split(".")
        .filter(Boolean)
        .map((s) => s.trim());

      // Uppercase first letter
      this.subject =
        this.contentList[0]?.subject.charAt(0).toUpperCase() +
        this.contentList[0]?.subject.slice(1);

      this.contentUUID = this.contentList[0].content_uuid;
      this.audioPath = this.contentList[0]?.audio_path;
      this.language = this.contentList[0]?.language;

      // Tratando a data
      this.dataString = this.createdAt = this.contentList[0]?.created_at;
      this.dataObj = new Date(this.dataString);

      this.dia = String(this.dataObj.getUTCDate()).padStart(2, "0");
      this.mes = String(this.dataObj.getUTCMonth() + 1).padStart(2, "0"); // Os meses vão de 0 a 11, então adicionamos 1
      this.ano = this.dataObj.getUTCFullYear();
    } catch (error) {
      console.error("Houve um erro ao buscar os dados:", error);
    }
  },

  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },

    getCountryCode(name) {
      const country = this.countries.find((country) => country.name === name);
      return country ? country.code : "";
    },

    getFlagUrl(name) {
      const code = this.getCountryCode(name);
      return `https://flagicons.lipis.dev/flags/4x3/${code}.svg`;
    },

    /// AUDIO PLAYER

    playPauseAudio() {
      const audioElement = this.$refs.audio;
      if (this.isPlaying) {
        audioElement.pause();
      } else {
        audioElement.play();
      }
      this.isPlaying = !this.isPlaying;

      audioElement.addEventListener("timeupdate", this.updateCurrentTime);
      audioElement.addEventListener("timeupdate", this.updateProgressBar);
    },

    goBackward() {
      const audioElement = this.$refs.audio;
      audioElement.currentTime -= 10;
    },

    goForward() {
      const audioElement = this.$refs.audio;
      audioElement.currentTime += 10;
    },

    updateCurrentTime() {
      const audioElement = this.$refs.audio;
      this.currentTime = audioElement.currentTime;
      this.audioDuration = audioElement.duration;

      const minutes = Math.floor(this.currentTime / 60);
      const seconds = Math.floor(this.currentTime % 60)
        .toString()
        .padStart(2, "0");

      document.getElementById(
        "currentTime"
      ).textContent = `${minutes}:${seconds}`;
    },

    updateProgressBar() {
      const audioElement = this.$refs.audio;
      const progressBar = document.getElementById("progress");

      if (audioElement.duration > 0) {
        const percentage =
          (audioElement.currentTime / audioElement.duration) * 100;
        progressBar.style.width = percentage + "%";
      }
    },

    initAudio() {
      this.$nextTick(() => {
        const audioElement = this.$refs.audio;

        if (audioElement) {
          audioElement.addEventListener("loadedmetadata", () => {
            this.audioDuration = audioElement.duration;
            const minutes = Math.floor(this.audioDuration / 60);
            const seconds = Math.floor(this.audioDuration % 60)
              .toString()
              .padStart(2, "0");
            document.getElementById(
              "totalTime"
            ).textContent = `${minutes}:${seconds}`;
          });
        }
      });
    },
  },
  watch: {
    showTranslation(newValue) {
      if (newValue && !this.translationRequested) {
        this.requestTranslation();
      }
    },

    audioPath: {
      immediate: true,
      handler() {
        this.initAudio();
      },
    },
  },
};
</script>

<style scoped>
</style>
  