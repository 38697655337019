<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>

import ContentList from './components/ContentList.vue';
import Login from './components/Login.vue';
import Onboarding from './views/Onboarding.vue';
import Chat from './components/Chat.vue';
import Dashboard from './views/Dashboard.vue';
import Sidebar from './components/Sidebar.vue';
import ContentView from './components/ContentView.vue';
import ChatView from './components/ChatView.vue';
import Notification from './components/ui/Notification.vue';

export default {
	name: 'App',
	components: {
		ContentList,
		Login,
		Onboarding,
		Chat,
		Dashboard,
		ContentView,
		Sidebar,
		ChatView,
		Notification,
	},
};
</script>

<style>


h1 {
	font-size: 24px;
}


.originalText {
	cursor: pointer; /* Muda o cursor para mãozinha quando o mouse passa sobre o texto */
	font-weight: bold;;
}

.translationBox {
	position: absolute;
	background-color: white;
	padding: 20px;
	box-shadow: 3px 5px 11px 0px rgba(0, 0, 1, 0.2);
	display: none;
	color: #4a0077;
	width: 300px;
	height: auto;
	border-radius: 10px;
	text-align: left;
	margin-top: 10px;

}

.translationBox strong {
	color: rgb(146, 0, 255);
	font-style: italic;
	text-align: left;
	font-size: 18px;
}

.translationBox p {
	color: #666;
	font-size: 15px;
	text-align: left;
	line-height: 25px;
	margin: 8px 0;
}

.translationBox.show {
	display: block;
}

.highlight {
	background-color: #444;
}


.clickable-word {
	cursor: pointer;
}


.error-message {
	color: rgb(249, 122, 253);
	font-weight: bold;
	margin-top: 10px;
}


.container {
	display: flex;
	justify-content: center;
}

#player {
	width: 50%;
	left: 25%;
	align-items: center;
	background-color: #f0f0f0;
	bottom: 0;
	position: absolute;
}

#audio {
	width: 100%;
}

#controls {
	display: flex;
}

#progress-container {
	width: 100%;
	height: 10px;
	background-color: #ddd;
	position: relative;
}

#progress {
	background-color: blue;
	height: 5px;
	width: 0%;
}

#time {
	font-size: 12px;
	margin-left: 5px;
}

.fade-in {
	animation: fadeInAnimation ease 1s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
