<template>
	<div class="h-screen flex">
		<!-- Coluna da Esquerda -->
		<Sidebar :username="username"></Sidebar>
		<!-- Coluna da Direita -->
		<div class="flex-1 p-10 mt-10 sm:mt-0 bg-[#F4F0F8] flex flex-col overflow-y-auto ">

			<LanguageSelection/>

			<!-- PayAlert -->
			<PayAlert
				v-if="isPaid === 0"/>

			<!-- Parte de Baixo -->
			<div class="flex-1 overflow-visible w-full	">

				<!-- Header com Título, Subtítulo  -->
				<div
					class="flex flex-col md:flex-row items-start md:items-center justify-between mt-20 w-full md:w-5/5 mb-[00px]">
					<div class="flex flex-col">
						<h2 class="text-4xl font-bold text-gray-700">{{ $t('dashboard.title') }}</h2>
						<p class="font-medium text-gray-500 mt-4">{{ $t('dashboard.subtitle') }}</p>
					</div>

					<!-- Botão para criar novo conteúdo-->
					<div @click="handleClick()" class="flex items-center rounded-xl p-4 text-white
                            transition ease-in-out delay-50 hover:scale-[1.06] 
                            hover:cursor-pointer hover:transform hover:rotate-360 bg-primary items-right mb-10 mt-10">
						<p class="mr-2 font-medium text-white ">{{ $t('dashboard.newMaterial') }}</p>
						<i class="fa-solid fa-wand-magic-sparkles transform transition duration-500 hover:rotate-360 rounded-full text-white "
						   style="font-size: 24px;"></i>
					</div>
				</div>

				<!-- <Options v-model:isVisible="isOptionsToggled"></Options> -->
				<div v-if="filteredContentList.length ===0">
					<p class="font-medium text-gray-700 mt-4">{{ $t('dashboard.noContentYet') }}</p>
				</div>
				<!-- Lista de Conteúdo -->
				<ul v-if="filteredContentList.length > 0"
				    class="flex flex-wrap w-full sm:flex-row justify-items-start space-y-9 sm:space-y-9">
					<li :title="item.subject" v-for="(item, index) in filteredContentList" :key="item.username"
					    class="relative mb-0 sm:mb-0 mr-9 flex" :class="{'mt-0 sm:mt-9': index === 0}">
						<div @click="navigateToContent(item.content_uuid)"
                        class=" text-gray-800 pt-5 pl-5 transition ease-in-out delay-50 bg-[#F1DEFF]
                        hover:cursor-pointer hover:bg-primary hover:text-white sm:w-[280px] w-[290px] h-[150px] rounded-xl p-6"
                        :class="{
                          'text-gray-800 pt-5 pl-5 transition ease-in-out delay-50 sm:w-[280px] w-[290px] h-[150px] rounded-xl p-6 ': true,
                          'bg-[#F1DEFF] hover:cursor-pointer hover:text-white hover:scale-[1.06]': !optionsStates[index]?.isToggled,
                          'bg-primary text-white ': optionsStates[index]?.isToggled
                      }">
							<!-- Tirei o hover pra corrigir um bug. Voltar aqui depois
							  <div @click="navigateToContent(item.content_uuid)" class="relative text-gray-800 pt-7 pl-7 transition ease-in-out delay-50 hover:scale-[1.06] bg-[#F1DEFF] hover:cursor-pointer hover:bg-primary hover:text-white sm:w-[280px] w-[290px] h-[150px] rounded-xl p-6"> -->

							<div @click.stop="toggleOptionsMenu($event, index)" class="flex absolute top-5 right-5 w-8 h-8 rounded-full
                              bg-transparent items-center justify-center transition hover:bg-white
                              hover:text-gray-700  
                              "

							     :class="{
                              'text-gray-800 transition ease-in-out delay-50  hover:text-gray-700 ': true,
                              'bg-[#F1DEFF] hover:cursor-pointer  hover:text-gray-700': !optionsStates[index]?.isToggled,
                              'bg-white text-gray-700': optionsStates[index]?.isToggled
                      }">
								<i class="fa-solid fa-ellipsis-vertical"></i>
							</div>
							<!-- <Options ref="optionsMenu" v-show="isOptionsToggled && currentOptionIndex === index" :position="menuPosition"></Options> -->
							<Options
								:key="item.username"
								:isVisible="optionsStates[index] && optionsStates[index].isToggled"
								@update:isVisible="value => updateOptionsState(index, value)"
								:position="optionsStates[index] && optionsStates[index].position"
								:uuid="item.content_uuid"
								@content-deleted="updateContentList"
							></Options>

							<img width="50" :src="getFlagUrl(language)" class=" rounded-[8px]"/>
							<h3 class="text-2xl font-medium mb-10 h-[60px] overflow-hidden ">
								{{ capitalizeFirstLetter(item.subject) }}</h3>
						</div>
					</li>
				</ul>

				<div
					class="flex w-full md:w-5/5 justify-end mt-5 text-right cursor-pointer hover:text-primary transition ease-in-out delay-50 hover:scale-[1.01] ">
					<p @click="navigateToContentList()"
					   class="mr-2 font-medium text-gray-600 text-lg  hover:text-primary ">
						{{ $t('dashboard.viewAllMaterials') }}</p>
					<i class="pi pi-arrow-right mt-1"></i>
				</div>


				<div class=" flex justify-end p-4 h-[80px] "></div>

				<!-- CONTENT (CREATED BY SYSTEM)  -->
				<ContentSystem :userIdiom=userIdiom />
				<!-- <ContentSystem
				  v-if="userIdiom"
				  :userIdiom=userIdiom /> -->

				<div class=" flex justify-end p-4 h-[80px] "></div>
				<!-- REPETIÇÕES ESPAÇADAS -->
				<SpaceRepetitions v-if="userId" :userId=userId />

				<div class="mb-10"></div>


				<Notification
					v-if="isNotificationVisible"
					:key="notificationKey"
					:msg="notificationMessage"
					:type="notificationType"
				/>


			</div>
		</div>
	</div>

	<div v-if="isLoading">
		<Loading/>
	</div>
</template>

<script>
import axios from 'axios';
import mixin from '../components/mixin';
import Dropdown from 'primevue/dropdown';
import Sidebar from '../components/Sidebar.vue';
import SpaceRepetitions from '../components/SpaceRepetitions.vue';
import PayAlert from '../components/PayAlert.vue';
import ContentSystem from '../components/ContentSystem.vue';
import LanguageSelection from '../components/LanguageSelection.vue';
import Options from '@/components/ui/Options.vue';
import Notification from '@/components/ui/Notification.vue';
import Loading from '../components/ui/Loading.vue';


export default {

	mixins: [mixin],

	components: {
		Dropdown,
		Sidebar,
		SpaceRepetitions,
		PayAlert,
		ContentSystem,
		LanguageSelection,
		Options,
		Notification,
		Loading,
	},

	data() {
		return {
			language: null,
			countries: [

				{ name: 'Inglês', code: 'us' },
				{ name: 'Espanhol', code: 'es' },
				{ name: 'Francês', code: 'fr' },
				{ name: 'Italiano', code: 'it' },
				{ name: 'Alemão', code: 'de' },
				{ name: 'Russo', code: 'ru' },
				{ name: 'Português', code: 'br' },
				{ name: 'Holandês', code: 'nl' },
			],

			contentList: [],
			// showSidebar: window.innerWidth >= 1024, // lg breakpoint do Tailwind
			username: '',
			userLanguage: '',
			userId: '',
			userIdiom: '',
			contentLang: '',
			createdAt: '',
			contentSubject: '',
			contentLevel: '',
			contentUUID: '',
			selectedLanguage: null,
			isPaid: 0,
			locale: '',
			translationsLoaded: false,
			numberOfContent: 0,
			isOptionsToggled: false,
			menuPosition: { x: 0, y: 0 },
			currentOptionIndex: null,
			optionsStates: {},
			isLoading: true,

		};
	},

	async mounted() {
		// Guarda o usuário
		this.isLoading = true;
		const username = localStorage.getItem('username');
		const user_id = localStorage.getItem('userId');

		this.username = username;
		// console.log(`Username`, username)
		//Puxa os dados da rota /user/content/:username
		try {
			const responseB = await axios.get(`${ process.env.VUE_APP_API_URL }/user/u/${ username }`);
			var list = [responseB.data];
			// console.log(responseB.data)

			this.userIdiom = list[0][0]?.learning_language;
			this.locale = list[0][0]?.user_idiom;
			this.isPaid = list[0][0]?.is_paid;
			localStorage.setItem("language", this.userIdiom)
			localStorage.setItem("learningLanguage", this.userIdiom)
			// console.log("O rapaz paga", this.isPaid, "numero de conteudos: ", this.numberOfContent)

			const response = await axios.get(`${ process.env.VUE_APP_API_URL }/user/content/${ username }/6/${ this.userIdiom }`);
			// alert (this.userIdiom)
			this.contentList = [response.data];
			this.userId = user_id;
			this.numberOfContent = response.data.length;

			const responseTrial = await axios.get(`${ process.env.VUE_APP_API_URL }/check-trial/${ username }`);
			var list = [responseTrial.data];
			this.inTrial = list[0]?.inTrial;
			console.log('Está em trial', this.inTrial);

			this.userIdiom = localStorage.getItem('userIdiom');
			this.language = localStorage.getItem('language');

			// await this.loadTranslations();
			this.isLoading = false;

			// console.log('DASHBOARD.VUE : PAI', this.userIdiom)
			// console.log(this.userIdiom)
			// console.log(this.contentList)

			// Se for onboarding (não criou conteúdo), envia para Onboarding
			if (this.contentList[0][0]?.is_onboarding) {
				this.navigateToOnboarding();
			}
			// Se não, segue para Dashboard
		} catch (error) {
			console.error('Houve um erro ao buscar os dados:', error);
		}

		// document.addEventListener('click', this.handleClickOutside);

	},

	// beforeUnmount() {
	//   document.removeEventListener('click', this.handleClickOutside);
	// },

	computed: {
		filteredContentList() {
			// Verifica se contentList[0] existe e não está vazio
			if (!this.contentList[0] || this.contentList[0].length === 0) return [];

			// Se nenhuma linguagem foi selecionada, retorna a lista inteira
			if (!this.selectedLanguage) return this.contentList[0];

			// Filtra a lista com base na linguagem selecionada
			return this.contentList[0].filter(item => {
				// Certifique-se de que tanto item.language quanto this.selectedLanguage existem antes de compará-los
				if (item.language && this.selectedLanguage) {
					return item.language === this.selectedLanguage;
				}
				return false;
			});
		},
	},

	methods: {

		async updateContentList() {
			this.showNotification('notifications.materialDeletedSuccessfully', 'success');

			const username = localStorage.getItem('username');
			const responseB = await axios.get(`${ process.env.VUE_APP_API_URL }/user/u/${ username }`);
			var list = [responseB.data];
			// console.log(list)

			this.userIdiom = list[0][0]?.learning_language;
			this.locale = list[0][0]?.user_idiom;
			this.isPaid = list[0][0]?.is_paid;
			console.log('O rapaz paga', this.isPaid, 'numero de conteudos: ', this.numberOfContent);


			const response = await axios.get(`${ process.env.VUE_APP_API_URL }/user/content/${ username }/6/${ this.userIdiom }`);
			// alert (this.userIdiom)
			this.contentList = [response.data];
			// this.userId = user_id
			this.numberOfContent = response.data.length;

			const responseTrial = await axios.get(`${ process.env.VUE_APP_API_URL }/check-trial/${ username }`);
			var list = [responseTrial.data];
			this.inTrial = list[0]?.inTrial;
			console.log('Está em trial', this.inTrial);


			this.userIdiom = localStorage.getItem('userIdiom');

			// this.loadTranslations();

			// console.log('DASHBOARD.VUE : PAI', this.userIdiom)

			// console.log(this.userIdiom)
			// console.log(this.contentList)

			// Se for onboarding (não criou conteúdo), envia para Onboarding
			if (this.contentList[0][0]?.is_onboarding) {
				this.navigateToOnboarding();
			}
		},

		getCountryCode(name) {
			const country = this.countries.find(country => country.name === name);
			return country ? country.code : '';
		},

		getFlagUrl(name) {
			const code = this.getCountryCode(name);
			return `https://flagicons.lipis.dev/flags/4x3/${ code }.svg`;
		},

		capitalizeFirstLetter(string) {

			let words = string.split(' ');
			let firstWords = words.slice(0, 6);
			let phrase = firstWords.join(' ');

			if (words.length > 6) {
				phrase += '...';
			}

			return phrase.charAt(0).toUpperCase() + phrase.slice(1);
		},

		checkAuthentication(username) {
			if (username) {
				console.log(username);
			} else {
				this.navigateToLogin();
			}
		},

		updateOptionsState(index, value) {
			if (!this.optionsStates[index]) {
				this.optionsStates[index] = { isToggled: false, position: { x: 0, y: 0 } };
			}
			this.optionsStates[index].isToggled = value;
			this.optionsStates = { ...this.optionsStates };
		},

		// handleClickOutside(event) {

		//   if (!this.$el.contains(event.target)) {


		//     let shouldUpdate = false;
		//     Object.keys(this.optionsStates).forEach(key => {
		//       if (this.optionsStates[key].isToggled) {
		//         this.optionsStates[key].isToggled = false;
		//         shouldUpdate = true;
		//       }
		//     });
		//     if (shouldUpdate) {
		//       // Forçar a atualização para garantir a reatividade
		//       this.optionsStates = { ...this.optionsStates };
		//     }

		// },

		toggleOptionsMenu(event, index) {
			event.stopPropagation();

			Object.keys(this.optionsStates).forEach(key => {
				if (key != index) { // Ignorar o menu atual que está sendo alternado
					this.optionsStates[key].isToggled = false;
				}
			});

			const isToggled = this.optionsStates[index]?.isToggled || false;

			// Encontrar o contêiner pai com position relative
			let parent = event.currentTarget.offsetParent;

			// Obter as coordenadas do botão em relação ao contêiner pai
			const rect = event.currentTarget.getBoundingClientRect();
			const parentRect = parent.getBoundingClientRect();

			const newPosition = {
				x: rect.left - parentRect.left, // posição X relativa ao contêiner pai
				y: rect.top - parentRect.top + rect.height, // posição Y relativa ao contêiner pai + altura do botão
			};

			if (!this.optionsStates[index]) {
				this.optionsStates[index] = { isToggled: !isToggled, position: newPosition };
			} else {
				this.optionsStates[index].isToggled = !isToggled;
				if (!isToggled) {
					this.optionsStates[index].position = newPosition;
				}
			}

			this.optionsStates = { ...this.optionsStates };
		},


		updateOptionsState(index, value) {
			if (!this.optionsStates[index]) {
				this.optionsStates[index] = { isToggled: false, position: { x: 0, y: 0 } };
			}
			this.optionsStates[index].isToggled = value;
			// Forçar a atualização para garantir a reatividade
			this.optionsStates = { ...this.optionsStates };
		},

	},

	beforeDestroy() {
		window.removeEventListener('resize', () => {
			this.showSidebar = window.innerWidth >= 1024;
		});
	},
};
</script>