<template>
  <div>
    <div v-if="loading" class="flex justify-center mx-auto">
      <i class="fa-solid fa-wand-magic-sparkles fa-spin text-primary mt-[20px] mb-[20px]" style="font-size: 25px"></i>
    </div>
    <div v-else class="flex flex-col items-center text-center">
      <div class="w-full bg-[#F1DEFF] h-4 mt-4 rounded-lg overflow-hidden mb-10">
        <div :style="{ width: progressPercentage + '%' }" class="bg-primary h-full"></div>
      </div>

      <div v-if="originalSentences" class="w-full">
        <ul class="mb-20 justify-items-center">
          <p class="text-2xl font-bold text-gray-700 mb-2"></p>
          <p class="mb-5"></p>

          <div class="mx-auto my-0 relative" v-if="pronunciationSentences.length > 0 &&
            pronunciationSentences.length > 0
            ">
            <div class="relative">
              <p @click="previousSentence()"
                class="absolute left-0 cursor-pointer transition ease-in-out delay-50 hover:scale-[1.2] hover:cursor-pointer hover:transform"
                style="font-size: 30px">
                <i class="fa-solid fa-arrow-left"></i>
              </p>

              <p @click="nextSentence()"
                class="absolute right-0 cursor-pointer transition ease-in-out delay-50 hover:scale-[1.2] hover:cursor-pointer hover:transform"
                style="font-size: 30px">
                <i class="fa-solid fa-arrow-right"></i>
              </p>
            </div>

            <li class="flex flex-col">
              <!-- Áudio -->
              <div class="flex justify-center mb-5">
                <audio :src="`${pronunciationSentences[currentSentenceIndex].audioPath}`" ref="audioPlayer"></audio>
                <button
                  class="rounded-full w-[50px] h-[50px] bg-primary text-white p-0 ml-1 transition ease-in-out delay-50 hover:scale-[1.1] hover:cursor-pointer hover:transform"
                  @click="playAudio(currentSentenceIndex)">
                  <i :class="playingAudioIndex === currentSentenceIndex
                    ? 'fa-solid fa-pause'
                    : 'fa-solid fa-play'
                    "></i>
                </button>
              </div>

              <!-- Texto -->
              <div class="w-full space-y-3 flex flex-col">
                <p class="flex mx-auto font-medium text-gray-500 text-[1.1rem] r">
                  {{ pronunciationSentences[currentSentenceIndex].fonetics }}
                </p>  
                <p @click="wordClicked($event)" class="mx-auto font-medium text-primary text-[1.7rem] cursor-pointer">
                  {{ pronunciationSentences[currentSentenceIndex].originalSentence }}
                </p>
              
              </div>
            </li>
          </div>
        </ul>

        <!-- Botão de gravação -->
        <div class="flex transform z-50 mx-auto w-[70px] mt-20">
          <div class="space-y-9">
            <button @click="isRecording ? stopRecording() : handleButtonClick()" :class="`rounded-full w-20 h-20 bg-primary text-white p-0 transition ease-in-out delay-50 
                               hover:cursor-pointer ${isRecording
                ? 'animate-pulse'
                : 'hover:scale-[1.1]'
              }`">
              <i :class="isRecording
                ? 'fa-solid fa-circle-stop'
                : 'fa-solid fa-microphone-lines'
                " style="font-size: 40px"></i>
            </button>
          </div>
        </div>

        <div v-if="isUploading" class="flex justify-center mx-auto">
          <i class="fa-solid fa-wand-magic-sparkles fa-spin text-primary mt-[20px] mb-[20px] items-center justify-center mx-auto"
            style="font-size: 25px"></i>
        </div>
      </div>

      <!-- Tradução das palavras -->
      <div class="translationBox" ref="translationBox">
        <strong>{{ selectedWord }}</strong>
        <p v-if="isWordLoading">
          <i class="fa-solid fa-wand-magic-sparkles fa-spin text-primary mt-[20px] mb-[20px]" style="font-size: 17px"></i>
        </p>
        <p v-else>{{ wordTranslation }}</p>
      </div>

      <Notification v-if="isNotificationVisible" :key="notificationKey" :msg="notificationMessage"
        :type="notificationType" />
    </div>
  </div>

  <div v-if="isLoading">
    <Loading />
  </div>
</template>



<script>
import mixin from "./mixin";
import axios from "axios";
import Notification from "@/components/ui/Notification.vue";
import Loading from "../components/ui/Loading.vue";
import RecordRTC from 'recordrtc';


export default {
  mixins: [mixin],
  components: {
    Notification,
    Loading,
  },

  props: ["language", "countries", "subject", "original_sentences", "level"],

  data() {
    return {
      recorder: null,
      isRecording: false,
      audioUrl: '',
      exercise_questions: [],
      exercise_answers: "",
      selectedAnswers: [],
      numberOfQuestions: 0,
      currentQuestionIndex: 0,
      loading: true,
      score: 0,
      dataGpt: "",
      userIdiom: "",
      learningLanguage: "",
      language: "",
      locale: "",
      originalSentences: [],
      showTranslation: "",
      selectedWord: "",
      wordTranslation: "",
      isWordLoading: false,
      mediaRecorder: null,
      audioChunks: [],
      audioBlob: null,
      transcriptionResults: [],
      chatGptResponses: [],
      audioPath: [],
      translation: [],
      playingAudioIndex: null,
      currentSentenceIndex: 0, // Adicione esta linha
      sentenceSpoken: "",
      pronunciationList: "",
      originalSentencesAudio: [],
      pronunciationSentences: [],
      isLoading: true,
      isShrunk: false,
      isUploading: false,
    };
  },

  async created() {
    try {
      const username = localStorage.getItem("username");
      this.loading = true;
      this.isLoading = true;

      const contentUUID = this.$route.params.contentUUID;

      // Busca informações do usuário
      const responseB = await axios.get(
        `${process.env.VUE_APP_API_URL}/user/u/${username}`
      );
      var list = [responseB.data];
      this.locale = list[0][0]?.user_idiom;
      this.userIdiom = list[0][0]?.user_idiom;
      this.learningLanguage = list[0][0]?.learning_language;

      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/content/${contentUUID}`
      );
      this.contentList = [response.data];
      this.originalSentences = JSON.parse(
        this.contentList[0]?.original_sentences
      );
      console.log(" response: ", response);

      const sentenceFormData = new FormData();

      // this.sentenceSpoken = this.originalSentences[this.currentSentenceIndex]

      sentenceFormData.append(
        "originalSentences",
        JSON.stringify(this.originalSentences)
      );
      sentenceFormData.append("contentUUID", contentUUID);
      sentenceFormData.append("userLanguage", this.userIdiom);
      sentenceFormData.append("learningLanguage", this.learningLanguage)

      console.log("contentUUID", contentUUID);

      const createSentenseResponse = await axios.post(
        `${process.env.VUE_APP_API_URL}/create-sentence`,
        sentenceFormData
      );
      // this.originalSentencesAudio = JSON.parse(createSentenseResponse.data.results)
      // console.log("createSentenseResponse", createSentenseResponse.data)

      // this.pronunciationSentences = JSON.parse(createSentenseResponse.data.results[0]?.original_sentence);
      this.pronunciationSentences = JSON.parse(
        createSentenseResponse.data.results.original_sentence
      );

      //  console.log()
      console.log(" pronunciationSentences", this.pronunciationSentences);
      // console.log(" Audio",  JSON.parse(createSentenseResponse.data.results[0].original_sentence)[0].audioPath)

      // this.loadTranslations();

      this.isLoading = false;

      this.language = localStorage.getItem("language");
    } catch (error) {
      console.error("Houve um erro ao buscar os dados:", error);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    nextSentence() {
      if (this.currentSentenceIndex < this.originalSentences.length - 1) {
        this.currentSentenceIndex++;
      } else {
        // Opcional: resetar para 0 ou outra ação quando chegar ao final do array
        this.currentSentenceIndex = 0; // Ou qualquer outra lógica que você desejar
      }

      this.$emit("nextSentenceEvent");
    },
    previousSentence() {
      if (
        this.currentSentenceIndex < this.originalSentences.length - 1 &&
        this.currentSentenceIndex > 0
      ) {
        this.currentSentenceIndex--;
      } else {
        this.currentSentenceIndex = 0;
      }

      // this.$emit('previousSentence');
    },

    async handleButtonClick() {
      // console.log("sentenca: ", this.originalSentences[this.currentSentenceIndex])

      await this.startRecording();

      // Vai para a próxima senten~ca
      if (this.currentSentenceIndex < this.originalSentences.length - 1) {
        // this.currentSentenceIndex++;
      } else {
        console.log("Chegou ao final das sentenças");
      }
    },
    async startRecording() {
			try {
				const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
				const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

				const options = {
					type: 'audio',
					numberOfAudioChannels: 2,
					checkForInactiveTracks: true,
					bufferSize: 16384,
				};

				if (isSafari) {
					options.sampleRate = 44100;
					options.bufferSize = 4096;
					options.mimeType = 'audio/wav';
				}

				if (this.recorder) {
					this.recorder.destroy();
					this.recorder = null;
				}

				this.recorder = new RecordRTC(stream, options);

				this.stream = stream;
				this.recorder.startRecording();
				this.isRecording = true;
			} catch (error) {
				console.error('Falha ao iniciar a gravação: ', error);
				this.showNotification(
					`No microphone detected. Please, allow Lanna to use your microphone`,
					'error',
				);
			}
		},

    async stopRecording() {
      if (!this.recorder) return;

      this.recorder.stopRecording(() => {
        let blob = this.recorder.getBlob();
        this.audioUrl = URL.createObjectURL(blob);

        if (this.stream) {
          this.stream.getTracks().forEach(track => track.stop());
        }

        // Após parar a gravação e liberar a stream, fazemos o upload do áudio
        this.uploadAudio(blob);

        this.stream = null;
        this.recorder = null;
        this.isRecording = false;
      });
    },

    async uploadAudio(blob) {
      const formData = new FormData();

      this.sentenceSpoken = this.originalSentences[this.currentSentenceIndex];

      formData.append("audio", blob, "audio.wav");
      formData.append("userIdiom", this.userIdiom);
      formData.append("learningLanguage", this.language);
      formData.append("sentenceSpoken", this.sentenceSpoken);
      formData.append("contentUUID", this.contentUUID);

      try {
        this.isUploading = true;

        const responsePronunciation = await axios.post(
          `${process.env.VUE_APP_API_URL}/pronunciation`,
          formData
        );
        this.pronunciationList = [responsePronunciation.data];

        console.log("pronunciationList", this.pronunciationList);

        this.chatGptResponses = JSON.parse(
          this.pronunciationList[0].chatGptTextResponse
        ).result;
        this.userPhrase = this.pronunciationList[0].userSentence;

        this.$emit("updateChatGptResponses", this.chatGptResponses);
        this.$emit("userPhrase", this.userPhrase);
        this.$emit("nextSentencePai", this.nextSentencePai);

        this.isUploading = false;

        this.loading = 0;
      } catch (error) {
        console.error("Erro ao enviar o áudio: ", error);
      }
    },

    playAudio() {
      const audioPlayer = this.$refs.audioPlayer;
      if (!audioPlayer) return;

      audioPlayer.onended = this.handleAudioEnd;

      if (this.playingAudioIndex === this.currentSentenceIndex) {
        audioPlayer.pause();
        this.playingAudioIndex = null;
      } else {
        if (this.playingAudioIndex !== null) {
          audioPlayer.pause();
          audioPlayer.currentTime = 0;
        }

        audioPlayer.play().catch((e) => {
          console.error("Erro ao reproduzir áudio automaticamente:", e);
        });
        this.playingAudioIndex = this.currentSentenceIndex;
      }
    },

    handleAudioEnd() {
      // Chamado quando o áudio termina de tocar
      this.playingAudioIndex = null;
    },
  },

  computed: {
    progressPercentage() {
      return (this.currentSentenceIndex / this.originalSentences.length) * 100;
    },
  },
  watch: {},
};
</script>