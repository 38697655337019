<template>
    <!-- <div class="flex flex-col md:flex-row items-center  
               justify-between w-full md:w-3/4 mt-10 rounded-lg
               p-4 bg-[#33B679] text-white font-base ">
        <h2 class="flex space-x-2">
            <i class="pi pi-arrow-right mt-1"></i>
            <p><span class="font-bold">50 off no Pro!</span> Estude o quanto quiser e com as melhores vozes!</p>
        </h2>

        <router-link :to="{ path: '/paywall', query: { isDirectToPay : true } }" tag="button" class="bg-white text-black rounded-lg p-2 pl-8 pr-8 font-medium hover:bg-primary hover:text-white transition ease-in-out delay-50 hover:scale-[1.06]">
            Assinar
        </router-link>
    </div> -->
</template>

<script>
    export default {

    }
</script>

<style>

</style>