<template>
  <div v-if="isVisible" :style="computedStyle"
    class="options w-[250px] font-medium p-4 bg-white radius rounded-xl fixed text-black z-150 hover:text-black shadow-lg p-6 bg-white rounded-lg">
    <ul @click.stop="selectOption, deleteContent(uuid)"
      class="gap-y-10 flex justify-between hover:text-white hover:bg-[#FF3B3B] px-5 py-2 rounded-lg">
      <div class="flex flex-row justify-between w-full">
        <span> Deletar </span>
        <li><i class="fa-solid fa-trash"></i></li>
      </div>
      <div v-if="isLoadingDelete" class="flex justify-center mx-auto">
        <i class="fa-solid fa-wand-magic-sparkles fa-spin text-primary mt-[20px] mb-[20px]" style="font-size: 25px"></i>
      </div>
    </ul>

    <Notification v-if="isNotificationVisible" :key="notificationKey" :msg="notificationMessage"
      :type="notificationType" />
  </div>
</template>

<script>
import axios from "axios";
import mixin from "../mixin";
import Notification from "@/components/ui/Notification.vue";

export default {
  components: {
    Notification,
  },

  mixins: [mixin],

  data() {
    return {
      isLoadingDelete: false,
    };
  },

  props: ["isVisible", "position", "uuid"],
  emits: ["update:isVisible"],
  computed: {
    computedStyle() {
      let top = this.position.y + 10;
      let left = this.position.x;

      // Ajustar se o menu estiver fora da tela na parte inferior
      if (top + 500 > window.innerHeight) {
        top = window.innerHeight - 500;
      }

      // Ajustar se o menu estiver fora da tela na direita
      if (left + 250 > window.innerWidth) {
        left = window.innerWidth - 250;
      }

      return {
        top: top + "px",
        left: left + "px",
        position: "absolute", // Posição absoluta em relação ao pai
        zIndex: 50, // Mantém na frente dos outros elementos
      };
    },
  },

  async mounted() {
    document.addEventListener("click", this.handleClickOutside);
    // this.loadTranslations();
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    handleClickOutside(event) {
      if (this.isVisible && !this.$el.contains(event.target)) {
        this.$emit("update:isVisible", false);
      }
    },
    selectOption() {
      this.$emit("update:isVisible", false); // Fechar o menu após a seleção
    },

    deleteContent(contentUuid) {
      if (!contentUuid) {
        return;
      }

      axios
        .delete(`${process.env.VUE_APP_API_URL}/delete-content`, {
          data: { contentUuid },
        })
        .then((response) => {
          this.isLoadingDelete = true;
          if (response.data.success) {
            this.selectOption();
            this.$emit("content-deleted");
            this.isLoadingDelete = false;
          } else {
            this.showNotification("notification.errorDeleting", "error");
            console.error("Erro ao deletar o conteúdo: ", response.data.error);
            this.isLoadingDelete = false;
          }
        })
        .catch((error) => {
          console.error("Ocorreu um erro ao deletar o conteúdo: ", error);
          this.isLoadingDelete = false;
        });
    },
  },
};
</script>

<style scoped>
.options {
  /* Impedir que o componente <Options> reaja a eventos de mouse do pai */
  pointer-events: none;
}

.options>* {
  /* Reativar eventos de mouse para elementos dentro de <Options> */
  pointer-events: auto;
}
</style>
