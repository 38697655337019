import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createI18n } from 'vue-i18n';

import './index.css';
import PrimeVue from 'primevue/config';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import '@fortawesome/fontawesome-free/css/all.css';


// Crie uma instância do VueI18n
const i18n = createI18n({
	locale: 'en', // idioma padrão
	messages: {
		// mensagens de localização, pode começar vazio se carregar dinamicamente
	},
});

const app = createApp(App);

app.use(PrimeVue);
app.use(router);

app.use(i18n); // Use i18n com a instância do app

app.mount('#app');

window.root = app;
window.root.i18n = i18n.global;
window.root.router = router;
