<template>
  <div class="bg-primary w-full  flex flex-col min-h-screen">
    <!-- Back button -->
    <div @click="navigateToDashboard()" class="relative cursor-pointer">
      <i class="text-white pi pi-arrow-left absolute left-5 top-5 hover:scale-[120%] transition ease-in-out delay-50 block"
        style="font-size: 1.5rem"></i>
    </div>

    <div class="flex justify-center items-center p-[20px] rounded-lg w-full mt-10 sm:mt-20 md:mt-20 mx-auto">
      <div v-if="currentStep === 1" class="space-y-5 flex flex-col items-center justify-center text-center fade-in">
        <h1 class="leading-tight text-3xl text-gray-800 font-bold text-white">
          {{
      $t("createContent.createStudyMaterial", {
        language: translatedLanguage,
      })
    }}
        </h1>
        <input type="hidden" id="userId" value="{{ userId }}" />
        <div class="mb-20"></div>
        <img :src="getFlagUrl(language)" style="width: 128px" class="rounded-lg" />
        <div class="error-message" v-if="errorStep1">
          {{ $t("createContent.chooseLanguageError") }}
        </div>
        <div>
          <Button :label="$t('onboarding.nextButton')" @click="nextStep(1)"
            class="flex items-center rounded-xl p-4 text-white transition ease-in-out delay-50 hover:scale-[1.06] hover:cursor-pointer hover:transform hover:rotate-360 bg-[#33B679] items-right mb-10 mt-10 w-[250px] mt-[10px]" />
        </div>
      </div>

      <div v-if="currentStep === 2" class="space-y-5 flex flex-col items-center justify-center text-center fade-in">
        <h1 class="leading-tight text-3xl text-gray-800 font-bold text-white">
          {{
      $t("createContent.textLevelQuestion", {
        language: translatedLanguage,
      })
    }}
        </h1>
        <p class="text-white text-xl">
          {{ $t("createContent.informationHelp") }}
        </p>
        <div class="mb-20"></div>

        <div class="card flex justify-content-center w-[250px]">
          <select v-model="level"
            class="w-full md:w-14rem p-4 rounded-lg border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
            <option disabled value="">
              {{ $t("createContent.selectContentLevel") }}
            </option>
            <option v-for="level in levels" :key="level.name" :value="level.name">
              {{ $t(level.code) }}
            </option>
          </select>
        </div>

        <div class="error-message fade-in" v-if="errorStep2">
          {{ $t("createContent.selectLevel") }}
        </div>
        <div>
          <Button :label="$t('onboarding.nextButton')" @click="nextStep(1)"
            class="flex items-center rounded-xl p-4 text-white transition ease-in-out delay-50 hover:scale-[1.06] hover:cursor-pointer hover:transform hover:rotate-360 bg-[#33B679] items-right mb-10 mt-10 w-[250px] mt-[10px]" />
        </div>
      </div>

      <div v-if="currentStep === 3" class="space-y-5 flex flex-col items-center justify-center text-center fade-in">
        <h1 class="leading-tight text-3xl text-gray-800 font-bold text-white">
          {{ $t("createContent.aboutSubject") }}
        </h1>

        <!-- <p class="text-white text-xl">{{ $t("createContent.writeAbout") }}</p> -->

        <div class="mb-20"></div>

        <div class="flex flex-col space-y-7">

          <div>
            <h3 class="text-white  mb-2">{{ $t("createContent.contentType") }}</h3>
            <select v-model="contentType" class="w-[300px] p-4 rounded-lg mb-3 font-medium text-lg text-gray-600">
              <option value="Conversa">
                {{ $t("createContent.conversation") }}
              </option>
              <option value="História">
                {{ $t("createContent.history") }}
              </option>
            </select>
          </div>

          <div>
            <h3 class="text-white mb-2">{{ $t("createContent.levelOfFormality") }}</h3>
            <select v-model="levelOfFormality" class="w-[300px] p-4 rounded-lg mb-3 font-medium text-lg text-gray-600">
              <option value="Muito informal">
                {{ $t("createContent.veryInformal") }}
              </option>
              <option value="Dia a dia">
                {{ $t("createContent.normal") }}
              </option>
              <option value="Formal">
                {{ $t("createContent.formal") }}
              </option>
            </select>
          </div>


          <div>
            <h3 class="text-white mb-2">
              <p>{{ $t("onboarding.writeAbout") }}</p>
            </h3>
            <InputText maxlength="100" type="text" v-model="subject"
              class="w-[300px] p-4 rounded-lg mb-3 font-medium text-lg text-gray-600" />
          </div>
        </div>

        <div class="error-message" v-if="errorStep3">Oops!</div>

        <div>
          <Button :label="$t('onboarding.nextButton')" @click="submitForm()"
            class="flex items-center rounded-xl p-4 text-white transition ease-in-out delay-50 hover:scale-[1.06] hover:cursor-pointer hover:transform hover:rotate-360 bg-[#33B679] items-right mb-10 mt-10 w-[250px] mt-[10px]" />
        </div>
      </div>

      <div v-if="currentStep === 4" class="space-y-5 flex flex-col items-center justify-center text-center fade-in">
        <h1 class="leading-tight text-3xl text-gray-800 font-bold text-white mb-5">
          {{ $t("createContent.creatingMaterial") }}
        </h1>

        <p class="text-xl text-gray-800 font-bold text-white mb-[70px]">
          {{ $t("createContent.dontForgetHeadphones") }}
        </p>

        <img src="https://learning-language.s3.us-east-2.amazonaws.com/imgs/ui/onboarding/use_headset.png"
          class="w-[200px] rounded-full" />

        <i class="fa-solid fa-wand-magic-sparkles fa-spin text-white mt-[30px]" style="font-size: 35px"></i>
      </div>

      <div v-if="currentStep === 5">
      </div>
    </div>

    <div v-if="isLoading">
      <Loading />
    </div>
  </div>
</template>

<script>
import mixin from "./mixin";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import Slider from "primevue/slider";
import InputText from "primevue/inputtext";
import axios from "axios";
import ProgressBar from "primevue/progressbar";
import Loading from "../components/ui/Loading.vue";

export default {
  mixins: [mixin],

  components: {
    Button,
    Dropdown,
    Slider,
    InputText,
    ProgressBar,
    Loading,
  },

  data() {
    return {
      language: null,
      selectedLevelName: "",
      languageTranslationMap: {
        Inglês: "register.language.english",
        Espanhol: "register.language.spanish",
        Francês: "register.language.french",
        Italiano: "register.language.italian",
        Alemão: "register.language.german",
        Russo: "register.language.russian",
        Português: "register.language.portuguese",
        Holandês: "register.language.dutch",
      },
      englishLanguageNames: {
        Inglês: "english",
        Espanhol: "spanish",
        Francês: "french",
        Italiano: "italian",
        Alemão: "german",
        Russo: "russian",
        Português: "portuguese",
        Holandês: "dutch",
      },
      countries: [
        { name: "Inglês", code: "us" },
        { name: "Espanhol", code: "es" },
        { name: "Francês", code: "fr" },
        { name: "Italiano", code: "it" },
        { name: "Alemão", code: "de" },
        { name: "Russo", code: "ru" },
        { name: "Português", code: "br" },
        { name: "Holandês", code: "nl" },
      ],

      level: null,
      levels: [
        { name: "Básico", code: "createContent.basic" },
        { name: "Intermediário", code: "createContent.intermediate" },
        { name: "Avançado", code: "createContent.advanced" },
      ],

      currentStep: 1,
      contentList: [],
      subject: "",
      levelOfFormality: "",
      contentType: "",
      errorStep1: false,
      errorStep2: false,
      errorStep3: false,
      selectedWord: "",
      wordTranslation: "",
      showTranslation: "",
      translatedSentences: "",
      userId: "",
      locale: "",
      value: 20,
      isLoading: false,

      // SELECT STEP 1
    };
  },

  async mounted() {
    this.isLoading = true;

    const username = localStorage.getItem("username");
    const user_id = localStorage.getItem("userId");

    console.log(`Username`, username);
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/user/content/${username}`
      );

      this.contentList = [response.data];

      const responseB = await axios.get(
        `${process.env.VUE_APP_API_URL}/user/u/${username}`
      );
      var list = [responseB.data];
      // console.log(list)

      this.language = list[0][0]?.learning_language;

      this.userId = list[0][0]?.user_id;
      console.log(`UserId`, this.userId);
      this.locale = this.contentList[0][0]?.user_idiom;
      // this.loadTranslations();
      this.isLoading = false;
    } catch (error) {
      console.error("Houve um erro ao buscar os dados:", error);
    }

    window.addEventListener("resize", () => {
      this.showSidebar = window.innerWidth >= 1024;
    });
  },

  methods: {
    getCountryCode(name) {
      const country = this.countries.find((country) => country.name === name);
      return country ? country.code : "";
    },
    getFlagUrl(name) {
      const code = this.getCountryCode(name);
      return `https://flagicons.lipis.dev/flags/4x3/${code}.svg`;
    },
  },
  watch: {
    showTranslation(newValue) {
      if (newValue && !this.translationRequested) {
        this.requestTranslation();
      }
    },

    // levelOfFormality(newValue) {
    //   if (newValue) {
    //     alert(this.levelOfFormality)
    //   }  
    // }
  },
  computed: {
    translatedLanguage() {
      if (this.language) {
        return this.$t(
          "register.language." + this.englishLanguageNames[this.language]
        );
      }
      return "";
    },
  },
};
</script>

<style scoped>
.large-stars .p-rating-star {
  font-size: 2em;
  /* Ajuste o tamanho conforme necessário */
}

.fade-in {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
