<template>
  <div>

    <div class="flex flex-col items-center text-center">
      <div class="w-full bg-[#F1DEFF] h-4 mt-4 rounded-lg overflow-hidden mb-10">
        <div :style="{ width: progressPercentage + '%' }" class="bg-primary h-full"></div>
      </div>

      <div v-if="originalSentences" class="w-full">
        <ul class="mb-20 justify-items-center">
          <p class="text-2xl font-bold text-gray-700 mb-2"></p>
          <p class="mb-5"></p>

          <div class="mx-auto my-0 relative">
            <div class="relative">
              <p @click="previousSentence()"
                class="absolute left-0 cursor-pointer transition ease-in-out delay-50 hover:scale-[1.2] hover:cursor-pointer hover:transform"
                style="font-size: 30px">
                <i class="fa-solid fa-arrow-left"></i>
              </p>

              <p @click="nextSentence()"
                class="absolute right-0 cursor-pointer transition ease-in-out delay-50 hover:scale-[1.2] hover:cursor-pointer hover:transform"
                style="font-size: 30px">
                <i class="fa-solid fa-arrow-right"></i>
              </p>
            </div>

            <li class="flex flex-col">
              <div class="flex justify-center mb-5">
              </div>
              <div class="flex flex-row justify-center items-center space-x-10 mb-10">

                <div class="flex flex-row justify-center items-center">
                  <img
                    :src="translationDirection === 'originalToTranslated' ? getFlagUrl(language) : getFlagUrlSmall(userIdiom)"
                    class="w-[60px] rounded-lg mr-4" />

                  <i @click="toggleTranslationDirection"
                    class="rounded-full bg-primary text-white p-5 fa-solid fa-right-left cursor-pointer"
                    style="font-size:25px"></i>

                  <img
                    :src="translationDirection === 'originalToTranslated' ? getFlagUrlSmall(userIdiom) : getFlagUrl(language)"
                    class="w-[60px] rounded-lg ml-4" />
                </div>

              </div>
              <!-- Texto -->
              <div class="flex flex-col w-full space-y-3 flex mb-10">
                <p v-if="sentenceType === '' || sentenceType === 'original'" @click="wordClicked($event)"
                  class="mx-auto font-medium text-primary text-[1.7rem] cursor-pointer">
                  {{ originalSentences[currentSentenceIndex] }}
                </p>
                <p v-if="sentenceType === 'translated'" class="mx-auto font-medium text-primary text-[1.7rem]">
                  {{ translatedSentences[currentSentenceIndex] }}
                </p>
              </div>
              <!-- Writing -->

              <div
                class="w-full space-y-3 flex w-[300px] sm:w-full md:w-full mx-auto font-medium text-primary text-[1.2rem]  border-2 border-primary rounded-xl  text-center position-relative">

                <input type="text" v-model="writingText" @keyup.enter="sendWriting"
                  class="w-[300px] sm:w-full md:w-full h-50 sm:w-[500px] md:w-[500px] mx-auto font-medium text-gray-700 text-[1.2rem] rounded-xl p-5 text-center focus:outline-none" />

                <div @click="sendWriting()">
                  <i
                    class="fa-solid fa-paper-plane text-primary cursor-pointer mr-5 position-absolute mt-2 transition ease-in-out delay-50 hover:scale-[1.2]"
                    style="font-size:24px"></i>
                </div>
              </div>
              <div v-if="loading" class="flex justify-center mx-auto">
                <i class="fa-solid fa-wand-magic-sparkles fa-spin text-primary mt-[20px] mb-[20px]"
                  style="font-size: 25px"></i>
              </div>

              <p class="mt-10 mx-auto font-medium text-gray-600 text-[1.2rem]">
                {{ writingResult }}
              </p>
            </li>
          </div>
        </ul>

        <div v-if="isUploading" class="flex justify-center mx-auto">
          <i class="fa-solid fa-wand-magic-sparkles fa-spin text-primary mt-[20px] mb-[20px] items-center justify-center mx-auto"
            style="font-size: 25px"></i>
        </div>
      </div>

      <!-- Tradução das palavras -->
      <div class="translationBox" ref="translationBox">
        <strong>{{ selectedWord }}</strong>
        <p v-if="isWordLoading">
          <i class="fa-solid fa-wand-magic-sparkles fa-spin text-primary mt-[20px] mb-[20px]" style="font-size: 17px"></i>
        </p>
        <p v-else>{{ wordTranslation }}</p>
      </div>

      <Notification v-if="isNotificationVisible" :key="notificationKey" :msg="notificationMessage"
        :type="notificationType" />
    </div>
  </div>

  <div v-if="isLoading">
    <Loading />
  </div>
</template>



<script>
import mixin from "./mixin";
import axios from "axios";
import Notification from "@/components/ui/Notification.vue";
import Loading from "../components/ui/Loading.vue";


export default {
  mixins: [mixin],
  components: {
    Notification,
    Loading,
  },

  props: ["language", "countries", "subject", "original_sentences", "level"],

  data() {
    return {
      translationDirection: 'originalToTranslated', // Controla a direção da tradução

      recorder: null,
      isRecording: false,
      audioUrl: '',
      exercise_questions: [],
      exercise_answers: "",
      selectedAnswers: [],
      numberOfQuestions: 0,
      currentQuestionIndex: 0,
      loading: true,
      score: 0,
      dataGpt: "",
      userIdiom: "",
      learningLanguage: "",
      language: "",
      locale: "",
      originalSentences: [],
      showTranslation: "",
      selectedWord: "",
      wordTranslation: "",
      isWordLoading: false,
      mediaRecorder: null,
      chatGptResponses: [],
      audioPath: [],
      translation: [],
      currentSentenceIndex: 0, // Adicione esta linha
      sentenceSpoken: "",
      pronunciationList: "",
      isLoading: true,
      isShrunk: false,
      isUploading: false,
      writingText: '',
      writingResult: '',
      translatedSentencesArray: [],
      translatedSentences: '',
      sentenceType: '',
      selectedSentence: '',
      translationLanguage: '',
      countries: [
        { name: 'Inglês', code: 'us' },
        { name: 'Espanhol', code: 'es' },
        { name: 'Francês', code: 'fr' },
        { name: 'Italiano', code: 'it' },
        { name: 'Alemão', code: 'de' },
        { name: 'Russo', code: 'ru' },
        { name: 'Português', code: 'br' },
        { name: 'Holandês', code: 'nl' },
      ],

    };
  },

  async created() {
    try {

      const username = localStorage.getItem("username");
      this.loading = true;
      this.isLoading = true;
      
      const contentUUID = this.$route.params.contentUUID;
      
      const responseB = await axios.get(
        `${process.env.VUE_APP_API_URL}/user/u/${username}`
        );
        var list = [responseB.data];
        this.locale = list[0][0]?.user_idiom;
        this.userIdiom = list[0][0]?.user_idiom;
        localStorage.setItem("userIdiom", this.userIdiom)
        this.learningLanguage = list[0][0]?.learning_language;

      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/content/${contentUUID}`
      );
      this.contentList = [response.data];
      this.originalSentences = JSON.parse(
        this.contentList[0]?.original_sentences
      );



      // console.log(" this.originalSentences: ", this.originalSentences);


      // Translate Sentences

      let writingTextsObject = {
        'text': this.originalSentences,
        // 'userWritingPhrase': userWritingPhrase,
        'language': this.userIdiom
      }

      const responseTranslation = await axios.post(`${process.env.VUE_APP_API_URL}/translate`, writingTextsObject);
      this.translatedSentencesArray = [responseTranslation.data]
      this.translatedSentences = JSON.parse(this.translatedSentencesArray[0].translation)

      const sentenceFormData = new FormData();

      // this.sentenceSpoken = this.originalSentences[this.currentSentenceIndex]

      sentenceFormData.append("originalSentences", JSON.stringify(this.originalSentences));
      sentenceFormData.append("contentUUID", contentUUID);

      this.isLoading = false;

      this.language = localStorage.getItem("language");
    } catch (error) {
      console.error("Houve um erro ao buscar os dados:", error);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    toggleTranslationDirection() {
      // Alterna a direção da tradução e automaticamente define o tipo de sentença para exibir
      if (this.translationDirection === 'originalToTranslated') {
        this.translationDirection = 'translatedToOriginal';
        this.setSentenceType('translated'); // Define para mostrar traduzido
      } else {
        this.translationDirection = 'originalToTranslated';
        this.setSentenceType('original'); // Define para mostrar original
      }
    },
    nextSentence() {
      this.writingResult = ''
      this.writingText = ''

      if (this.currentSentenceIndex < this.originalSentences.length - 1) {
        this.currentSentenceIndex++;
      } else {
        // Opcional: resetar para 0 ou outra ação quando chegar ao final do array
        this.currentSentenceIndex = 0; // Ou qualquer outra lógica que você desejar
      }

      // Atualizar a selectedSentence com base na preferência atual
      this.updateSelectedSentence();

      this.$emit("nextSentenceEvent");
    },

    previousSentence() {
      this.writingResult = ''
      this.writingText = ''

      if (this.currentSentenceIndex > 0) {
        this.currentSentenceIndex--;
      } else {
        // Opcional: ir para a última frase se estiver na primeira
        this.currentSentenceIndex = this.originalSentences.length - 1;
      }

      // Atualizar a selectedSentence com base na preferência atual
      this.updateSelectedSentence();
    },

    updateSelectedSentence() {
      // Isso assume que você deseja manter a preferência (original/traduzido) atual ao mudar de frase
      if (this.sentenceType === 'original') {
        this.selectedSentence = this.originalSentences[this.currentSentenceIndex];
      } else {
        this.selectedSentence = this.translatedSentences[this.currentSentenceIndex];
      }
    },

    setSentenceType(type) {
      this.sentenceType = type;
      // Define a sentença selecionada com base no tipo
      if (type === 'original') {
        this.selectedSentence = this.originalSentences[this.currentSentenceIndex];
      } else if (type === 'translated') {
        this.selectedSentence = this.translatedSentences[this.currentSentenceIndex];
      }

    },

    async sendWriting() {
      // Define o idioma baseado no tipo da sentença selecionada
      this.translationLanguage = this.sentenceType === 'original' ? this.userIdiom : this.language;

      console.log("translationLanguage:", this.translationLanguage);

      this.loading = true;
      this.writingResult = '';

      let userWritingPhrase = this.writingText;
      let writingTextsObject = {
        'text': this.selectedSentence,
        'userWritingPhrase': userWritingPhrase,
        'language': this.translationLanguage,
        'idioma': this.userIdiom
      };

      alert
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/writing`, writingTextsObject);
        this.writingResult = response.data.translation;
      } catch (error) {
        console.log("Erro", error);
      } finally {
        this.loading = false;
      }
    },


    async handleButtonClick() {
      // console.log("sentenca: ", this.originalSentences[this.currentSentenceIndex])
      await this.startRecording();

      // Vai para a próxima senten~ca
      if (this.currentSentenceIndex < this.originalSentences.length - 1) {
        // this.currentSentenceIndex++;
      } else {
        console.log("Chegou ao final das sentenças");
      }
    },

    getCountryCode(name) {
      const country = this.countries.find(country => country.name === name);
      return country ? country.code : '';
    },

    getFlagUrl(name) {
      const code = this.getCountryCode(name);
      return `https://flagicons.lipis.dev/flags/4x3/${code}.svg`;
    },

    getFlagUrlSmall(name) {
      let code = name.substr(0, 2)
      if (code === 'pt') code = 'br'
      return `https://flagicons.lipis.dev/flags/4x3/${code}.svg`;
    }

  },

  computed: {
    progressPercentage() {
      return (this.currentSentenceIndex / this.originalSentences.length) * 100;
    },
  },
  watch: {},
};
</script>