<template>
    <div class="flex w-full min-h-screen h-full bg-[#F1DEFF] justify-center backdrop-blur-sm bg-white/80 items-center z-[100] absolute left-0 right-0 top-0">
        <i class="fa-solid fa-wand-magic-sparkles spin-animation text-primary mt-[30px]" style="font-size:40px;"></i>
    </div>
</template>

<script>
    export default {
        data(){
            return {

            }
        },
        created(){
        }
    }
</script>

<style>
@keyframes spin {
    0% { transform: rotate(0deg) scale(1); }
    50% { transform: rotate(360deg) scale(1.3); }
    100% { transform: rotate(1080deg) scale(1); }
}

.spin-animation {
    animation: spin 3s infinite ease-in-out;
}
</style>