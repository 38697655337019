<template>
  <div
    v-if="contentList.length > 0"
    class="bg-[#F4F0F8] flex flex-col h-screen overflow-y-auto"
  >
    <div class="lg:w-3/4 md:w-3/4 sm:w-[90%] mx-auto">
      <!-- Back button -->
      <div class="relative cursor-pointer" @click="navigateToDashboard()">
        <i
          class="pi pi-arrow-left absolute left-5 top-5 hover:scale-[120%] transition ease-in-out delay-50 block"
          style="font-size: 1.5rem"
        ></i>
      </div>

      <!-- Div do Título e do Switch -->
      <div
        class="justify-between items-center p-[10px] sm:p-[40px] rounded-lg mt-[60px]"
      >
        <div class="flex mb-10">
          <img
            v-if="language"
            :alt="language"
            :src="getFlagUrl(language)"
            class="w-[60px] rounded-lg mr-4"
            :class="`mr-2 flag flag-${getCountryCode(language)}`"
          />
          <h1 class="text-3xl text-gray-800 font-bold mt-1">
            {{ capitalizeFirstLetter(subject) }}
          </h1>
        </div>
      </div>

      <!-- Área Principal e Área à Direita -->
      <div class="flex flex-row justify-between mx-auto mb-[200px]">
        <!-- DIV PRINCIPAL DE CONTEÚDO -->
        <div class="flex flex-wrap w-full">
          <!-- OPÇÕES DO CONTEÚDO-->
          <ContentMenu />

          <!-- Área Principal - AQUI VAI O CONTEÚDO-->
          <div
            class="p-[20px] sm:p-[50px] m-0 w-full sm:w-full bg-white rounded-lg"
          >
            <div class="space-y-9">
              <ExercisesView
                :language="language"
                :countries="countries"
                :subject="subject"
                :original_sentences="originalSentences"
                :level="level"
              >
              </ExercisesView>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import mixin from "./mixin";
import InputSwitch from "primevue/inputswitch";
import ChatView from "./ChatView.vue";
import ExercisesView from "./ExercisesView.vue";
import ContentMenu from "./ContentMenu.vue";

export default {
  mixins: [mixin],

  components: {
    InputSwitch,
    ChatView,
    ExercisesView,
    ContentMenu,
  },

  data() {
    return {
      contentList: [],
      language: "",
      locale: "",
      level: "",
      subject: "",
      errorStep1: false,
      errorStep2: false,
      errorStep3: false,
      selectedWord: "",
      wordTranslation: "",
      showTranslation: "",
      originalSentences: "",
      translatedSentences: "",
      sentence: "",
      isSidebarOpen: false,
      checked: false,

      // Tratar data
      dataString: "",
      dataObj: "",
      dia: "",
      mes: "",
      ano: "",

      language: null,
      nome: "renyer",
      countries: [
        { name: "Inglês", code: "us" },
        { name: "Espanhol", code: "es" },
        { name: "Francês", code: "fr" },
        { name: "Italiano", code: "it" },
        { name: "Alemão", code: "de" },
        { name: "Russo", code: "ru" },
        { name: "Português", code: "br" },
        { name: "Holandês", code: "nl" },
      ],
    };
  },
  async mounted() {
    const contentUUID = this.$route.params.contentUUID;

    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/content/${contentUUID}`
      );
      this.contentList = [response.data];

      this.originalSentences = this.contentList[0]?.original_sentences
        .split(".")
        .filter(Boolean)
        .map((s) => s.trim());

      this.subject =
        this.contentList[0]?.subject.charAt(0).toUpperCase() +
        this.contentList[0]?.subject.slice(1);

      this.contentUUID = this.contentList[0].content_uuid;
      this.audioPath = this.contentList[0]?.audio_path;

      this.language = localStorage.getItem("language");
      this.locale = list[0][0]?.user_idiom;

      this.level = this.contentList[0]?.level;

      this.dataString = this.createdAt = this.contentList[0]?.created_at;
      this.dataObj = new Date(this.dataString);

      this.dia = String(this.dataObj.getUTCDate()).padStart(2, "0");
      this.mes = String(this.dataObj.getUTCMonth() + 1).padStart(2, "0"); // Os meses vão de 0 a 11, então adicionamos 1
      this.ano = this.dataObj.getUTCFullYear();
    } catch (error) {
      console.error("Houve um erro ao buscar os dados:", error);
    }
  },

  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },

    navigateToDashboard() {
      this.$router.push({
        path: `/dashboard`,
      });
    },

    navigateToContentList() {
      this.$router.push({
        path: `/content-list`,
      });
    },

    getCountryCode(name) {
      const country = this.countries.find((country) => country.name === name);
      return country ? country.code : "";
    },

    getFlagUrl(name) {
      const code = this.getCountryCode(name);
      return `https://flagicons.lipis.dev/flags/4x3/${code}.svg`;
    },

    /// AUDIO PLAYER

    playPauseAudio() {
      const audioElement = this.$refs.audio;
      if (this.isPlaying) {
        audioElement.pause();
      } else {
        audioElement.play();
      }
      this.isPlaying = !this.isPlaying;

      audioElement.addEventListener("timeupdate", this.updateCurrentTime);
      audioElement.addEventListener("timeupdate", this.updateProgressBar);
    },

    goBackward() {
      const audioElement = this.$refs.audio;
      audioElement.currentTime -= 10;
    },

    goForward() {
      const audioElement = this.$refs.audio;
      audioElement.currentTime += 10;
    },

    updateCurrentTime() {
      const audioElement = this.$refs.audio;
      this.currentTime = audioElement.currentTime;
      this.audioDuration = audioElement.duration;

      const minutes = Math.floor(this.currentTime / 60);
      const seconds = Math.floor(this.currentTime % 60)
        .toString()
        .padStart(2, "0");

      document.getElementById(
        "currentTime"
      ).textContent = `${minutes}:${seconds}`;
    },

    updateProgressBar() {
      const audioElement = this.$refs.audio;
      const progressBar = document.getElementById("progress");

      if (audioElement.duration > 0) {
        const percentage =
          (audioElement.currentTime / audioElement.duration) * 100;
        progressBar.style.width = percentage + "%";
      }
    },

    initAudio() {
      this.$nextTick(() => {
        const audioElement = this.$refs.audio;

        if (audioElement) {
          audioElement.addEventListener("loadedmetadata", () => {
            this.audioDuration = audioElement.duration;
            const minutes = Math.floor(this.audioDuration / 60);
            const seconds = Math.floor(this.audioDuration % 60)
              .toString()
              .padStart(2, "0");
            document.getElementById(
              "totalTime"
            ).textContent = `${minutes}:${seconds}`;
          });
        }
      });
    },
  },
  watch: {
    showTranslation(newValue) {
      if (newValue && !this.translationRequested) {
        this.requestTranslation();
      }
    },

    audioPath: {
      immediate: true,
      handler() {
        this.initAudio();
      },
    },
  },
};
</script>

<style scoped>
</style>
  