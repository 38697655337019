<template>
  <div
    class="flex flex-col md:flex-row items-start md:items-center justify-between w-full md:w-4/5 mt-10 mb-[50px]"
  >
    <div class="flex flex-col">
      <h2 class="text-4xl font-bold text-gray-700">
        {{ $t("SpacedRepetitions.memorizationTitle") }}
      </h2>
      <p class="font-medium text-gray-500 mt-4">
        {{ $t("SpacedRepetitions.reviewList") }}
      </p>
    </div>
  </div>

  <div class="flex flex-wrap">
    <!-- HOJE -->
    <div
      class="mb-10 text-gray-400 pt-8 mr-10 bg-white hover:cursor-pointer rounded-xl p-6 min-w-full sm:min-w-[280px] sm:w-[280px]"
    >
      <h3 class="text-2xl font-medium text-gray-800 mb-10">
        {{ $t("SpacedRepetitions.today") }}
      </h3>
      <ul v-if="revisionsToday && revisionsToday.length > 0">
        <li
          v-for="(revision, index) in revisionsToday"
          :key="index"
          @click="navigateToContent(revision.content_uuid)"
          class="text-white text-lg font-medium bg-[#33B679] p-2 pl-4 rounded-[6px] mb-4 transition ease-in-out delay-50 hover:scale-[1.06] hover:bg-primary hover:text-white"
        >
          {{ capitalizeFirstLetter(revision.subject) }}
        </li>
      </ul>
      <ul v-else>
        <li>{{ $t("SpacedRepetitions.noRevisions") }}</li>
      </ul>
    </div>

    <!-- AMANHÃ -->
    <div
      class="mb-10 text-gray-400 pt-8 mr-10 bg-[white] hover:cursor-pointer rounded-xl p-6 min-w-full sm:min-w-[280px] sm:w-[280px]"
    >
      <h3 class="text-2xl font-medium text-gray-800 mb-10">
        {{ $t("SpacedRepetitions.tomorrow") }}
      </h3>
      <ul v-if="revisionsTomorrow && revisionsTomorrow.length > 0">
        <li
          v-for="(revision, index) in revisionsTomorrow"
          :key="index"
          @click="navigateToContent(revision.content_uuid)"
          class="text-gray-700 text-lg font-medium font-medium bg-[#F1DEFF] p-2 pl-4 rounded-[6px] mb-4 transition ease-in-out delay-50 hover:scale-[1.06] hover:bg-primary hover:text-white"
        >
          {{ capitalizeFirstLetter(revision.subject) }}
        </li>
      </ul>
      <ul v-else>
        <li>{{ $t("SpacedRepetitions.noRevisions") }}</li>
      </ul>
    </div>

    <!-- DEPOIS DE AMANHÃ -->
    <div
      class="mb-10 text-gray-400 pt-8 mr-10 bg-white hover:cursor-pointer rounded-xl p-6 min-w-full sm:min-w-[280px] sm:w-[280px]"
    >
      <h3 class="text-2xl font-medium text-gray-800 mb-10">
        {{ $t("SpacedRepetitions.dayAfterTomorrow") }}
      </h3>
      <ul v-if="revisionsAfterTomorrow && revisionsAfterTomorrow.length > 0">
        <li
          v-for="(revision, index) in revisionsAfterTomorrow"
          :key="index"
          @click="navigateToContent(revision.content_uuid)"
          class="text-gray-700 text-lg font-medium bg-[#F1DEFF] p-2 pl-4 rounded-[6px] mb-4 transition ease-in-out delay-50 hover:scale-[1.06] hover:bg-primary hover:text-white"
        >
          {{ capitalizeFirstLetter(revision.subject) }}
        </li>
      </ul>
      <ul v-else>
        <li>{{ $t("SpacedRepetitions.noRevisions") }}</li>
      </ul>
    </div>
  </div>

  <div
    class="flex w-full md:w-5/5 justify-end mt-5 text-right cursor-pointer hover:text-primary transition ease-in-out delay-50 hover:scale-[1.01]"
  >
    <p
      @click="navigateToCalendar()"
      class="mr-2 font-medium text-gray-600 text-lg hover:text-primary"
    >
      {{ $t("SpacedRepetitions.viewFullStudyCalendar") }}
    </p>
    <i class="pi pi-arrow-right mt-1"></i>
  </div>
</template>

<script>
import axios from "axios";
import mixin from "./mixin";

export default {
  mixins: [mixin],

  props: [
    //   'userId',
    //   'countries',
    //   'subject',
    //   'original_sentences',
    //   'level'
  ],

  data() {
    return {
      userId: "",
      today: "",
      itemDate: "",
      revisionsToday: [],
      revisionsTomorrow: [],
      revisionsAfterTomorrow: [],
      locale: "",
      learningLanguage: "",
    };
  },
  computed: {},
  async mounted() {
    this.fetchRevisions();
  },
  methods: {
    async fetchRevisions() {
      const username = localStorage.getItem("username");
      this.username = username;

      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/user/u/${username}`
      );
      this.contentList = [response.data];

      this.userId = this.contentList[0][0]?.user_id;
      this.locale = this.contentList[0][0]?.user_idiom;
      this.learningLanguage = this.contentList[0][0]?.learning_language;

      // this.loadTranslations();

      try {
        const responseToday = await axios.get(
          `${process.env.VUE_APP_API_URL}/revision/today/${this.userId}/${this.learningLanguage}`
        );
        const responseTomorrow = await axios.get(
          `${process.env.VUE_APP_API_URL}/revision/tomorrow/${this.userId}/${this.learningLanguage}`
        );
        const responseAfterTomorrow = await axios.get(
          `${process.env.VUE_APP_API_URL}/revision/day-after-tomorrow/${this.userId}/${this.learningLanguage}`
        );
        this.revisionsToday = responseToday.data;
        this.revisionsTomorrow = responseTomorrow.data;
        this.revisionsAfterTomorrow = responseAfterTomorrow.data;

        console.log(
          this.userId,
          responseAfterTomorrow,
          "Idioma: ",
          this.learningLanguage
        );
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
      }
    },

    capitalizeFirstLetter(string) {
      let words = string.split(" ");
      let firstWords = words.slice(0, 6);
      let phrase = firstWords.join(" ");

      if (words.length > 6) {
        phrase += "...";
      }

      return phrase.charAt(0).toUpperCase() + phrase.slice(1);
    },
  },
};
</script>