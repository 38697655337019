<template>
	<div class="bg-[#F4F0F8] flex flex-col h-screen overflow-y-auto">
		<div class="lg:w-3/4 md:w-3/4 sm:w-[90%] mx-auto">
			<!-- Back button -->
			<div class="relative cursor-pointer " @click="navigateToDashboard()">
				<i class="pi pi-arrow-left absolute left-5 top-5 hover:scale-[120%] transition ease-in-out delay-50	 block"
					style="font-size: 1.5rem"></i>
			</div>

			<!-- Div do Título e do Switch -->
			<div class=" justify-between items-center p-[10px] sm:p-[40px] rounded-lg  mt-[60px]">
				<div class="flex mb-10">
					<img v-if="language" :alt="language" :src="getFlagUrl(language)" class="w-[60px] rounded-lg mr-4"
						:class="`mr-2 flag flag-${getCountryCode(language)}`" />
					<h1 class="text-3xl text-gray-800 font-bold mt-1">Speaking</h1>
				</div>
			</div>

			<!-- Área Principal e Área à Direita -->
			<div class="flex flex-row justify-center mx-auto mb-[200px]">

				<!--Div principal de conteúdo -->
				<div class="flex flex-wrap w-full">

					<!-- Área Principal -->
					<div class=" p-[20px] sm:p-[50px] m-0  w-full rounded-lg h-full mb-[100px]">
						<div class="space-y-9 bg-white p-10 rounded-lg">

							<div v-if="transcriptionResults.length < 1 && !isRecording && !loading">
								<p class="font-medium text-gray-500 text-lg">{{ $t('speaking.intro') }}</p>
							</div>

							<div v-for="(result, index) in transcriptionResults" :key="`item-${index}`">
								<div class="flex justify-end mb-10">
									<p
										class="font-medium text-primary text-[1.1rem] text-right bg-white border border-primary px-4 py-4 rounded-lg">
										{{ result }}</p>
								</div>

								<div v-if="chatGptResponses.length > index">
									<div
										class="py-4 px-4 rounded-lg max-w-[70%] font-medium bg-[#F1DEFF] flex justify-between">
										<div>
											<p
												class="text-gray-600 text-[1.2rem] font-medium leading-sete mb-2 leading-loose">
												{{
													chatGptResponses[index]
												}}</p>
											<p class="text-gray-500  text-[0.9rem]">{{ translation[index] }}</p>

											<div v-if="!loading"
												class="flex fixed bottom-[130px] left-1/2 transform -translate-x-1/2 z-50 bg-primary p-4 rounded-xl border border-primary">
												<p class="text-white  text-[1.1rem] text-center items-center justify-center ">
													<i class="fa-solid fa-lightbulb text-white mr-2"></i>
													{{ resultSuggestion }}
												</p>
											</div>

										</div>
										<div class="flex justify-end items-">
											<audio :src="`${audioPath[index]}`" ref="audioPlayers"
												@loadeddata="playAudio(index)"></audio>
											<button class="rounded-full w-10 h-10 bg-primary text-white p-0 ml-1
                            transition ease-in-out delay-50 hover:scale-[1.1] 
                            hover:cursor-pointer hover:transform" @click="playAudio(index)">
												<i
													:class="playingAudioIndex === index ? 'fa-solid fa-pause' : 'fa-solid fa-play'"></i>
											</button>
										</div>
									</div>
								</div>
							</div>

							<div v-if="loading" class="flex justify-center mx-auto">
								<i class="fa-solid fa-wand-magic-sparkles fa-spin text-primary mt-[20px] mb-[20px]"
									style="font-size:25px"></i>
							</div>
						</div>
					</div>

					<div class="fixed bottom-10 left-1/2 transform -translate-x-1/2 z-50">
						<div class="space-y-9">
							<button @click="isRecording ? stopRecording() : startRecording()"
								:class="`rounded-full w-20 h-20 bg-primary text-white p-0 transition ease-in-out delay-50 hover:cursor-pointer ${isRecording ? 'animate-pulse' : 'hover:scale-[1.1]'}`">
								<i :class="isRecording ? 'fa-solid fa-circle-stop' : 'fa-solid fa-microphone-lines'"
									style="font-size: 40px;"></i>
							</button>
						</div>
					</div>

				</div>
			</div>
			<Notification v-if="isNotificationVisible" :key="notificationKey" :msg="notificationMessage"
				:type="notificationType" />
		</div>
	</div>
	<div v-if="isLoading">
		<Loading />
	</div>
</template>


<script>
import mixin from '../components/mixin';
import axios from 'axios';
import Notification from '../components/ui/Notification.vue';
import Loading from '../components/ui/Loading.vue';
import RecordRTC from 'recordrtc';

export default {
	mixins: [mixin],
	components: {
		Notification,
		Loading,
	},

	data() {
		return {
			recorder: null,
			isRecording: false,
			audioUrl: '',
			mediaRecorder: null,
			audioChunks: [],
			audioBlob: null,
			transcriptionResults: [],
			chatGptResponses: [],
			audioPath: [],
			translation: [],
			language: '',
			userIdiom: '',
			username: '',
			loading: 0,
			countries: [
				{ name: 'Inglês', code: 'us' },
				{ name: 'Espanhol', code: 'es' },
				{ name: 'Francês', code: 'fr' },
				{ name: 'Italiano', code: 'it' },
				{ name: 'Alemão', code: 'de' },
				{ name: 'Russo', code: 'ru' },
				{ name: 'Português', code: 'br' },
				{ name: 'Holandês', code: 'nl' },
			],
			playingAudioIndex: null, // Adicionado para rastrear o áudio em reprodução
			locale: '',
			isLoading: false,
			resultSuggestion: ''


		};
	},
	async mounted() {
		this.isLoading = true;

		this.userIdiom = localStorage.getItem('userIdiom');
		localStorage.setItem('language', localStorage.getItem('learningLanguage'));

		const username = localStorage.getItem('username');
		this.username = username;

		const responseB = await axios.get(`${process.env.VUE_APP_API_URL}/user/u/${username}`);
		var list = [responseB.data];
		this.language = list[0][0]?.learning_language;
		this.locale = list[0][0]?.user_idiom;
		this.userIdiom = list[0][0]?.user_idiom;
		localStorage.setItem('userIdiom', this.userIdiom);

		this.isLoading = false;
	},
	methods: {
		async startRecording() {
			try {
				const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
				const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

				const options = {
					type: 'audio',
					numberOfAudioChannels: 2,
					checkForInactiveTracks: true,
					bufferSize: 16384,
				};

				if (isSafari) {
					options.sampleRate = 44100;
					options.bufferSize = 4096;
					options.mimeType = 'audio/wav';
				}

				if (this.recorder) {
					this.recorder.destroy();
					this.recorder = null;
				}

				this.recorder = new RecordRTC(stream, options);

				this.stream = stream;
				this.recorder.startRecording();
				this.isRecording = true;
			} catch (error) {
				console.error('Falha ao iniciar a gravação: ', error);
				this.showNotification(
					`No microphone detected. Please, allow Lanna to use your microphone`,
					'error',
				);
			}
		},


		async stopRecording() {
			if (!this.recorder) return;

			this.recorder.stopRecording(() => {
				let blob = this.recorder.getBlob();
				this.audioUrl = URL.createObjectURL(blob);

				if (this.stream) {
					this.stream.getTracks().forEach(track => track.stop());
				}

				this.uploadAudio(blob);

				this.stream = null;
				this.recorder = null;
				this.isRecording = false;
			});
		},


		async uploadAudio(blob) {
			const formData = new FormData();


			let fileExtension = 'wav';
			if (blob.type === 'audio/mp4') fileExtension = 'm4a';
			else if (blob.type === 'audio/webm') fileExtension = 'webm';

			formData.append('audio', blob, `audio.${fileExtension}`);
			formData.append('userIdiom', this.userIdiom);
			formData.append('learningLanguage', this.language);

			try {
				this.loading = 1;
				const response = await axios.post(`${process.env.VUE_APP_API_URL}/whisper`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				});

				if (response.status === 200) {
					const { data } = response;
					this.transcriptionResults.push(data.text);
					this.chatGptResponses.push(data.chatGptTextResponse);
					this.audioPath.push(data.audioPath);
					this.translation.push(data.translation);
					this.resultSuggestion = data.resultSuggestion;
				} else {
					console.error('Resposta do servidor não é um JSON válido');
				}
				this.loading = 0;
			} catch (error) {
				console.error('Erro ao enviar o áudio: ', error);
				this.loading = 0;
			}
		},

		getCountryCode(name) {
			const country = this.countries.find(country => country.name === name);
			return country ? country.code : '';
		},

		getFlagUrl(name) {
			const code = this.getCountryCode(name);
			return `https://flagicons.lipis.dev/flags/4x3/${code}.svg`;
		},
		playAudio(index) {
			const audioPlayer = this.$refs.audioPlayers[index];
			if (!audioPlayer) return;

			audioPlayer.onended = () => this.handleAudioEnd();

			// Se o áudio selecionado já está tocando, pause
			if (this.playingAudioIndex === index) {
				audioPlayer.pause();
				this.playingAudioIndex = null;
			} else {
				// Se outro áudio está tocando, pare
				if (this.playingAudioIndex !== null) {
					const playingAudioPlayer = this.$refs.audioPlayers[this.playingAudioIndex];
					if (playingAudioPlayer) {
						playingAudioPlayer.pause();
						playingAudioPlayer.currentTime = 0; // Opcional: resetar o áudio que estava tocando
					}
				}

				// Toca o áudio selecionado
				audioPlayer.play().catch(e => {
					console.error('Erro ao reproduzir áudio automaticamente:', e);
				});
				this.playingAudioIndex = index; // Atualiza o índice do áudio em reprodução
			}
		},
		handleAudioEnd() {
			// Chamado quando o áudio termina de tocar
			this.playingAudioIndex = null;
		},
	},
};
</script>
<style scoped>
@keyframes pulseAnimation {

	0%,
	100% {
		transform: scale(1.0);
	}

	50% {
		transform: scale(1.25);
	}
}

.animate-pulse {
	animation: pulseAnimation 2s infinite;
}
</style>