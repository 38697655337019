<template>
	<div class="flex flex-col mx-auto bg-[#F4F0F8]">
		<div class="p-5 items-center min-h-screen h-full w-full sm:w-[500px] mx-auto">
			<div>
				<img src="https://framerusercontent.com/images/ROWCGCoRs148xJnbvIq4SgM2oM.png" alt="Logo"
					class="mt-[50px] m-10 w-[200px] mx-auto" />
			</div>
			<!-- Box de Login -->
			<div class="w-full p-10 bg-white rounded-lg">
				<h1 class="mb-6 text-3xl text-left text-gray-600 font-medium">
					{{ $t('login.Login') }}
				</h1>

				<form @submit.prevent="loginUser" class="space-y-4">
					<div>
						<span class="text-sm text-gray-400 block mb-2">{{
						$t('login.Email')
					}}</span>
						<input
							class="bg-white w-full border border-gray-300 p-4 text-gray-800 transition ease-in-out delay-50 hover:bg-white hover:text-gray-800 rounded-xl mb-4"
							type="text" v-model="email" :placeholder="$t('login.Email')" required />
					</div>
					<div>
						<span class="text-sm text-gray-400 block mb-2">{{
						$t('login.Password')
					}}</span>
						<input
							class="bg-white w-full border border-gray-300 p-4 text-gray-800 transition ease-in-out delay-50 hover:bg-white hover:text-gray-800 rounded-xl mb-4"
							type="password" v-model="password" :placeholder="$t('login.Password')" required />
					</div>
					<div class="flex items-center justify-between">
						<label class="inline-flex items-center">
							<input type="checkbox" class="text-purple-600 form-checkbox" v-model="savePassword" />
							<span class="ml-2 text-sm text-gray-600">{{
						$t('login.SavePassword')
					}}</span>
						</label>
					</div>
					<div class="error-message" v-if="error">
						<p class="text-sm text-red-500">
							{{ $t('login.errorInvalidInformation') }}
						</p>
					</div>
					<button
						class="w-full rounded-xl p-4 font-bold text-white bg-primary rounded hover:bg-purple-700 focus:outline-none focus:shadow-outline"
						type="submit">
						{{ $t('login.Login') }}
						<i v-if="loadingLogin" class="fa-solid fa-wand-magic-sparkles fa-spin text-white ml-2"
							style="font-size:25px"></i>
					</button>
					<div>
						<hr class="w-full mt-8 mb-6 text-gray-300" />
					</div>
					<div class="mt-4 text-center flex items-center">
						<p class="text-left font-medium text-gray-600 w-[300px]">
							{{ $t('login.DoesntHaveAnAccount') }}
						</p>
						<a href="/register"
							class="w-[150px] items-right justify-right px-4 py-2 mt-2 font-medium text-gray-600 bg-white border border-gray-300 rounded-xl rounded hover:bg-primary hover:text-white focus:outline-none focus:shadow-outline">{{
						$t('login.Register') }}</a>
					</div>
				</form>
			</div>

			<Notification v-if="isNotificationVisible" :key="notificationKey" :msg="notificationMessage"
				:type="notificationType" />
		</div>
		<div v-if="isLoading">
			<Loading />
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import mixin from './mixin';
import Notification from './ui/Notification.vue';
import Loading from "../components/ui/Loading.vue";


export default {
	mixins: [mixin],

	components: {
		Notification, Loading
	},

	data() {
		return {
			username: null,
			email: null,
			password: null,
			savePassword: false,
			error: false,
			notificationMessage: null,
			notificationType: 'success',
			isNotificationVisible: false,
			notificationKey: 0, // Nova propriedade para chave única
			notificationTimeout: null, // Para armazenar o ID do timeout
			locale: (navigator && navigator.language) || 'en-US',
			isLoading: false,
			loadingLogin: false,
		};
	},
	methods: {
		loginUser() {
			console.log(this.email, this.password);
			this.loadingLogin = true

			axios
				.post(`${process.env.VUE_APP_API_URL}/login`, {
					email: this.email,
					password: this.password,
				})
				.then((response) => {

					if (response.data.success) {
						localStorage.setItem('username', response.data.username);
						localStorage.setItem('userId', response.data.userId);
						localStorage.setItem('lanna_t', response.data.lanna_t);
						localStorage.setItem('userIdiom', response.data.userIdiom);
						localStorage.setItem('learningLanguage', response.data.learningLanguage);
						localStorage.setItem('language', localStorage.getItem('learningLanguage'));

						this.loadingLogin = false

						this.$router.push(`/dashboard/`);
					} else {
						// alert(`O`);
					}
				})
				.catch((error) => {
					this.loadingLogin = false
					this.showNotification('notifications.wrongEmailOrPassword', 'error');
				});
		},
	},
	async created() {
		this.isLoading = true;
		// await this.loadTranslations();
		this.isLoading = false;
	},
};
</script>

<style scoped></style>
