<template>
    <div class="h-screen flex fade-in">
      <div @click="navigateToContentList()" class="relative cursor-pointer" >
            <i class="text-black pi pi-arrow-left absolute left-5 top-5 hover:scale-[120%] transition ease-in-out delay-50	 block" style="font-size: 1.5rem"></i>
        </div>    <!-- Coluna da Esquerda -->
      <!-- <Sidebar :username="username"></Sidebar> -->
      <!-- Coluna da Direita -->
      <div class="flex-1 p-10 sm:mt-0 bg-[#F4F0F8] flex flex-col overflow-y-auto ">
        <!-- Parte de Cima --> 
 
        <!-- Parte de Baixo -->
        <div class="flex-1 overflow-visible w-full	mt-10">
          <!-- Header com Título, Subtítulo  -->
          <div class="flex flex-col md:flex-row items-start md:items-center justify-between w-full md:w-4/5 mb-[20px]">
            <div class="flex flex-col">
              <h2 v-if="isDirectToPay" class="text-4xl font-bold text-gray-700">Continue usando o Lanna!</h2>
              <h2 v-else class="text-4xl font-bold text-gray-700">Você atingiu o limite de materiais de estudos gratuítos</h2>
              <p class="font-medium text-gray-500 mt-4">Para continuar usando o Lanna, escolha um dos planos abaixo:</p>
            </div>
          </div>
          <div class="rounded-lg p-6 w-full">
              <!-- <header class="flex justify-between items-center p-5 ">
                  <h2 class="text-2xl font-medium  text-gray-600">
                    Dados da conta
                  </h2>
              </header> -->
          </div>
        <!-- <router-link :to="{ path: '/paywall', query: { isDirectToPay : true } }" tag="button" class="bg-white text-black rounded-lg p-2 pl-8 pr-8 font-medium hover:bg-primary hover:text-white transition ease-in-out delay-50 hover:scale-[1.06]">
            Assinar
        </router-link>
         -->


          <!-- Pricing Plan Selection Box -->
            <div class="flex flex-wrap justify-center gap-10 ">

                <!-- Starter Plan -->
              <router-link :to="{path: '/payment', query : { plan: 'yearly'}}" class="max-w-sm rounded-lg border border-primary border-4 bg-white w-full transition ease-in-out delay-50 hover:scale-110 ">
                <div class="p-10">
                  <h3 class="text-2xl font-bold leading-none text-gray-900">Anual</h3>
                  <h2 class="mt-2 mb-5 text-4xl font-bold text-gray-900">R$29 <span class="text-lg font-normal text-gray-500">por mês por 12 meses</span></h2>
                  <a href="#" class="inline-block px-6 py-2 text-lg font-medium text-center text-white bg-primary rounded-xl w-full">25% de desconto</a>
                  <p class="mt-5 text-gray-500">Pague em 12x</p>
                  <p class="text-gray-500">R$348 por um ano de acesso.</p>
                </div>
                <div class="p-10 border-t border-gray-200">
                  <p class="text-gray-600">Components</p>
                  <ul class="my-4 space-y-3">

                    <li class="flex items-center space-x-2">
                      <i class="pi pi-check text-primary"></i>
                      <span>Até 30 materiais por mês</span>
                    </li>

                    <li class="flex items-center space-x-2">
                      <i class="pi pi-check text-primary"></i>
                      <span>Chat com áudio</span>
                    </li>

                    <li class="flex items-center space-x-2">
                      <i class="pi pi-check text-primary"></i>
                    <span>Exercícios e correções</span>
                    </li>
                  </ul>
                </div>
              </router-link>

              <!-- Mensal Plan -->
              <router-link :to="{path: '/payment', query : { plan: 'monthly'}}" class="max-w-sm rounded-lg  bg-white w-full  transition ease-in-out delay-50 hover:scale-110 ">
                <div class="p-10">
                  <h3 class="text-2xl font-bold leading-none text-gray-900">Mensal</h3>
                  <h2 class="mt-2 mb-5 text-4xl font-bold text-gray-900">R$39 <span class="text-lg font-normal text-gray-500">por mês</span></h2>
                  <a href="#" class="inline-block px-6 py-2 text-lg font-medium text-center text-white bg-primary rounded-xl w-full">25% de desconto</a>
                  <p class="mt-5 text-gray-500">Cobrança mensal</p>
                  <p class="text-gray-500">R$468 por um ano de acesso.</p>
                </div>
                <div class="p-10 border-t border-gray-200">
                  <p class="text-gray-600">Components</p>
                  <ul class="my-4 space-y-3">

                    <li class="flex items-center space-x-2">
                      <i class="pi pi-check text-primary"></i>
                      <span>Até 30 materiais por mês</span>
                    </li>

                    <li class="flex items-center space-x-2">
                      <i class="pi pi-check text-primary"></i>
                      <span>Chat com áudio</span>
                    </li>

                    <li class="flex items-center space-x-2">
                      <i class="pi pi-check text-primary"></i>
                    <span>Exercícios e correções</span>
                    </li>
                  </ul>
                </div>
              </router-link>
                  
                <!-- Botão de Salvar -->
                <!-- <button  class="w-[250px] h-[60px] bg-primary text-white font-medium text-lg mt-[50px] mb-[20px] ml-[10px] rounded-2xl">Salvar</button> -->
            </div>
          </div>
        </div>
      </div>
  </template>

<script>
    import axios from "axios";
    import mixin from '../components/mixin';
    import Sidebar from '../components/Sidebar.vue';
    import PayAlert from "../components/PayAlert.vue";
    import InputText from 'primevue/inputtext';
    import Button from 'primevue/button';


    export default {
        mixins: [mixin],

        components:{
            Sidebar, PayAlert, InputText, Button
          },
          
        data() {
            return {
                username: '',    
                userEmail: '',
                user_id: '',
                isPaid: 0,
                inTrial: true,
                learningLanguage: '',
                userIdiom: '' ,
                languageName: '',
                languageMap: {
                  'en-US': 'Inglês',
                  'es-ES': 'Espanhol',
                  'fr-FR': 'Francês',
                  'it-IT': 'Italiano',
                  'de-DE': 'Alemão',
                  'ru-RU': 'Russo',
                  'pt-PT': 'Português',
                  'pt-BR': 'Português',
                  'nl-NL': 'Holandês'
                },
                learningLanguageCode: '',
                isDirectToPay: false
        
            };
        },

        async mounted(){
          const username = localStorage.getItem("username");
          const user_id = localStorage.getItem("userId");
          
          this.isDirectToPay = this.$route.query.isDirectToPay
          // alert(this.isDirectToPay)
          this.username = username
          this.user_id = user_id

          const responseB = await axios.get(`${process.env.VUE_APP_API_URL}/user/u/${username}`);
          var list = [responseB.data];
          this.isPaid = list[0][0]?.is_paid;
          this.learningLanguage = list[0][0]?.learning_language;
          this.userIdiom = list[0][0]?.user_idiom;
          this.userEmail = list[0][0]?.email;
          this.user_id = list[0][0]?.user_id;
          localStorage.setItem("userId", this.user_id ) 

          const languageName = list[0][0]?.learning_language; // Nome completo do idioma
          this.learningLanguageCode = this.getCodeFromLanguageName(languageName);

          const responseTrial = await axios.get(`${process.env.VUE_APP_API_URL}/check-trial/${username}`);
          var list = [responseTrial.data];
          this.inTrial = list[0]?.inTrial;
          console.log("Está em trial", this.inTrial)
        },

        computed: {
          languageName(){
            return this.getLanguageName(this.userIdiom)
          }
        },

        methods: {
          getCodeFromLanguageName(name) {
            return Object.keys(this.languageMap).find(key => this.languageMap[key] === name);
        },

          getLanguageName(isoCode) {
            const languageMap = {
              'en-US': 'Inglês',
              'es-ES': 'Espanhol',
              'fr-FR': 'Francês',
              'it-IT': 'Italiano',
              'de-DE': 'Alemão',
              'ru-RU': 'Russo',
              'pt-PT': 'Português',
              'pt-BR': 'Português',
              'nl-NL': 'Holandês'
            };

            return languageMap[isoCode] || 'Código de idioma desconhecido';
          },

          async saveUserSettings() {
            const userSettings = {
              // username: this.username,
              userEmail: this.userEmail,
              currentPassword: this.currentPassword,
              newPassword: this.newPassword,
              learningLanguage: this.languageMap[this.learningLanguageCode], // Nome completo do idioma
              userIdiom: this.userIdiom,
            };

            try {
              const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/update/${this.username}`, userSettings);
              if (response.status === 200) {
                alert(`Sucesso`)
              } else {
                throw new Error('Falha ao salvar as configurações');
              }
            } catch (error) {
              console.error(error);
              // Tratar erro
            }
          },



        },

      
          
        watch: {
          userIdiom(newVal, oldVal) {
            if (newVal !== oldVal) {
              // this.updateUserLanguage();
            }
          },          
        }
    }
</script>
