<template>
  <div>
    <div v-if="loading" class="flex justify-center mx-auto">
      <i
        class="fa-solid fa-wand-magic-sparkles fa-spin text-primary mt-[20px] mb-[20px]"
        style="font-size: 25px"
      ></i>
    </div>
    <div v-else class="flex flex-col items-center text-center">
      <div
        class="w-full bg-[#F1DEFF] h-4 mt-4 rounded-lg overflow-hidden mb-10"
      >
        <div
          :style="{ width: progressPercentage + '%' }"
          class="bg-primary h-full"
        ></div>
      </div>
      <div
        v-if="currentQuestionIndex < exercise_questions.length"
        class="w-full"
      >
        <ul class="mb-20 justify-items-center">
          <!-- <p class="text-2xl font-bold text-gray-700 mb-2"> -->
          <p
            @click="wordClicked($event)"
            class="mx-auto font-medium text-primary text-[1.7rem] cursor-pointer"
          >
            {{ exercise_questions[currentQuestionIndex].pergunta }}
          </p>
          <p class="mb-6">
            {{ exercise_questions[currentQuestionIndex].traducao_pergunta }}
          </p>
          <div class="mx-auto my-0">
            <li
              v-for="(resposta, index) in exercise_questions[
                currentQuestionIndex
              ].respostas"
              :key="index"
              @click="
                selectAnswer(index, exercise_questions[currentQuestionIndex])
              "
              :class="
                getAnswerClass(index, exercise_questions[currentQuestionIndex])
              "
              class="flex w-full py-4 px-4 border-2 border-primary text-black rounded-lg mr-0 mt-2 mb-1 cursor-pointer transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-105 duration-300"
            >
              <div class="w-full space-y-3">
                <p class="font-bold text-lg">{{ resposta.resposta }}</p>
                <p class="text-sm">{{ resposta.traducao_resposta }}</p>
              </div>
            </li>
          </div>
        </ul>
      </div>
      <div v-else>
        <p class="text-xl">{{ score }}/{{ numberOfQuestions }}</p>
      </div>
    </div>
  </div>
  <div v-if="isLoading">
    <Loading />
  </div>

  <!-- Caixa de tradução -->
  <div class="translationBox" ref="translationBox">
    <strong>{{ selectedWord }}</strong>
    <p v-if="isWordLoading">
      <i
        class="fa-solid fa-wand-magic-sparkles fa-spin text-primary mt-[20px] mb-[20px]"
        style="font-size: 17px"
      ></i>
    </p>
    <p v-else>{{ wordTranslation }}</p>
  </div>
</template>



<script>
import mixin from "./mixin";
import axios from "axios";
import Loading from "../components/ui/Loading.vue";

export default {
  mixins: [mixin],
  components: {
    Loading,
  },

  props: ["language", "countries", "subject", "original_sentences", "level"],

  data() {
    return {
      exercise_questions: [],
      exercise_answers: "",
      selectedAnswers: [],
      originalSentences: [],
      numberOfQuestions: 0,
      currentQuestionIndex: 0,
      loading: true,
      score: 0,
      dataGpt: "",
      userIdiom: "",
      learningLanguage: "",
      language: "",
      locale: "",
      isLoading: "",
      isWordLoading: false,
      wordTranslation: "",
      showTranslation: "",
      selectedWord: "",
      isWordLoading: false,
    };
  },

  async created() {
    try {
      this.isLoading = true;
      const username = localStorage.getItem("username");
      this.loading = true;
      const contentUUID = this.$route.params.contentUUID;

      // Busca informações do usuário
      const responseB = await axios.get(
        `${process.env.VUE_APP_API_URL}/user/u/${username}`
      );
      var list = [responseB.data];
      this.locale = list[0][0]?.user_idiom;
      this.userIdiom = list[0][0]?.user_idiom;
      this.learningLanguage = list[0][0]?.learning_language;
      console.log("Idioma: ", this.userIdiom, this.learningLanguage);

      // this.loadTranslations();

      // Prepara o corpo da requisição
      const bodyContent = {
        learningLanguage: this.learningLanguage,
        userIdiom: this.userIdiom,
        level: this.level,
        subject: this.subject,
        original_sentences: this.original_sentences,
      };

      // Envia o conteúdo via POST
      await axios.post(
        `${process.env.VUE_APP_API_URL}/exercises/${contentUUID}`,
        bodyContent
      );

      // Tenta buscar e analisar a lista de exercícios até 3 vezes
      let attempts = 0;
      let fetched = false;
      while (!fetched && attempts < 3) {
        try {
          const response = await axios.get(
            `${process.env.VUE_APP_API_URL}/exercises/${contentUUID}`
          );
          const exerciseList = JSON.parse(response.data.exerciseList.exercise);
          console.log(exerciseList);
          this.exercise_questions = JSON.parse(exerciseList).perguntas;
          this.numberOfQuestions = this.exercise_questions.length;
          this.dataGpt = response;
          fetched = true; // Dados foram buscados e parseados com sucesso
          this.isLoading = false;
        } catch (error) {
          attempts++;
          if (attempts >= 3) {
            console.error(
              "Não foi possível buscar ou analisar a lista de exercícios. Tente novamente mais tarde."
            );
          }
        }
      }

      this.language = localStorage.getItem("language");
    } catch (error) {
      console.error("Houve um erro ao buscar os dados:", error);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    selectAnswer(answerIndex, question) {
      // Armazene a resposta selecionada pelo usuário
      this.selectedAnswers[this.currentQuestionIndex] = answerIndex;

      // console.log(question)
      // console.log(question.respostas[answerIndex].opcao)
      // console.log( question.resposta_correta)

      if (question.respostas[answerIndex].opcao === question.resposta_correta) {
        // console.log('Resposta correta')
        this.score++;
        //  console.log(this.score)
      }

      // Avance para a próxima pergunta se ainda houver perguntas restantes.
      if (this.currentQuestionIndex < this.exercise_questions.length) {
        // Faça uma pequena pausa antes de avançar para a próxima pergunta para que o usuário possa ver o feedback visual
        setTimeout(() => {
          this.currentQuestionIndex += 1;
        }, 2000); // Ajuste este tempo conforme necessário
      } else {
        // Lógica para quando todas as perguntas foram respondidas
        // Ex: Mostrar resultados, navegar para uma nova página, etc.
      }
    },

    getAnswerClass(index, question) {
      if (index === this.selectedAnswers[this.currentQuestionIndex]) {
        console.log(question.respostas[index].opcao, question.resposta_correta);

        return {
          "bg-green-500":
            question.respostas[index].opcao === question.resposta_correta,
          "bg-red-500":
            question.respostas[index].opcao !== question.resposta_correta,
          "border-green-500":
            question.respostas[index].opcao === question.resposta_correta &&
            index !== this.selectedAnswers[this.currentQuestionIndex],
          "text-white": true,
        };
      }
      return {
        "hover:bg-primary": true,
        "hover:text-white": true,
      };
    },
  },

  computed: {
    progressPercentage() {
      return (this.currentQuestionIndex / this.exercise_questions.length) * 100;
    },
  },
};
</script>