<template>
  <div v-if="contentList.length > 0" class="bg-[#F4F0F8] min-h-screen flex flex-col overflow-y-auto">
    <div class="lg:w-3/4 md:w-3/4 sm:w-[90%] mx-auto">
      <!-- Back button -->
      <div class="relative cursor-pointer " @click="navigateToDashboard()">
        <i class="pi pi-arrow-left absolute left-5 top-5 hover:scale-[120%] transition ease-in-out delay-50	 block"
          style="font-size: 1.5rem"></i>
      </div>

      <!-- Div do Título e do Switch -->
      <div class=" justify-between items-center p-[10px] sm:p-[40px] rounded-lg  mt-[60px]">
        <div class="flex mb-10">
          <img v-if="language" :alt="language" :src="getFlagUrl(language)" class="w-[60px] rounded-lg mr-4"
            :class="`mr-2 flag flag-${getCountryCode(language)}`" />
          <h1 class="text-3xl text-gray-800 font-bold mt-1">{{ capitalizeFirstLetter(subject) }}</h1>
        </div>
      </div>

      <!-- Área Principal e Área à Direita -->
      <div class="flex flex-row justify-between mx-auto mb-[200px]">

        <!-- DIV PRINCIPAL DE CONTEÚDO -->
        <div class="flex flex-wrap w-full">

          <!-- OPÇÕES DO CONTEÚDO-->
          <ContentMenu />

          <!-- Área Principal - AQUI VAI O CONTEÚDO-->
          <div class=" p-[20px] sm:p-[50px] m-0 w-full sm:w-full bg-white rounded-lg ">
            <div class="space-y-9">
              <PronunciationView :language="language" :countries="countries" :subject="subject"
                :original_sentences="originalSentences" :level="level" @updateChatGptResponses="handleChatGptResponses"
                @userPhrase="handleUserPhrase" ref="refPronunciationView">
              </PronunciationView>

            </div>
          </div>

          <!-- Respostas -->
          <div v-if="chatGptResponseAccuracy && chatGptResponseAccuracy === 100"
            class=" p-[20px] sm:p-[50px] m-0 w-full sm:w-full bg-[#33B679] rounded-lg  mt-10 justify-center items-center ">
            <div class=" flex justify-between">
              <div class="flex w-[700px] rounded-lg h-20 items-center">
                <p class="text-xl font-medium text-white">{{ $t("pronunciation.wellDone") }}</p>
              </div>
              <div class="flex w-[200px] bg-white rounded-lg h-20 cursor-pointer justify-center items-center "
                @click="nextSentencePai()">
                <p
                  class="text-xl font-medium text-[#33B679] text-center hover:scale-[110%] transition ease-in-out delay-50	">
                  {{ $t("pronunciation.next") }}</p>
              </div>
            </div>
          </div>

          <div v-else-if="chatGptResponseAccuracy < 100 && chatGptResponseAccuracy >= 80"
            class=" p-[20px] sm:p-[50px] m-0 w-full sm:w-full bg-[#FFF6A7] rounded-lg  mt-10 justify-center items-center ">
            <div class=" flex justify-between">
              <div class="rounded-lg h-20 items-center ">
                <p class="text-xl font-medium text-gray-800">{{ $t("pronunciation.almostThere") }}</p>
                <p>{{ $t("pronunciation.whatIUnderstood") }}</p>
                <span>{{ userPhraseMsg }}</span>
              </div>

              <div
                class="flex w-[200px] bg-white rounded-lg h-20  cursor-pointer justify-center items-center hover:scale-[110%] transition ease-in-out delay-50	"
                @click="nextSentencePai()">
                <p class="text-xl font-medium text-[#33B679] text-center ">{{ $t("pronunciation.next") }}</p>
              </div>

            </div>
          </div>

          <div v-else-if="chatGptResponseAccuracy === 0"
            class=" p-[40px] sm:p-[40px] m-0 w-full sm:w-full bg-[#F1DEFF] rounded-lg  mt-10 justify-center items-center ">
            <div class=" flex justify-between">
              <div class="rounded-lg h-20 items-center mr-5">
                <p class="text-xl font-medium text-gray-800">{{ $t("pronunciation.tryAgain") }} 🫣</p>
                <p>{{ $t("pronunciation.tryAgainOrNext") }}</p>
              </div>

              <!-- Se tentar 3x, dar a opção de passar para o próximo -->
              <div
                class="flex w-[200px] bg-primary rounded-lg h-20  cursor-pointer items-center justify-center hover:scale-[110%] transition ease-in-out delay-50	"
                @click="nextSentencePai()">
                <p class="text-xl font-medium text-white text-center ">{{ $t("pronunciation.next") }}</p>
              </div>

            </div>
          </div>


          <!-- {{  typeof chatGptResponses }}  -->
          <!-- {{  typeof chatGptResponseAccuracy }} -->



        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import mixin from '../components/mixin';
import InputSwitch from 'primevue/inputswitch';
import ContentMenu from "../components/ContentMenu.vue";
import PronunciationView from "../components/PronunciationView.vue";

export default {

  mixins: [mixin],

  components: {
    InputSwitch,
    ContentMenu,
    PronunciationView
  },

  data() {
    return {
      contentList: [],
      language: '',
      locale: '',
      level: '',
      subject: '',
      errorStep1: false,
      errorStep2: false,
      errorStep3: false,
      selectedWord: '',
      wordTranslation: '',
      showTranslation: '',
      originalSentences: '',
      translatedSentences: '',
      sentence: '',
      isSidebarOpen: false,
      checked: false,
      chatGptResponses: '',
      userPhrase: '',
      pronunciationTries: '',

      dataString: '',
      dataObj: '',
      dia: '',
      mes: '',
      ano: '',

      language: null,
      nome: 'renyer',
      countries: [
        { name: 'Inglês', code: 'us' },
        { name: 'Espanhol', code: 'es' },
        { name: 'Francês', code: 'fr' },
        { name: 'Italiano', code: 'it' },
        { name: 'Alemão', code: 'de' },
        { name: 'Russo', code: 'ru' },
        { name: 'Português', code: 'br' },
        { name: 'Holandês', code: 'nl' },
      ],
    };
  },
  async mounted() {

    this.$emit('nextSentencePai', this.nextSentencePai);

    console.log(" this.chatGptResponses ", this.chatGptResponses)
    this.chatGptResponses = null


    const contentUUID = this.$route.params.contentUUID;

    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/content/${contentUUID}`);
      this.contentList = [response.data];

      this.originalSentences = this.contentList[0]?.original_sentences.split('.').filter(Boolean).map(s => s.trim());

      this.subject = this.contentList[0]?.subject.charAt(0).toUpperCase() + this.contentList[0]?.subject.slice(1);

      this.contentUUID = this.contentList[0].content_uuid;
      this.audioPath = this.contentList[0]?.audio_path;

      this.language = localStorage.getItem("language");
      this.locale = list[0][0]?.user_idiom;
      // this.loadTranslations();


      this.level = this.contentList[0]?.level;

    } catch (error) {
      console.error("Houve um erro ao buscar os dados:", error);
    }
  },

  methods: {

    nextSentencePai() {
      if (this.$refs.refPronunciationView) {
        this.$refs.refPronunciationView.nextSentence();
        this.chatGptResponses = null
        this.pronunciationTries = 0;
      }
    },


    chatGptResponseAccuracy() {
      return parseInt(this.chatGptResponses, 10); 

    },

    handleChatGptResponses(data) {
      this.chatGptResponses = data
    },

    handleUserPhrase(data) {
      this.userPhrase = data
    },

    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },

    navigateToDashboard() {
      this.$router.push({
        path: `/dashboard`
      });
    },

    navigateToContentList() {
      this.$router.push({
        path: `/content-list`
      });
    },

    getCountryCode(name) {
      const country = this.countries.find(country => country.name === name);
      return country ? country.code : '';
    },

    getFlagUrl(name) {
      const code = this.getCountryCode(name);
      return `https://flagicons.lipis.dev/flags/4x3/${code}.svg`;
    },


    /// AUDIO PLAYER
    playPauseAudio() {
      const audioElement = this.$refs.audio;
      if (this.isPlaying) {
        audioElement.pause();
      } else {
        audioElement.play();
      }
      this.isPlaying = !this.isPlaying;

      audioElement.addEventListener("timeupdate", this.updateCurrentTime);
      audioElement.addEventListener("timeupdate", this.updateProgressBar);
    },

    goBackward() {
      const audioElement = this.$refs.audio;
      audioElement.currentTime -= 10;
    },

    goForward() {
      const audioElement = this.$refs.audio;
      audioElement.currentTime += 10;
    },

    updateCurrentTime() {
      const audioElement = this.$refs.audio;
      this.currentTime = audioElement.currentTime;
      this.audioDuration = audioElement.duration;

      const minutes = Math.floor(this.currentTime / 60);
      const seconds = Math.floor(this.currentTime % 60).toString().padStart(2, '0');

      document.getElementById('currentTime').textContent = `${minutes}:${seconds}`;

    },

    updateProgressBar() {
      const audioElement = this.$refs.audio;
      const progressBar = document.getElementById("progress");

      if (audioElement.duration > 0) {
        const percentage = (audioElement.currentTime / audioElement.duration) * 100;
        progressBar.style.width = percentage + '%';
      }
    },

    initAudio() {
      this.$nextTick(() => {
        const audioElement = this.$refs.audio;

        if (audioElement) {
          audioElement.addEventListener('loadedmetadata', () => {
            this.audioDuration = audioElement.duration;
            const minutes = Math.floor(this.audioDuration / 60);
            const seconds = Math.floor(this.audioDuration % 60).toString().padStart(2, '0');
            document.getElementById('totalTime').textContent = `${minutes}:${seconds}`;
          });

        }
      });
    },

  },
  watch: {
    showTranslation(newValue) {
      if (newValue && !this.translationRequested) {
        this.requestTranslation();
      }
    },


    chatGptResponses(newValue, oldValue) {
      console.log("chatGptResponses foi atualizado", newValue);
    },

    userPhrase(newValue, oldValue) {
      console.log("userPhrase foi atualizado", oldValue + newValue);
    },


    audioPath: {
      immediate: true,
      handler() {
        this.initAudio();
      }
    }
  },
  computed: {
    chatGptResponseAccuracy() {
      return parseInt(this.chatGptResponses, 10);
    },
    userPhraseMsg() {
      return this.userPhrase;
    }
  }
};
</script>

<style scoped></style>
  