<template>
  <div class="bg-white rounded-lg p-6 w-full sm:w-[90%] mx-auto">
    <header class="flex justify-between items-center mb-5">
      <h2 class="text-2xl font-medium capitalize text-gray-600">
        {{ currentMonthFirstDay.toLocaleString("default", { month: "long" }) }}
        {{ currentMonthFirstDay.getFullYear() }}
      </h2>

      <div class="space-x-2">
        <button
          @click="previousMonth"
          class="text-[#F1DEFF] rounded-full h-[50px] w-[50px] hover:bg-primary"
        >
          <i class="pi pi-angle-left" style="font-size: 30px"></i>
        </button>
        <button
          @click="nextMonth"
          class="text-[#F1DEFF] rounded-full h-[50px] w-[50px] hover:bg-primary"
        >
          <i class="pi pi-angle-right" style="font-size: 30px"></i>
        </button>
      </div>
    </header>

    <div class="space-y-4">
      <div
        v-for="day in daysInMonth"
        :key="day ? day.toISOString() : Math.random()"
        class="day-container"
      >
        <div
          v-if="day"
          class="font-medium text-lg bg-[#F1DEFF] p-2 rounded-md mb-2"
        >
          {{
            day.toLocaleDateString("default", {
              weekday: "long",
              day: "numeric",
              month: "long",
            })
          }}
        </div>

        <ul v-if="day" class="space-y-1">
          <li
            v-for="revision in organizedRevisions[
              day.toISOString().split('T')[0]
            ] || []"
            :key="revision.id"
            @click="navigateToContent(revision.contentUUID)"
            class="flex items-center space-x-2 p-2 rounded bg-white border border-[#F1DEFF] hover:bg-primary hover:text-white cursor-pointer"
            :class="isToday(revision.date) ? 'todayDate' : ''"
          >
            <img
              :src="getFlagUrl(revision.language)"
              class="rounded-lg w-[20px] h-[20px]"
            />
            <span>{{ capitalizeFirstLetter(revision.subject) }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import mixin from "./mixin";
import Sidebar from "./Sidebar.vue";
import PayAlert from "./PayAlert.vue";

export default {
  mixins: [mixin],

  components: {
    Sidebar,
    PayAlert,
  },

  data() {
    return {
      username: "",
      locale: "",
      conteudo: "",
      userId: "",
      isPaid: 0,
      revisions: [],
      learningLanguage: "",
      // Você pode usar uma lista completa de nomes ou abreviaturas para os dias da semana.
      daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
      currentMonthFirstDay: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ),
      countries: [
        { name: "Inglês", code: "us" },
        { name: "Espanhol", code: "es" },
        { name: "Francês", code: "fr" },
        { name: "Italiano", code: "it" },
        { name: "Alemão", code: "de" },
        { name: "Russo", code: "ru" },
        { name: "Português", code: "br" },
        { name: "Holandês", code: "nl" },
      ],
    };
  },

  async mounted() {
    const username = localStorage.getItem("username");
    const user_id = localStorage.getItem("userId");

    this.username = username;
    this.user_id = user_id;

    try {
      const responseId = await axios.get(
        `${process.env.VUE_APP_API_URL}/user/u/${username}`
      );
      this.conteudo = [responseId.data];

      this.userId = [responseId.data][0][0]?.user_id;
      this.learningLanguage = [responseId.data][0][0]?.learning_language;
      console.log(this.learningLanguage);

      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/revision/${this.userId}/${this.learningLanguage}`
      );
      this.revisions = response.data;

      const responseB = await axios.get(
        `${process.env.VUE_APP_API_URL}/user/u/${username}`
      );
      var list = [responseB.data];
      // console.log(list)

      this.isPaid = list[0][0]?.is_paid;
      this.locale = list[0][0]?.user_idiom;

      // this.loadTranslations();
    } catch (error) {
      console.error("Houve um erro ao buscar os dados:", error);
    }
  },

  computed: {
    organizedRevisions() {
      let revisions = {};
      this.revisions.forEach((revision) => {
        [
          "revision_1_day",
          "revision_3_days",
          "revision_5_days",
          "revision_7_days",
          "revision_15_days",
          "revision_30_days",
        ].forEach((dateKey) => {
          if (revision[dateKey]) {
            // Verifica se a chave/data está definida.
            try {
              let date = new Date(revision[dateKey])
                .toISOString()
                .split("T")[0];
              if (!revisions[date]) {
                revisions[date] = [];
              }
              revisions[date].push({
                subject: revision.subject,
                contentUUID: revision.content_uuid,
                language: revision.language,
                date: date,
              });
              this.language = revision.language;
            } catch (error) {
              console.error("Data inválida em revision:", revision, error);
            }
          }
        });
      });
      return revisions;
    },
    daysInMonth() {
      let days = [];
      let year = this.currentMonthFirstDay.getFullYear();
      let month = this.currentMonthFirstDay.getMonth();
      let dateInMonth = new Date(year, month, 1); // O primeiro dia do mês.
      let dayOfWeek = dateInMonth.getDay(); // Índice do dia da semana para o primeiro dia do mês.

      // Preenche os dias antes do primeiro dia do mês com espaços vazios.
      for (let i = 0; i < dayOfWeek; i++) {
        days.push(null);
      }

      while (dateInMonth.getMonth() === month) {
        days.push(new Date(dateInMonth)); // Adicionando uma cópia para evitar mutações indesejadas.
        dateInMonth.setDate(dateInMonth.getDate() + 1); // Avança para o próximo dia.
      }
      return days;
    },
  },

  methods: {
    previousMonth() {
      this.currentMonthFirstDay = new Date(
        this.currentMonthFirstDay.getFullYear(),
        this.currentMonthFirstDay.getMonth() - 1,
        1
      );
    },
    nextMonth() {
      this.currentMonthFirstDay = new Date(
        this.currentMonthFirstDay.getFullYear(),
        this.currentMonthFirstDay.getMonth() + 1,
        1
      );
    },

    getCountryCode(name) {
      const country = this.countries.find((country) => country.name === name);
      return country ? country.code : "";
    },

    getFlagUrl(name) {
      const code = this.getCountryCode(name);
      return `https://flagicons.lipis.dev/flags/4x3/${code}.svg`;
    },

    isToday(date) {
      const today = new Date();
      const itemDate = new Date(date);

      return (
        itemDate.getUTCFullYear() === today.getFullYear() &&
        itemDate.getUTCMonth() === today.getMonth() &&
        itemDate.getUTCDate() === today.getDate()
      );
    },
    capitalizeFirstLetter(string) {
      let words = string.split(" ");
      let firstWords = words.slice(0, 6);
      let phrase = firstWords.join(" ");

      if (words.length > 6) {
        phrase += "...";
      }

      return phrase.charAt(0).toUpperCase() + phrase.slice(1);
    },
  },
};
</script>

<style scoped>
.todayDate {
  background-color: #33b679;
  color: white;
}
</style>