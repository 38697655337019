<template>
  <div class="notification-container">
    <transition name="fade">
      <div v-if="msg" :class="['notification', notificationClass]">
        {{ $t(msg) }}
      </div>
    </transition>
  </div>
</template>
  
  <script>
import mixin from "../mixin";

export default {
  mixins: [mixin],

  props: {
    msg: String,
    type: String,
  },
  computed: {
    notificationClass() {
      return {
        "notification-success": this.type === "success",
        "notification-error": this.type === "error",
      };
    },
  },
  async mounted() {
    // this.loadTranslations();
  },
};
</script>


<style scoped>
.notification-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10000; /* Um valor mais alto para garantir que está acima de outros elementos */
}

.notification-success {
  background-color: #33b679; /* Green background */
  color: #fff; /* Dark green text */
  border: 1px solid #c3e6cb;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.notification-error {
  background-color: #ff3b3b; /* Red background */
  color: #fff; /* Dark red text */
  border: 1px solid #f5c6cb;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.fade-enter-active {
  animation: fadeIn 0.3s; /* Aplica a animação de Fade In */
}

.fade-leave-active {
  animation: fadeOut 2s; /* Aplica a animação de Fade In */
}

.fade-enter, .fade-leave-to /* .fade-leave-active no <2.1.8 */ {
  opacity: 0;
}

.notification {
  transition: all 2s ease; /* Suaviza a transição da animação, duração de 2s */
  transform: translateX(0); /* Posição final */
  opacity: 1; /* Totalmente visível */
  animation-fill-mode: forwards; /* Garante que a propriedade final da animação seja mantida após a conclusão */
  border: none;
  border-radius: 0.75rem;
  padding: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Para adicionar um pouco de movimento */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animação de Fade Out */
@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px); /* Para adicionar um pouco de movimento */
  }
}
</style>
